import { Button, message, Radio, Select, Switch } from 'antd'
import { Block, InputBox } from 'components'
import React, { FC, useCallback, useEffect, useState } from 'react'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { BraftEditorBody } from './style'
import axios from 'axios'
import { OSS_SIGN } from 'consts/url'
import { getRandomString } from 'utils/common'
import { announcement } from 'services'
import { SystemPayloadType } from 'services/announcement'

interface Config {
  accessid: string,
  host: string,
  policy: string,
  signature: string,
  expire: number,
  callback: string,
  dir: string
}

const System: FC = () => {
  const { Option } = Select
  const [loading, setLoading] = useState<boolean>(false)
  const [value, setValue] = useState<any>(BraftEditor.createEditorState(null))
  const [data, setData] = useState<SystemPayloadType>()

  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await announcement.getSystem()
      setData(data)
      setValue(BraftEditor.createEditorState(decodeURIComponent(data.content)))
      setLoading(false)
    })()
  }, [])

  const handleSubmit = useCallback(async () => {
    const content = encodeURIComponent(value.toHTML())
    const payload: SystemPayloadType = { ...data!, content }
    try {
      await announcement.editSystem(payload)
      message.success("修改成功")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [value, data])

  const handleUpload = useCallback(async (e) => {
    const { data: ossConfig } = await axios.get<Config>(OSS_SIGN)
    const filename = `oss-${Date.now()}-${getRandomString()}.jpg`
    const formData = new FormData()
    const key = ossConfig.dir + filename
    formData.append('key', key)
    formData.append('OSSAccessKeyId', ossConfig.accessid)
    formData.append('policy', ossConfig.policy)
    formData.append('signature', ossConfig.signature)
    formData.append('success_action_status', '200')
    formData.append('callback', ossConfig.callback)
    formData.append('file', e.file)
    await axios.post(ossConfig.host, formData)
    e.success({ url: `${ossConfig.host}/${key}` })
  }, [])

  return (
    <div>
      <Block
        loading={loading}
        showBack
        title="系统预约须知配置"
        style={{ paddingBottom: 24 }}
        description={<Button onClick={handleSubmit}>保存</Button>}
      >
        <InputBox title="是否启用" style={{ marginTop: 32 }}>
          <Switch
            checked={data?.is_used}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={(value) => { setData({ ...data!, is_used: value }) }}
          />
        </InputBox>
        <InputBox title="提醒规则" style={{ marginTop: 32 }}>
          <Radio.Group value={data?.rule} onChange={e => setData({ ...data!, rule: e.target.value })}>
            <Radio style={{ marginLeft: 15 }} value="every">每次都提示</Radio>
            <Radio value="everyday">每天提醒一次</Radio>
            <Radio value="once">确认以后不不提醒</Radio>
          </Radio.Group>
        </InputBox>
        {data?.rule === "every" ?
          <InputBox title="显示规则" style={{ marginTop: 32 }}>
            <Select value={data?.space} onChange={value => setData({ ...data!, space: value })} style={{ marginLeft: 15, width: 234 }}>
              <Option value={0}>一直显示</Option>
              <Option value={10}>每10分钟显示一次</Option>
              <Option value={15}>每15分钟显示一次</Option>
              <Option value={30}>每30分钟显示一次</Option>
            </Select>
          </InputBox>
          : null
        }
        <BraftEditorBody>
          <BraftEditor
            value={value}
            onChange={(value: any) => setValue(value)}
            media={{
              accepts: {
                video: false,
                audio: false,
              },
              externals: {
                video: false,
                audio: false,
                embed: false,
              },
              pasteImage: true,
              uploadFn: handleUpload
            }}
            controls={[
              'undo', 'redo', 'font-size', 'separator', 'text-color', 'bold', 'italic', 'underline',
              'strike-through', 'separator', 'superscript', 'subscript', 'separator', 'text-indent', 'text-align',
              'separator', 'list-ul', 'list-ol', 'blockquote', 'separator', 'link', 'hr', 'media'
            ]}
          />
        </BraftEditorBody>
      </Block>
    </div >
  )
}

export default System