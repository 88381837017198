import React, { FC, useCallback, useState } from 'react'
import { Body, Bar, Name, AvatarBar, Panel } from './style'
import { MenuFoldOutlined, SearchOutlined, BellOutlined } from '@ant-design/icons'
import { Avatar, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from "src/store"

const UserNavBar: FC = () => {
  const { app: { render } } = useSelector((state: RootState) => state)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const appDispatch = useDispatch<Dispatch>().app

  const handleClickLogout = useCallback(() => {
    localStorage.removeItem('token')
    appDispatch.setRender(render + 1)
  }, [appDispatch, render])

  return (
    <Body>
      <MenuFoldOutlined style={{ fontSize: '24px' }} />
      <Bar>
        <SearchOutlined />
        <BellOutlined style={{ marginLeft: '24px' }} />
        <AvatarBar onClick={() => { setIsOpen(!isOpen) }}>
          <Avatar></Avatar>
          <Name>这里是名字</Name>
          {isOpen ?
            <Panel>
              <Button
                type="link"
                onClick={handleClickLogout}
                style={{ height: 36 }}
              >
                退出登录
              </Button>
            </Panel>
            : null
          }
        </AvatarBar>
      </Bar>
    </Body>
  )
}

export default UserNavBar