/*
 * @Author: your name
 * @Date: 2021-04-12 11:35:18
 * @LastEditTime: 2021-04-12 18:11:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Reject/index.tsx
 */
import { Header } from "components"
import React, { FC, useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { BreadcrumbOption, TabOption } from "types/antd"
import Alert from "./View/Alert"
import Detail from "./View/Detail"
import List from "./View/List"

const Reject: FC = () => {

  const { pathname } = useHistory().location
  const [tabs] = useState<TabOption[]>([{
    key: "0",
    name: "拒收原因维护",
    link: "/reject/list"
  }, {
    key: "1",
    name: "各页面提醒",
    link: "/reject/alert"
  }])

  const [selectTabKey, setSelectTabKey] = useState<string>(pathname === "/reject/list" ? "0" : "1")

  return (
    <div>
      {
        pathname === "/reject/list" || pathname === "/reject/alert" ? < Header
          title="系统表单维护"
          description=""
          tabs={tabs}
          selectTabKey={selectTabKey}
          onTabClick={(key) => { setSelectTabKey(key) }}
        /> : null
      }
      <Route path='/reject/list' render={() => <List />} />
      <Route path='/reject/detail/:id' render={() => <Detail />} />
      <Route path='/reject/alert' render={() => <Alert />} />
    </div>
  )
}

export default Reject