import { Brief, Detail, Record } from 'types/user'
import { get, post } from 'utils/http/http'

export default {
  // 新增用户
  add: (payload: Payload.Add) => post<any>('/Admin/User/add_user', payload),
  // 删除用户
  delete: (payload: IdPayload) => post<any>('/Admin/User/delete', payload),
  // 更新用户信息
  update: (payload: UpdatePayload) => post<any>('/Admin/User/update', payload),
  // 获取用户列表
  getList: (payload: GetListPayload) => get<DataList<Brief>>('/Scene/users', payload),
  // 获取用户详情
  getDetail: (id: string) => get<Detail>(`/Scene/user/${id}`),
  // 获取用户预约记录
  getRecords: (id: string, payload: GetRecordsPayload) => get<DataList<Record>>(`/Scene/user/${id}/orders`, payload),
  // 获取策略分组列表
  getGroupList: (payload: QueryPayload) => get<GetGroupListResult>('/Scene/group_list', payload),
  // 获取供应商列表
  getSupplierList: (payload: QueryPayload) => get<GetSupplierListResult>('/Scene/supplier_list', payload)
}

declare namespace Payload {
  export interface Add {
    users: AnyObject[]
  }
}

interface GetListPayload {
  supplier_id?: number,
  order_time?: string,
  appointment_time?: string,
  order_order?: 'asc' | 'dec',
  appointment_order?: string,
  weight?: string,
  q?: string,
  page_size?: number,
  page?: number,
}

interface GetRecordsPayload {
  order_order?: 'asc' | 'dec'
  state?: string,
  page_size?: number,
  page?: number
}

interface GetGroupListResult {
  groups: QueryResult
}

interface GetSupplierListResult {
  suppliers: QueryResult
}

interface UpdatePayload {
  id: string,
  phone: string,
  supplier: string,
  user_name?: string
}
