export const columns = [{
  key: 'name',
  dataIndex: 'name',
  title: '策略分组名称',
  width: '200px'
}, {
  key: 'type',
  dataIndex: 'type',
  title: '供应商类型',
  width: '200px'
}, {
  key: 'cycle',
  dataIndex: 'cycle',
  title: '策略周期'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作',
  width: '240px',
}]
