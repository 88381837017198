import { Input, message, Table } from 'antd'
import { Block } from 'components'
import React, { FC, useState, useEffect } from 'react'
import announcement, { HistoryItem } from 'services/announcement'

const columns = [{
  key: 'username',
  dataIndex: 'username',
  title: '用户名'
}, {
  key: 'sign_type',
  dataIndex: 'sign_type',
  title: '联系方式'
},{
  key: 'sign_type',
  dataIndex: 'sign_type',
  title: '签订时间'
}, {
  key: 'sign_type',
  dataIndex: 'sign_type',
  title: '签署类型'
},{
  key: 'register_time',
  dataIndex: 'register_time',
  title: '注册时间'
}]

const History: FC = () => {
  const { Search } = Input
  const [render, setRender] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [keyword, setKeyword] = useState<string>("")
  const [size, setSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [data, setData] = useState<HistoryItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const data = await announcement.getHistory({ page, size, keyword })
        setData(data.list)
        setTotal(data.total)
      } catch (err) {
        err.response && message.error(err.response.data.message)
      } finally {
        setLoading(false)
      }
    })()
  }, [render, page, size, keyword])

  return (

    <Block
      title="签署记录"
      loading={loading}
      description={
        <Search
          placeholder="input search text"
          onSearch={(value) => { setKeyword(value) }}
          enterButton
          style={{ marginTop: 10 }}
        />
      }
    >
      <Table
        dataSource={data}
        columns={columns}
        style={{ marginTop: '24px' }}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: size,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _size) => {
            if (_page !== page) setPage(_page)
            if (_size !== size) setSize(_size!)
          },
        }}
      />
    </Block>
  )
}

export default History