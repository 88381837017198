import React, { FC, useState, useCallback } from "react"
import { Modal, Select, Button, Input, message } from "antd"
import supplier, { Group, AddNewSupplierPayload } from "services/supplier"
import { supplierDialogStyle } from "consts/supplier"
import { addSupplierVac } from "utils/vac"
import { InputBox } from "components"

interface DialogType {
  groupList?: Group[],
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void
}

const AddSupplierDialog: FC<DialogType> = (props) => {
  const [name, setName] = useState<string>("")
  const [phone, setPhone] = useState<string>()
  const [weight, setWeight] = useState<number>(1)

  const submit = useCallback(async () => {
    const payload: AddNewSupplierPayload = {
      name,
      phone: phone || "",
      group: "普通供应商",
      weight
    }
    if (addSupplierVac(payload)) return
    try {
      await supplier.addNewSupplier(payload)
      props.onClose()
      message.success('添加供应商成功')
      props.onSubmit()
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }

  }, [name, phone,  weight])

  const reset = useCallback(async () => {
    setName("")
    setPhone("")
    setWeight(1)
  }, [])

  return (
    <Modal
      title="添加供应商"
      visible={props.isOpen}
      onCancel={props.onClose}
      footer={
        <div>
          <Button onClick={reset}>重置</Button>
          <Button onClick={submit} type="primary" >确认</Button>
        </div >
      }
    >
      <InputBox style={{ ...supplierDialogStyle, marginTop: 0 }} title="供应商名称">
        <Input style={{ width: 240 }} value={name} onChange={(e) => { setName(e.target.value) }} />
      </InputBox>
      <InputBox style={supplierDialogStyle} title="供应商联系方式">
        <Input style={{ width: 240 }} value={phone} type="number" onChange={(e) => { setPhone(e.target.value) }} />
      </InputBox>
      <InputBox style={supplierDialogStyle} title="供应商权重">
        <Input style={{ width: 240 }} value={weight} type="number" onChange={(e) => { setWeight(Number(e.target.value)) }} />
      </InputBox>
    </Modal >
  )
}

export default AddSupplierDialog