import { get, post } from '../utils/http/http'
import { RefuseForm } from 'types/order'

const baseURL = '/Scene/'

export default {
  //列表-配置
  getConfigure: () => get<ConfigureResult>(`${baseURL}configure`),
  //列表-列表
  getList: (payload: ListPayload) => get<ListResult>(`${baseURL}orders`, payload),
  //详情-信息
  getDetail: (payload: { id: string }) => get<DetailResult>(`/Admin/Order/detail`, payload),
  //详情-动作
  action: (payload: ActionPayload) => post<any>(`${baseURL}action`, payload),
  //详情-待审核列表
  getQueue: (payload: { id: string }) => get<{ orders: Queue[] }>(`${baseURL}order/${payload.id}/queue`),
  // 获取签到二维码
  getQRCode: () => post<QRCodeResult>('/Admin/Order/attend_qrcode'),
  // 配置
  getMessage: () => get<any[]>(`${baseURL}action_conf/refuse`),
  //
  getReject: () => get<any[]>(`/Admin/order_rule/refuse_list`),
  // 获取拒绝收货表单列表
  getRefuseFormList: () => get<RefuseForm[]>('/Admin/order_rule/refuse_list')
}

export interface Queue {
  num: number,
  name: string,
  cellphone: string,
  supplier: string,
  supplier_type: string,
  subject: string,
  created: string
}

export interface ConfigureResult {
  status: { [key: string]: string[] }
  table: { [key: string]: string[] }
}

export interface ActionPayload {
  action: string,
  id: string[],
  payload?: any
}

interface ListPayload {
  status?: string,
  q?: string,
  page_size?: string,
  page: string
}

interface ListResult {
  total: number,
  data: Order[]
}

export interface Order {
  phone: string,
  created: string,
  id: string,
  name: string,
  status: string,
  subject: string,
  supplier: string,
  color: string
}

export interface DetailResult {
  order_detail: Detail[],
  order_status: Logs;
  order_user: {
    [key: string]: string
  };
  order_field: OrderField[];
}


interface Log {
  title: string;
  content: string;
}

export interface Detail {
  date: string,
  lesson: string,
  name: string,
  sequence_number: string,
  state: string,
  week: string
}

interface OrderField {
  name: string;
  field_content: string;
  type: number;
}


export interface Reason {
  name: string;
  type: string;
  value: string;
}

interface Logs {
  refuse: readonly any[] | undefined
  id: string,
  created: string;
  modified: string;
  status: string;
  color: string,
  supplier: string;
  supplier_type: string,
  subject: string,
  logs: Log[];
  reason?: Reason[];
}

interface Log {
  title: string;
  content: string;
}

interface QRCodeResult {
  content: string
}