import React, { FC, Fragment } from "react"
import { Route } from "react-router-dom"
import Decoration from "./View/Decoration"
import DisplayTime from "./View/DisplayTime"
import Weight from "./View/Weight"
import Number from "./View/Number"

const System: FC = () => {
  return (
    <Fragment>
      <Route path='/system/display_time' render={() => <DisplayTime />} />
      <Route path='/system/weight' render={() => <Weight />} />
      <Route path='/system/number' render={() => <Number />} />
      <Route path='/system/decoration' render={() => <Decoration />} />
    </Fragment>
  )
}

export default System
