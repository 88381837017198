/*
 * @Author: your name
 * @Date: 2020-10-22 19:24:37
 * @LastEditTime: 2020-12-21 17:50:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/DisplayTime/View/style.ts
 */
import styled from 'styled-components'

export const Title = styled.div`
  height: 24px;
  font-size: 16px;
  font-weight: 600;
`
