import React, { FC, CSSProperties } from 'react'
import { Row, Col } from 'antd'
import { Key, Value } from './style'

interface Props {
  name: string,
  colw?: number,
  style?: CSSProperties,
}

const HashItem: FC<Props> = (props) => {
  return (
    <Row style={{ ...props.style, padding: '4px 0px' }}>
      <Col span={props.colw || 6}>
        <Key>{props.name}</Key>
      </Col>
      <Col>
        <Value>{props.children}</Value>
      </Col>
    </Row>
  )
}

export default HashItem
