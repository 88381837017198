import React, { FC, useEffect, useState, useCallback } from 'react'
import { Button, Input, Table, Popconfirm, message, Select, Descriptions } from 'antd'
import { Block, Info } from 'components'
import { Title, Value, View } from './style'
import { infoStyle, userColumns } from 'consts/supplier'
import { useParams, useHistory } from 'react-router'
import { item, supplier } from 'services'
import { Datum, Group } from 'services/supplier'
import { addSupplierVac } from 'utils/vac'
import { exportExcel } from 'xlsx-oc'

const Detail: FC = () => {
  const { TextArea } = Input
  const { Option } = Select
  const history = useHistory()
  const param: { id: string } = useParams()
  const id = param.id
  const [state, setState] = useState<'display' | 'edit'>('display')
  const [name, setName] = useState<string>()
  const [groupList, setGroupList] = useState<Group[]>()
  const [group, setGroup] = useState<number>()
  const [weight, setWeight] = useState<number>()
  const [phone, setPhone] = useState<string>()
  const [groupType, setGroupType] = useState<string>()
  const [createTime, setCreateTime] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [tempName, setTempName] = useState<string>()
  const [tempPhone, setTempPhone] = useState<string>()
  const [tempWeight, setTempWeight] = useState<number>()
  const [tempGroup, setTempGroup] = useState<number>()
  const [tempDescription, setTempDescription] = useState<string>()
  const [render, setRender] = useState<number>(1)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [user, setUser] = useState<any[]>([])
  const [excel, setExcel] = useState<Datum[]>([])
  const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>([])
  const [groups, setGroups] = useState<{ id: number, group: string, type: string }[]>([])

  useEffect(() => {
    (async () => {
      const { groups } = await supplier.getGroupList()
      setGroupList(groups)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      setResourceList(data)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      setPageLoading(true)
      const data = await supplier.getSupplierDetail({ id })
      setPageLoading(false)
      setName(data.name)
      setGroup(data.group_id)
      setPhone(data.phone)
      setWeight(data.weight)
      setGroupType(data.group_type)
      setDescription(data.info)
      setCreateTime(data.create_time)
      setGroups(data.groups)
    })()
  }, [render, id])

  useEffect(() => {
    (async () => {
      const data = await supplier.getSupplierUserList({
        id,
        order_order: '',
        appointment_order: '',
        page_size: 1000,
        page: 1
      })
      setUser(handleUserData(data.data))
      setExcel(data.data)
    })()
  }, [id])

  const handleUserData = useCallback((data: Datum[]) => {
    if (!data || !data.length) return []
    const result: any[] = []
    data.forEach(item => {
      result.push({
        key: item.id,
        name: item.name,
        phone: item.phone,
        group: item.group,
        supplier: item.supplier,
        order_time: item.order_time
      })
    })
    return result
  }, [])

  const handleClickEdit = useCallback(() => {
    setState('edit')
    setTempName(name)
    setTempPhone(phone)
    setTempWeight(weight)
    setTempDescription(description)
    setTempGroup(group)
  }, [name, phone, weight, description])

  const handleClickDelete = useCallback(async () => {
    await supplier.delSupplier({ id })
    message.success('删除成功')
    history.push('/supplier/white')
  }, [id])

  const handleClickEditSubmit = useCallback(async () => {
    const payload = {
      id,
      name: tempName!,
      phone: tempPhone!,
      weight: tempWeight!,
      info: tempDescription || '',
    }
    if (addSupplierVac({ ...payload, group: `${tempGroup}` })) return
    try {
      await supplier.editSupplier({ ...payload, group_id: tempGroup! })
      message.success('修改成功')
      setRender(render + 1)
      setState('display')
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }

  }, [id, tempName, tempPhone, tempWeight, tempDescription, tempGroup, render])

  const exportDefaultExcel = useCallback(() => {
    const header = [
      { k: 'id', v: '用户Id' },
      { k: 'name', v: '用户姓名' },
      { k: 'supplier', v: '供应商名称' },
      { k: 'group', v: '分组' },
      { k: 'phone', v: '联系方式' },
      { k: 'order_time', v: '最近下单时间' },
      { k: 'appointment_time', v: '预约时间' },
      { k: 'register_time', v: '注册时间' }
    ];
    const fileName = "用户列表.xlsx";
    exportExcel(header, excel, fileName);
  }, [excel])

  return (
    <React.Fragment>
      <Block
        loading={pageLoading}
        description={
          state === 'display' ?
            <div>
              <Popconfirm
                title="您真的要删除此供应商么"
                onConfirm={handleClickDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button>删除</Button>
              </Popconfirm>
              <Button onClick={handleClickEdit} style={{ marginLeft: 8 }}>编辑</Button>
            </div> :
            <div>
              <Popconfirm
                title="此操作将放弃本次所有更改"
                onConfirm={() => { setState('display') }}
                okText="Yes"
                cancelText="No"
              >
                <Button>取消</Button>
              </Popconfirm>
              <Button
                style={{ marginLeft: 8 }}
                type='primary'
                onClick={handleClickEditSubmit}
              >
                确认
              </Button>
            </div>
        }
        style={{ paddingBottom: 24 }}
      >
        <Title style={{ marginTop: 32 }}>基本信息</Title>
        <View>
          {
            state === 'display' ?
              <React.Fragment>
                <Info title={'公司名称'} style={infoStyle}>{name}</Info>
                <Info title={'策略分组'} style={infoStyle}>
                  {groupList && groupList.length && group ? groupList.filter(item => Number(item.id) === group)[0].name : ''}
                </Info>
                <Info title={'联系方式'} style={infoStyle}>{phone}</Info>
                <Info title={'权重类型'} style={infoStyle}>{weight}</Info>
                <Info title={'策略类型'} style={infoStyle}>{groupType}</Info>
                <Info title={'注册时间'} style={infoStyle}>{createTime}</Info>
              </React.Fragment> :
              <React.Fragment>
                <Info title={'公司名称'} style={infoStyle}>
                  <Input style={{ height: 24, width: 160 }} value={tempName} onChange={(e) => { setTempName(e.target.value) }} />
                </Info>
                <Info title={'策略分组'} style={infoStyle}>
                  <Select
                    value={tempGroup}
                    onChange={(value) => { setTempGroup(value) }}
                    style={{ height: 24, width: 160 }}
                    size='small'
                    dropdownStyle={{
                      overflowY: "scroll"
                    }}
                  >
                    {groupList && groupList.length ? groupList.map(item =>
                      <Option value={item.id} key={item.id}>{item.name}</Option>
                    ) : null}
                  </Select>
                </Info>
                <Info title={'联系方式'} style={infoStyle}>
                  <Input style={{ height: 24, width: 160 }} value={tempPhone} onChange={(e) => { setTempPhone(e.target.value) }} />
                </Info>
                <Info title={'权重类型'} style={infoStyle}>
                  <Input style={{ height: 24, width: 160 }} value={tempWeight} type='number' onChange={(e) => { setTempWeight(Number(e.target.value)) }} />
                </Info>
                <Info title={'策略类型'} style={infoStyle}>{groupType}</Info>
                <Info title={'注册时间'} style={infoStyle}>{createTime}</Info>
              </React.Fragment>
          }
        </View>
        <Title style={{ marginTop: 17 }}>详细信息</Title>
        {state === 'display' ?
          <Value>{description}</Value> :
          <TextArea value={tempDescription} onChange={(e) => { setTempDescription(e.target.value) }} />
        }

      </Block>
      <Block title="供应商分组信息" style={{ paddingTop: 16 }}>
        {resourceList && resourceList.length && groups.map((item: any) => {
          console.log(item)
          console.log(resourceList)
          return <Descriptions>
            <Descriptions.Item label={`${resourceList?.filter(resource => resource.id === item.id)[0].name}分组`}>
              {item.group}
            </Descriptions.Item>
            <Descriptions.Item label={`${resourceList?.filter(resource => resource.id === item.id)[0].name}策略类型`}>
              {item.type}
            </Descriptions.Item>
          </Descriptions>
        })}
      </Block>
      <Block
        title='该供应商旗下员工'
        description={<Button type='primary' onClick={exportDefaultExcel}>导出Excel</Button>}
      >
        <Table
          style={{ marginTop: 28 }}
          dataSource={user}
          columns={userColumns}
          pagination={false}
        />
      </Block>

    </React.Fragment>
  )
}

export default Detail