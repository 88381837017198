import { get, post, put, del } from '../utils/http/http'

const baseURL = '/Scene/'

export default {
  //策略分组-列表
  getGroupList: () => get<GroupListResult>(`${baseURL}group_list`),
  //白名单供应商-列表
  getWhiteList: (payload: WhiteListPayload) => get<WhiteListResult>(`${baseURL}suppliers`, payload),
  //白名单供应商-添加
  addNewSupplier: (payload: AddNewSupplierPayload) => post<null>(`${baseURL}suppliers`, payload),
  //白名单供应商-修改
  changeSupplier: (payload: ChangeSupplierPayload) => put<null>(`${baseURL}suppliers`, payload),
  //白名单供应商-列表
  getReviewList: (payload: ReviewListPayload) => get<ReviewListResult>(`${baseURL}suppliers`, payload),
  //供应商-详情
  getSupplierDetail: (payload: { id: string }) => get<SupplierDetailResult>(`${baseURL}supplier/${payload.id}`),
  //供应商-编辑
  editSupplier: (payload: EditSupplierPayload) => put<null>(`${baseURL}supplier/${payload.id}`, payload),
  //供应商-删除
  delSupplier: (payload: { id: string }) => del<null>(`${baseURL}supplier/${payload.id}`),
  //供应商员工-列表
  getSupplierUserList: (payload: SupplierUserListPayload) => get<SupplierUserListResult>(`${baseURL}suppliers/${payload.id}/users`, payload),
  //供应商-审核
  handleReview: (payload: HandleReviewPayload) => post<null>(`${baseURL}suppliers/review`, payload),
  //
  importSupplierList: (payload: { suppliers: string[][] }) => post(`${baseURL}import_suppliers`, payload),
  //供应商-更改分组
  editSort: (payload: EditSortPayload) => put<null>(`${baseURL}supplier/group`, payload),
}

interface EditSortPayload {
  suppliers: string[],
  group_id: string
}

interface SupplierUserListResult {
  data: Datum[];
  total: number;
}

export interface Datum {
  id: string;
  name: string;
  supplier: string;
  group: string;
  phone: string;
  order_time: string;
  appointment_time: string;
  register_time: string;
}

interface HandleReviewPayload {
  id_list: number[],
  op: string
}

interface GroupListResult {
  groups: Group[];
}

export interface Group {
  id: number;
  name: string;
}

interface WhiteListPayload {
  supplier_name?: string,
  group_name?: string,
  weight?: number,
  resource_id?: number,
  size: number,
  page: number,
  auth: boolean
}

interface WhiteListResult {
  data: SupplierList[];
  total: number
}

export interface SupplierList {
  id: number;
  key?: number;
  name: string;
  group: string;
  group_type: string;
  weight: number;
  contact?: string;
  phone: string;
  info: string;
  create_time: string;
  register_time?: string;
}

export interface AddNewSupplierPayload {
  name: string,
  phone: string,
  group: string,
  weight: number
}

interface ChangeSupplierPayload {

}

interface ReviewListPayload {
  q: string,
  page_size: number,
  page: number,
  auth: boolean
}

interface ReviewListResult {
  data: SupplierList[];
  total: number
}

interface SupplierDetailResult {
  id: number;
  name: string;
  group: string;
  group_id: number;
  group_type: string;
  weight: number;
  phone: string;
  info: string;
  register_time: string;
  create_time: string;
  groups: { id: number, group: string, type: string }[]
}

interface SupplierUserListPayload {
  id: string,
  order_order: string,
  appointment_order: string,
  page_size: number,
  page: number
}

interface EditSupplierPayload {
  id: string,
  name: string,
  phone: string,
  weight: number,
  info: string,
  group_id: number,
}