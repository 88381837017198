/*
 * File: /src/pages/System/View/Decoration/Home/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 12:37:08
 * Last Modified: Wednesday 2020-12-09 12:37:08
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Col, Collapse, message, Row, Space } from 'antd'
import { Block } from 'components'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { createFromIconfontCN } from '@ant-design/icons';
import Emulator from '../Emulator';
import components from '../components';
import Carousel from './Carousel';
import { useDispatch, useSelector } from 'react-redux';
import services from 'services';
import { ComponentEditing, IndexStyle } from 'types/decoration';
import { ICONFONTCN } from 'consts/url';

function renderEditArea(name?: string) {
  switch (name) {
    case 'Carousel': return <Carousel />
    default: return null
  }
}

const IconFont = createFromIconfontCN({scriptUrl: ICONFONTCN})

const Home: FC = () => {
  const { indexStyle, activeComponent } = useSelector((state: RootState) => state.decoration)
  const dispatch = useDispatch<Dispatch>().decoration

  const [loading, setLoading] = useState<boolean>(false)
  const [dragging, setDragging] = useState<boolean>(false)
  const [draggedComponentName, setDraggedComponentName] = useState<string>('')
  const [initComponens, setInitComponents] = useState<ComponentEditing[]>([])

  const handleSave = useCallback(() => {
    if (!indexStyle) return
    setLoading(true)
    services.common.setConfig({ index_style: JSON.stringify(indexStyle) })
      .catch(() => {
        message.error('保存失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [indexStyle])

  useEffect(() => {
    setLoading(true)
    services.common.getConfig({ configs_need: ['index_style'] })
      .then(data => {        
        const indexStyle = JSON.parse(data.index_style) as IndexStyle        
        dispatch.setIndexStyle(indexStyle)
        const initComponens: ComponentEditing[] = []
        if (indexStyle.carouseList.length) {
          initComponens.push({name: 'Carousel', mode: 'normal', level: components['Carousel'].level})
        }
        if(indexStyle.shortcutList.length) {
          initComponens.push({name: 'Shortcut', mode: 'normal', level: components['Shortcut'].level})
        }
        setInitComponents(initComponens)
      })
      .catch(() => {        
        message.error('获取配置项失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Block
      title="首页装修"
      showBack
      description={
        <Space>
          <Button>预览</Button>
          <Button type="primary" onClick={handleSave}>保存并应用</Button>
        </Space>
      }
      loading={loading}
      style={{padding: 30}}
    >
      <Row>
        <Col span={7}>
          <Collapse
            ghost
            defaultActiveKey={['1']}
            expandIcon={(p) => p.isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
          >
            <Collapse.Panel header="基本组件" key="1">
              <Row gutter={[0, 28]}>
                {Object.values(components).filter(component => component.category === 'basic').map(component =>
                  <Col
                    span={8}
                    draggable
                    onDragStart={() => {
                      setDraggedComponentName(component.name)
                      setDragging(true)
                    }}
                    onDragEnd={() => setDragging(false)}
                    style={{ textAlign: 'center', cursor: 'pointer' }}
                  >
                    <IconFont type={component.icon} style={{ fontSize: 30 }} />
                    <div style={{ fontSize: 12, marginTop: 12 }}>{component.title}</div>
                  </Col>
                )}
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="其他组件" key="2">
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col span={9}>
          <Emulator
            initComponents={initComponens}
            draggedComponentName={draggedComponentName}
            dragging={dragging}
          />
        </Col>
        <Col span={8}>
          {renderEditArea(activeComponent)}
        </Col>
      </Row>
    </Block>
  )
}

export default Home
