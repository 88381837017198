import { get, post, del, put } from "../utils/http/http"

const baseURL = "/Admin/Admin/"

export default {
  //管理员-列表
  getAdministratorList: () => get<Administrator[]>(`${baseURL}list`),
  //管理员-详情
  getAdministrator: (payload: { id: string }) => get<AdministratorInfo>(`${baseURL}operation`, payload),
  //管理员-新增
  addAdministrator: (payload: any) => post<{ id: number }>(`${baseURL}operation`, payload),
  //管理员-删除
  delAdministrator: (payload: { id: number }) => del(`${baseURL}operation`, payload),
  //管理员-修改
  editAdministrator: (payload: any) => put(`${baseURL}operation`, payload)
}

export interface Administrator {
  id: number,
  name: string;
  account: string;
  grade: string;
  create_time: string;
  phone: number;
}


export interface AdministratorInfo {
  name: string,
  account: string,
  phone: number,
  description: string,
  resource_id_list: number[],
  is_use_wechat_message: boolean,
}



