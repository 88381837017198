import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block } from 'components'
import { Button, message, Popconfirm, Popover, Table } from 'antd'
import { Title, ButtonBar } from './style'
import { administratorsColumn } from 'consts/administrators'
import { Link } from 'react-router-dom'
import { administrator } from 'services'
import { Administrator } from 'services/administrator'

const List: FC = () => {
  const [list, setList] = useState<Administrator[]>()
  const [render, setRender] = useState<number>(1)

  useEffect(() => {
    (async () => {
      const data = await administrator.getAdministratorList()
      if (data.length) {
        const result = data.map(item => ({
          ...item,
          handle: (
            <ButtonBar>
              <Link to={`/administrators/detail/${item.id}`}>
                <Button type="link">详情</Button>
              </Link>
              <Popconfirm
                placement="left"
                title={`确认删除管理员 ${item.name} ?`}
                onConfirm={() => { handleDelete(item.id) }}
              >
                <Button type="link" >删除</Button>
              </Popconfirm>
            </ButtonBar >
          )
        }))
        setList(result)
      }
    })()
  }, [render])

  const handleDelete = useCallback(async (id) => {
    try {
      await administrator.delAdministrator({ id })
      setRender(render + 1)
      message.success("删除成功")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [render])

  return (
    <Block>
      <Link to="/administrators/new">
        <Button style={{ marginTop: 24 }} type="primary">添加</Button>
      </Link>
      <Title>管理员列表</Title>
      <Table
        pagination={false}
        columns={administratorsColumn}
        dataSource={list}
        style={{ marginTop: 24 }}
      />
    </Block>
  )
}

export default List