/*
 * File: /src/pages/System/View/Decoration/Emulator/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Tuesday 2020-12-08 04:30:25
 * Last Modified: Wednesday 2020-12-09 10:02:48
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useState, useCallback, CSSProperties, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ComponentEditing } from 'types/decoration'
import components from '../components'
import Head from './Head'

const maskStyle: CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
}

function getLevel(name: string) {
  const _components = Object.values(components)
  const index = _components.findIndex(component => component.name === name)
  return index === -1 ? 1 : _components[index].level
}

interface ComponentWrapperProps {
  name: string,
  mode: 'normal' | 'before'
}

const ComponentWrapper: FC<ComponentWrapperProps> = (props) => {
  const { activeComponent } = useSelector((state: RootState) => state.decoration)
  const dispatch = useDispatch<Dispatch>().decoration

  return (
    <div
      key={props.name}
      onMouseEnter={e => {
        e.preventDefault()
        dispatch.setActiveComponent(props.name)
      }}
      style={{ position: 'relative', cursor: 'pointer' }}
    >
      {components[props.name].render && components[props.name].render({})}
      {props.mode === 'before' &&
        <div style={{ ...maskStyle, backgroundColor: 'rgba(255,255,255,0.8)', border: '2px #268B76 dashed' }}></div>
      }
      {activeComponent === props.name &&
        <div style={{ ...maskStyle, border: '2px #268B76 dashed' }}></div>
      }
    </div>
  )
}

interface Props {
  initComponents: ComponentEditing[],
  draggedComponentName?: string,
  dragging?: boolean
}

const Emulator: FC<Props> = (props) => {
  const { initComponents, draggedComponentName, dragging } = props
  const [components, setComponents] = useState<ComponentEditing[]>([])

  const handleDragEnter = useCallback(() => {
    if (draggedComponentName) {
      const index = components.findIndex(component => component.name === draggedComponentName)
      if (index !== -1) components.splice(index, 1)
      components.push({ name: draggedComponentName, level: getLevel(draggedComponentName), mode: 'before' })
      setComponents([...components])
    }
  }, [draggedComponentName, components])

  const handleDragLevel = useCallback(() => {
    const index = components.findIndex(component => component.mode === 'before')
    if (index !== -1) components.splice(index, 1)
    setComponents([...components])
  }, [components])

  const handleDrop = useCallback(e => {
    const index = components.findIndex(component => component.mode === 'before')
    if (index !== -1) components[index].mode = 'normal'
    setComponents([...components])
  }, [components])

  useEffect(() => {
    setComponents([...initComponents])
  }, [initComponents])
  
  return (
    <div
      style={{
        width: '75%',
        margin: 'auto',
        border: '1px solid #E9E9E9',
        borderRadius: 8,
        minHeight: 628,
      }}
    >
      <Head />
      {components.sort((a, b) => a.level - b.level).map(component =>
        <ComponentWrapper name={component.name} mode={component.mode} />
      )}
      {dragging &&
        <div
          style={maskStyle}
          onDragOver={e => e.preventDefault()}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLevel}
        ></div>
      }
    </div>
  )
}

export default Emulator
