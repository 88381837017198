import { DatePicker, message, Select } from 'antd'
import { Block, InputBox, TimeScroll } from 'components'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormatDay } from 'utils/time'
import moment from "moment"
import { Header, TitleList, Title, NoneBox, NoneBoxList } from './style'
import { daily } from 'services'
import { TimeFragment } from 'services/dilay'
import Box from '../Model/Box'
import order, { ActionPayload } from 'services/order'
import { getWeekTableRange } from 'components/WeekTable/utils'

const Today: FC = () => {
  const { Option } = Select
  const nowDate = FormatDay(new Date())
  const [resourceList, setResourceList] = useState<{ id: string, name: string }[]>()
  const [selectResourceList, setSelectResourceList] = useState<number[]>()
  const [displayList, setDisplayList] = useState<{ id: string, name: string }[]>()
  const [selectTimeFragment, setSelectTimeFragment] = useState<string>("")
  const [selectDay, setSelectDay] = useState<string>(nowDate)
  const [timeList, setTimeList] = useState<TimeFragment[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])
  const [render, setRender] = useState<number>(0)
  const [scrollTop, setScrollTop] = useState<number>(590)

  const renderNoneBoxList = useCallback(() => {
    const length = selectResourceList && selectResourceList.length ? selectResourceList.length : 0
    if (!length) return null
    const boxList = []
    for (let index = 0; index < (24 * length); index++) {
      boxList.push(<NoneBox key={index} />)
    }
    return <NoneBoxList style={{ width: length * 150 }}>{boxList}</NoneBoxList>
  }, [selectResourceList])

  const handleChangeSelect = useCallback((indexList: number[]) => {
    if (indexList.length <= 7) {
      setSelectResourceList(indexList)
      setDisplayList(resourceList?.filter(item => indexList.some(index => Number(item.id) === index)))
    } else {
      const tempList = indexList.slice(indexList.length - 7, indexList.length)
      setSelectResourceList(tempList)
      setDisplayList(resourceList?.filter(item => tempList.some(index => Number(item.id) === index)))
    }
  }, [resourceList])

  useEffect(() => {
    (async () => {
      const data = await daily.getResourceList()
      setResourceList(data)
    })()
  }, [])

  useEffect(() => {
    if (!selectResourceList || !selectResourceList.length) {
      setTimeList([])
      return
    }
    (async () => {
      try {
        setLoading(true)
        const timeList = await daily.getTimeFragmentList({
          date: selectDay.split("-").join(""),
          resource_id_list: selectResourceList.join()
        })
        setTimeList(timeList)
      } catch (err) {
        err.response && message.error(err.response.data.message)
        setTimeList([])
      } finally {
        setLoading(false)
      }
    })()
  }, [selectDay, selectResourceList, render])

  const orderSubmit = useCallback(async () => {
    if (!selectRowIdList || !selectRowIdList.length) return
    try {
      let payload: ActionPayload = {
        id: selectRowIdList as string[],
        action: "签到",
        payload: {}
      }
      await order.action(payload)
      message.success(`您已完成操作签到`, 5)
      setSelectRowIdList([])
      setSelectTimeFragment("")
      setRender(render + 1)
    } catch (err) {
      message.error(`操作出现异常`, 5)
    }
  }, [selectRowIdList, render])

  useEffect(() => {
    setSelectRowIdList([])
  }, [selectTimeFragment])

  //计算时间格子生成后移动高度
  useEffect(() => {
    if (!timeList || !timeList.length) return
    let min = 590
    timeList.forEach(item => {
      const [hour, minute] = item.time.split("-")[0].split(":")
      const startTime = Number(hour) * 60 + Number(minute)
      if (min > getWeekTableRange(startTime)) min = getWeekTableRange(startTime)
    })
    setScrollTop(min - 1 + Math.random())
  }, [timeList])

  return (
    <Block
      style={{ paddingTop: 17 }}
    >
      <Header>
        <InputBox title="选择日期" >
          <DatePicker
            value={moment(selectDay)}
            style={{ width: 200 }}
            onChange={(_date, dateStr) => { setSelectDay(dateStr) }}
            allowClear={false}
          />
        </InputBox>
        <InputBox title="预约项目" style={{ marginLeft: 80 }}>
          <Select
            mode="multiple"
            style={{ minWidth: 200 }}
            placeholder="请选择预约项目"
            onChange={handleChangeSelect}
            value={selectResourceList}
          >
            {resourceList && resourceList.length && resourceList.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </InputBox>
      </Header>
      <TitleList>
        {displayList?.map(item => <Title key={Math.random()}>{item.name}</Title>)}
      </TitleList>
      <TimeScroll
        isLoading={loading}
        background={renderNoneBoxList()}
        scrollTop={scrollTop}
      >
        {timeList?.map(item =>
          <Box
            key={`${item.date}${item.id}${Math.random()}`}
            data={item}
            type="resource"
            selectResourceList={selectResourceList?.sort((a, b) => (b - a))}
            selectTimeFragment={selectTimeFragment}
            onClick={(key) => { setSelectTimeFragment(selectTimeFragment === key ? "" : key) }}
            selectRowIdList={selectRowIdList}
            onChange={(selectRowIdList: React.ReactText[]) => { setSelectRowIdList(selectRowIdList) }}
            onSubmit={orderSubmit}
            onCancel={() => {
              setSelectRowIdList([])
              setSelectTimeFragment("")
              setRender(render + 1)
            }}
          />
        )}
      </TimeScroll>
    </Block>
  )
}

export default Today