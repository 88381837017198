/*
 * File: /src/services/common.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Thursday 2020-12-10 04:59:43
 * Last Modified: Thursday 2020-12-10 04:59:43
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import http from 'utils/http'

export default {
  // 获取配置项
  getConfig: (payload?: Payload.GetConfig) => http.post<AnyObject>('/Admin/Config/get_configures', payload),
  // 设置配置项
  setConfig: (payload: AnyObject) => http.post<any>('/Admin/Config/set_config', payload)
}

declare namespace Payload {
  export interface GetConfig {
    configs_need: AnyObject
  }
}
