import React, { FC,CSSProperties } from 'react'
import { Layout, Spin } from 'antd'

interface Props {
  loading?: boolean;
  bgColor?: string;
  style?: CSSProperties;
}

const Container: FC<Props> = (props) => {
  const { loading = false, bgColor = '#FFFFFF', style, children } = props

  return (
    <Layout style={{ ...style, backgroundColor: bgColor }}>
      <Spin spinning={loading}>
        {children}
      </Spin>
    </Layout>
  )
}

export default Container
