/*
 * @Author: your name
 * @Date: 2020-09-25 15:32:16
 * @LastEditTime: 2020-10-13 14:54:32
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/daily.ts
 */
export const timeFragmentColumns = [{
  key: 'order_id',
  dataIndex: 'order_id',
  title: '订单'
}, {
  key: 'name',
  dataIndex: 'name',
  title: '用户名称'
}, {
  key: 'supplier',
  dataIndex: 'supplier',
  title: '供应商'
}, {
  key: 'index',
  dataIndex: 'index',
  title: '序号'
}, {
  key: 'state',
  dataIndex: 'state',
  title: '签到状态'
}]