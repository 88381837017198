import { message } from "antd"
import { AddNewSupplierPayload } from "services/supplier"

export const timeTableNewItem = (date: Date | undefined, timeRange: string[] | undefined,) => {
  if (!date) {
    message.error('请选择一个日期')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[0]) {
    message.error('请选择开始时间')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[1]) {
    message.error('请选择结束时间')
    return true
  }
  return false
}

export const timeQuickNewItem = (date: Date | undefined, timeRange: string[] | undefined) => {
  if (!date) {
    message.error('请选择一个日期')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[0]) {
    message.error('请选择开始时间')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[1]) {
    message.error('请选择结束时间')
    return true
  }
  return false
}

export const userLoginVac = (username: string | undefined, password: string | undefined) => {
  if (!username) {
    message.error('用户名不能为空')
    return true
  }
  if (!password) {
    message.error('密码不能为空')
    return true
  }
  return false
}

export const addSupplierVac = (payload: AddNewSupplierPayload) => {
  if (!payload.name) {
    message.error('供应商名称不能为空')
    return true
  }
  if (!payload.phone) {
    message.error('供应商联系方式不能为空')
    return true
  }
  if (!payload.group) {
    message.error('必须选择一个策略分组')
    return true
  }
  if (!payload.weight) {
    message.error('必须填写权重')
    return true
  }
  return false
}