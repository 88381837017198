/*
 * File: /src/pages/System/View/Decoration/Emulator/Ca
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 12:41:50
 * Last Modified: Wednesday 2020-12-09 12:41:51
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC } from 'react'
import { Carousel as AntdCarousel } from 'antd'
import { useSelector } from 'react-redux'

const imgStyle = {
  width: '100%',
  height: 170
}

const Carousel: FC = () => {
  const { indexStyle } = useSelector((state: RootState) => state.decoration)

  return (
    <AntdCarousel autoplay>
      {indexStyle && indexStyle.carouseList.map(img =>
        <div>
          <img style={imgStyle} src={img.url} />
        </div>
      )}
    </AntdCarousel>
  )
}

export default Carousel
