/*
 * @Author: your name
 * @Date: 2021-04-12 10:52:43
 * @LastEditTime: 2021-04-14 16:47:27
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/InputBox/index.tsx
 */
import React, { FC, ReactNode, CSSProperties } from 'react'
import { Body, Title, Input, Mark } from './style'

interface SelectProps {
  title?: string,
  isMust?: boolean,
  children?: ReactNode,
  style?: CSSProperties,
  titleStyle?: CSSProperties,
}

const InputBox: FC<SelectProps> = (props) => {
  return (
    <Body style={props.style}>
      <Title style={props.titleStyle}>{props.isMust ? <Mark>*</Mark> : null}{props.title}:</Title>
      <Input>{props.children}</Input>
    </Body>
  )
}

export default InputBox