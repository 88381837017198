import styled from "styled-components"

export const Box = styled.div`
  width: 148px;
  height: 74px;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  float: left;
`

export const BoxSpace = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
`

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 1161px;
`