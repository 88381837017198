/*
 * File: /src/pages/System/View/Decoration/Header.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 11:04:09
 * Last Modified: Wednesday 2020-12-09 11:04:09
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useState } from 'react'
import { getSelectTabKey } from 'utils/common'
import { useHistory } from 'react-router-dom'
import { Header } from 'components'
import { BreadcrumbOption, TabOption } from 'types/antd'

const breadcrumbs: BreadcrumbOption[] = [{
  name: '系统配置',
}, {
  name: '店铺装修'
}]

const tabs: TabOption[] = [{
  key: '0',
  name: '导航栏相关页面',
  link: '/system/decoration/main'
}, {
  key: '1',
  name: '时间页',
  link: '/system/decoration/time'
}]

const Head: FC = () => {
  const { pathname } = useHistory().location
  const [selectTabKey, setSelectTabKey] = useState<string>(getSelectTabKey(tabs, pathname))

  return (
    <Header
      title='店铺装修'
      description="在后台页面中，大批量的数据修改和提交是很常见的情况。"
      tabs={tabs}
      breadcrumbs={breadcrumbs}
      selectTabKey={selectTabKey}
      onTabClick={key => setSelectTabKey(key)}
    />
  )
}

export default Head
