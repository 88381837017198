/*
 * @Author: your name
 * @Date: 2021-04-12 17:56:42
 * @LastEditTime: 2021-04-13 17:24:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Reject/View/Detail.tsx
 */
import { MenuOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, message, Modal, Popconfirm, Select, Switch, Table } from "antd"
import {  SortableContainer as SortableContainerOS, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Block, InputBox } from "components"
import React, { FC, useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { BlockLabel, DetailTitle, ModalBody } from "./style"
import { system } from "services";
import { customOrderDefaultModelForm, customOrderEditColumns, customOrderTypeList } from "consts/system"
import "./style.css"

const Detail: FC = () => {

  const SortableItem = SortableElement((props: any) => <tr {...props} />)
  const SortableContainer = SortableContainerOS((props: any) => <tbody {...props} />)

  const history = useHistory()
  const id = Number(useParams<{ id: string }>().id)
  const [loading, setLoading] = useState<boolean>(false)
  const [modelForm, setModelForm] = useState<any>(customOrderDefaultModelForm)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [rnd, setRnd] = useState<number>(0)
  const [onlineForm, setOnlineForm] = useState<any>()
  const [form, setForm] = useState<any>({
    name: "",
    is_disable: false,
    is_add: false,
    entry_list: []
  })

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        if (id) {
          const data = await system.getCustomOrder({ id })
          const result = { ...data, entry_list: data.entry_list.map((item: any) => ({ ...item, type: handleOnlineSwitch(item.type) })) }
          setOnlineForm(result)
          setForm(result)
        }
      } catch (error) {
        message.error("获取失败")
      } finally {
        setLoading(false)
      }
    })()
  }, [id, rnd])

  const handleOnlineSwitch = (type: string) => {
    switch (type) {
      case "single": return "单选"
      case "multiple": return "多选"
      case "text": return "文字输入框"
      case "image": return "图片上传"
      case "phone": return "手机号码"
      case "id_card": return "身份证号"
      case "license_plate": return "车牌号"
      case "date": return "日期"
      case "Chinese": return "汉字"
      case "number": return "数字"
      case "time": return "时间"
      default: return type
    }
  }

  const createThisForm = useCallback(async () => {
    const newData = JSON.parse(JSON.stringify(form))
    newData.entry_list = form.entry_list && form.entry_list.map((item: any, index: number) => ({
      ...item,
      index,
      type: handleSwitch(item.type),
      options: item.options ? item.options : []
    }))
    if (!newData.name) {
      message.warning('请填写订单信息名称！')
      return
    } else if (!newData.entry_list.length) {
      message.warning('请至少添加一条自定义订单信息！')
      return
    }
    setLoading(true)
    if (id) {
      try {
        await system.editCustomOrder({ ...newData, id })
        message.success("修改成功")
        setRnd(Math.random())
        history.push("/reject/list")
      } catch (err) {
        message.error("修改失败")
      } finally {
        setLoading(false)
      }
    } else {
      try {
        await system.createCustomOrder(newData)
        message.success("创建成功")
        setRnd(Math.random())
        history.push("/reject/list")
      } catch (err) {
        message.error("创建失败")
      } finally {
        setLoading(false)
      }
    }
  }, [form, id])

  const handleSwitch = (type: string) => {
    switch (type) {
      case "单选": return "single"
      case "多选": return "multiple"
      case "文字输入框": return "text"
      case "图片上传": return "image"
      case "手机号码": return "phone"
      case "身份证号": return "id_card"
      case "车牌号": return "license_plate"
      case "日期": return "date"
      case "汉字": return "Chinese"
      case "数字": return "number"
      case "时间": return "time"
      default: return type
    }
  }

  const handleForm = useCallback((data: any[]) => {
    if (!data || !data.length) return []
    return data.map(item => {
      return ({
        index: item.index,
        name: item.name,
        type: item.type,
        handle: <div style={{ marginLeft: -15 }}>
          <Button type="link" onClick={() => { handleClickEdit(item.index) }}>修改</Button>
          <Popconfirm
            title="确定删除吗？删除后不可恢复"
            okText="删除"
            cancelText="我点错了"
            onConfirm={() => handleClickDel(item.index)}
          >
            <Button type="link" >删除</Button>
          </Popconfirm>
        </div>
      })
    })
  }, [form])

  const handleClickDel = useCallback(async (index: number) => {
    const newData = JSON.parse(JSON.stringify(form))
    newData.entry_list.splice(index, 1)
    setForm(newData)
  }, [form])

  const handleClickEdit = useCallback((index: number) => {
    index === form.entry_list.length ?
      setModelForm({ ...customOrderDefaultModelForm, index }) :
      setModelForm(form.entry_list.filter((item: any) => item.index === index)[0])
    setIsOpen(true)
  }, [form])

  const handleChangeModalInput = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newData = JSON.parse(JSON.stringify(modelForm))
    newData.options[index] = e.target.value
    setModelForm(newData)
  }, [modelForm])

  const handleRemoveModalOptions = useCallback((index: number) => {
    const newData = JSON.parse(JSON.stringify(modelForm))
    newData.options.splice(index, 1)
    setModelForm(newData)
  }, [modelForm])

  const submitModal = useCallback(async () => {
    if (addCustomOrderModalVac(modelForm)) return
    const newData = JSON.parse(JSON.stringify(form))
    const index = newData.entry_list.findIndex((item: { index: number }) => item.index === modelForm.index)
    index === -1 ?
      newData.entry_list.push(modelForm) :
      newData.entry_list.splice(index, 1, modelForm)
    setForm(newData)
    setIsOpen(false)
  }, [modelForm, form])

  const addCustomOrderModalVac = (payload: any) => {
    if (!payload.name) {
      message.error('题目名称不能为空')
      return true
    }
    if (!payload.type) {
      message.error('请选择题目类型')
      return true
    }
    if ((payload.type === "单选" || payload.type === "多选") && !(payload.options && payload.options.length)) {
      message.error('请选至少添加一个选项')
      return true
    }
    if (!payload.tip) {
      message.error('请输入填写提示')
      return true
    }
    if ((payload.type === "文字输入框") && !payload.text_len) {
      message.error('请输入最大填写字数')
      return true
    }
    return false
  }

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...form.entry_list], oldIndex, newIndex).filter((el: any) => !!el);
      console.log('Sorted items: ', newData);
      setForm({ ...form, entry_list: newData });
    }
  }, [form])

  const DraggableContainer = useCallback((props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  ), [onSortEnd, form])

  const DraggableBodyRow = useCallback(({ className, style, ...restProps }: any) => {
    console.log(restProps)
    const index = form.entry_list.findIndex((x: any) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }, [form])

  return (
    <div>
      <Block
        showBack
        loading={loading}
        title="订单拒收原因维护"
        style={{ paddingBottom: 32 }}
        description={!Number(id) ?
          <Button type="primary" onClick={createThisForm}>创建</Button> :
          <div>
            <Popconfirm title="您确定要放弃当前更改?" onConfirm={() => { setForm(onlineForm!) }}>
              <Button>重置</Button>
            </Popconfirm>
            <Button style={{ marginLeft: 8 }} type="primary" onClick={createThisForm}>保存</Button>
          </div>}
      >
        <DetailTitle style={{ margin: "32px 0 16px" }}> 基本信息
        <Switch
            checked={!form.is_disable}
            checkedChildren="使用"
            unCheckedChildren="禁用"
            onChange={(value) => { setForm({ ...form, is_disable: !value }) }}
            style={{ marginLeft: 10 }}
          />
        </DetailTitle>
        <InputBox title="拒收信息名称" style={{ alignItems: "inherit" }} titleStyle={{ paddingTop: 8 }}>
          <Input value={form.name} style={{ width: 272 }} onChange={(e) => { setForm({ ...form, name: e.target.value }) }} />
          <div style={{ display: "flex", alignItems: "center", fontSize: 14, marginTop: 10 }}>
            <Checkbox checked={form.is_add} onChange={(e) => { setForm({ ...form, is_add: e.target.checked }) }} />
            <div style={{ marginLeft: 8 }}>允许自行添加多组</div>
          </div>
        </InputBox>
      </Block>
      <Block
        title="订单拒收原因维护"
        loading={loading}
      >
        <Button type="primary" style={{ margin: "24px 0" }} onClick={() => handleClickEdit(form.entry_list.length)}>添加填写内容</Button>
        <Table
          pagination={false}
          dataSource={handleForm(form.entry_list)}
          columns={customOrderEditColumns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Block>
      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={submitModal}
        title="添加填写内容"
      >
        <ModalBody>
          <InputBox title="题目名称" style={{ width: 320 }}>
            <Input value={modelForm.name} style={{ width: 224 }} onChange={(e) => { setModelForm({ ...modelForm, name: e.target.value.trim() }) }} placeholder="请输入" />
          </InputBox>
          <InputBox title="题目类型" style={{ width: 320, marginTop: 16 }}>
            <Select style={{ width: 224 }} value={modelForm.type} onChange={(value: string) => { setModelForm({ ...modelForm, type: value }) }} placeholder="请选择">
              {customOrderTypeList.map(optItem =>
                <Select.OptGroup label={optItem.name} key={optItem.name}>
                  {optItem.list.map((item: string) => <Select.Option key={`${optItem.name}-${item}`} value={item}>{item}</Select.Option>)}
                </Select.OptGroup>
              )}
            </Select>
            {modelForm.type === "单选" || modelForm.type === "多选" ?
              <PlusCircleOutlined
                onClick={() => setModelForm({ ...modelForm, options: modelForm.options ? [...modelForm.options, ""] : [""] })}
                style={{ fontSize: 18, marginLeft: 10 }}
              /> : null
            }
          </InputBox>
          <InputBox title="填写提示" style={{ width: 320, marginTop: 16 }}>
            <Input value={modelForm.tip} style={{ width: 224 }} onChange={(e) => { setModelForm({ ...modelForm, tip: e.target.value.trim() }) }} placeholder="请输入" />
          </InputBox>
          {modelForm.type === "文字输入框" ?
            <InputBox title="最大填写字数" style={{ width: 320, marginTop: 16, marginLeft: -53 }}>
              <Input value={modelForm.text_len} style={{ width: 224 }} onChange={(e) => { setModelForm({ ...modelForm, text_len: Number(e.target.value) }) }} placeholder="请输入" />
            </InputBox> : null
          }
          {(modelForm.type === "单选" || modelForm.type === "多选") && modelForm.options &&
            modelForm.options.length ? modelForm.options.map((item: any, index: number) => (
              <InputBox title={`选项${index + 1}`} key={index} style={{ marginTop: 16, marginLeft: 75 }}>
                <Input
                  value={item}
                  onChange={(e) => handleChangeModalInput(e, index)}
                  style={{ width: 224 }}
                />
                <MinusCircleOutlined onClick={() => handleRemoveModalOptions(index)} style={{ fontSize: 18, marginLeft: 10 }} />
              </InputBox>
            )) : null
          }
          <InputBox title="填写要求" style={{ width: 320, marginTop: 16, alignItems: "start" }}>
            <BlockLabel>
              <Checkbox
                checked={modelForm.is_require}
                onChange={(e) => setModelForm({ ...modelForm, is_require: e.target.checked })}
              />&nbsp;必填
            </BlockLabel>
          </InputBox>
        </ModalBody>
      </Modal>
    </div>
  )
}



export default Detail