import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block, Header, InputBox } from 'components'
import { BreadcrumbOption } from 'types/antd'
import { Button, Col, Input, InputNumber, message, Row } from 'antd'
import { system } from 'services'
import { NumberRule } from 'services/system'
const NumberPage: FC = () => {
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: '系统配置'
  }, {
    name: '预约规则设置'
  }])
  const [loading, setLoading] = useState<boolean>(false)
  const [render, setRender] = useState<number>(0)
  const [data, setData] = useState<NumberRule>()

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const data = await system.getNumber()
        setData(data)
      } catch (err) {
        err.response && message.error(err.response.data.message)
      } finally {
        setLoading(false)
      }
    })()
  }, [render])

  const submit = useCallback(async () => {
    try {
      setLoading(true)
      await system.editNumber(data!)
      setRender(render + 1)
      message.success("修改成功")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }, [data, render])

  return (
    <React.Fragment>
      <Header
        title="预约规则设置"
        description="供应商用户,预约每个园区次数限制"
        breadcrumbs={breadcrumbs}
      />
      <Block
        title="供应商下单次数规则"
        loading={loading}
        description={<Button loading={loading} disabled={loading} onClick={submit}>保存</Button>}
        style={{ paddingTop: 24, paddingBottom: 24 }}
      >
        <InputBox title="每日预约次数">
          <InputNumber
            value={Number(data?.daily_times)}
            onChange={(value) => setData({
              daily_times: value!.toString(),
              weekly_times: data!.weekly_times
            })
            }
          />
        </InputBox>
        <InputBox title="每周预约次数" style={{ marginTop: 24 }}>
          <InputNumber
            value={Number(data?.weekly_times)}
            onChange={(value) => setData({
              weekly_times: value!.toString(),
              daily_times: data!.weekly_times
            })
            }
          />
        </InputBox>
      </Block>
    </React.Fragment>
  )
}

export default NumberPage