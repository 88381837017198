import { get, post } from '../utils/http/http'

const baseURL = '/Admin/Order/'

export default {
  //预约实况-对象列表
  getReserveList: (payload: ReserveListPayload) => get<ReserveListResult>(`${baseURL}reserve_list`, payload),
  //请求列表-搜索用户
  getUserList: (payload: { keyword: string }) => get<User[]>(`/Scene/reserve_user`, payload),
  //手动添加预约-添加预约
  postNewEvent: (payload: NewEventPayload) => post<any>(`${baseURL}do_reserve`, payload),
}

export interface User {
  id: string,
  name: string,
  phone: string,
  supplier: string
}

export interface NewEventPayload {
  id: number,
  reread_rule: string,
  times: {
    [key: string]: number[]
  },
  resource_id: number,
  custom_rule: {
    period_space: string| null,
    period_value: number | null,
    day: number[]| null,
    period_type: string| null,
    period_data: string| null,
  }
}

interface ReserveListPayload {
  date: string,
  resource_id: string
}

interface ReserveListResult {
  [key: string]: Reserve[]
}

interface Reserve {
  id: string,
  resource_id: string,
  time: string,
  max_load: number,
  use_load: number,
  color: string,
  state: string,
}
