import React, { FC, useEffect } from 'react'
import qrcode from 'qrcode'
import { Block } from 'components'
import { getSystemInfo } from 'utils/common'

const Attend: FC = () => {
  useEffect(() => {
    const name = getSystemInfo().subnet_name
    const content = process.env.REACT_APP_API + `/pages/Attend/index?system=${name}`
    const dom = document.getElementById('canvas')
    qrcode.toCanvas(dom, content, { width: 450 })
  }, [])

  return (
    <Block>
      <canvas id="canvas"></canvas>
    </Block>
  )
}

export default Attend
