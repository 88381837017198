/*
 * @Author: your name
 * @Date: 2020-11-06 21:18:18
 * @LastEditTime: 2021-04-13 17:15:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/system.ts
 */
import { MenuOutlined } from "@ant-design/icons"
import React from "react"
import { SortableHandle } from 'react-sortable-hoc'

export const customOrderTypeList = [
  { name: "普通类型", list: ["单选", "多选", "文字输入框"] },
]

export const customOrderDefaultModelForm: any = {
  name: "",
  type: "",
  index: 0,
  options: [],
  number: 1,
  is_cache: false,
  is_require: false,
  is_custom_add: false,
  //日期
  is_select_history: false,
  is_select_range_date: false,
  range_date: 1,
  //时间
  range_time: [],
  is_use_range_time: false,
  //数字
  max_number: 1,
  min_number: 1,
  fixed: 1,
  is_use_max_number: false,
  is_use_min_number: false,
  is_use_fixed: false,
  number_header_text: "",
  number_footer_text: "",
  number_is_use_header_text: false,
  number_is_use_footer_text: false,
  //汉字
  max_text: 1,
  min_text: 1,
  is_use_max_text: false,
  is_use_min_text: false,
  Chinese_header_text: "",
  Chinese_footer_text: "",
  Chinese_is_use_header_text: false,
  Chinese_is_use_footer_text: false,
  tip: "",
  text_len: 150,
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
))

export const customOrderEditColumns = [{
  title: '排序',
  dataIndex: 'sort',
  width: 60,
  className: 'drag-visible',
  render: () => <DragHandle />,
}, {
  key: 'name',
  dataIndex: 'name',
  title: '名称',
  className: 'drag-visible'
}, {
  key: 'type',
  dataIndex: 'type',
  title: '类型'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]