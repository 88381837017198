import React, { FC, useState, useEffect, useCallback } from 'react'
import Container from 'components/mobile/Container'
import services from 'services'
import { Button, message, Input, Row, Col } from 'antd'
import { useParams } from 'react-router'
import { RefuseForm, RefuseFormField } from 'types/order'
import { ActionSheet, List } from 'antd-mobile'
import { ButtonWrapper, FormEntry, Label, Paper, PlusButton, Must } from './style'
import renderCheckboxSheet from './CheckboxSheet'
import { PlusOutlined } from '@ant-design/icons'

const Cancel: FC = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const [loading, setLoading] = useState<boolean>(false)
  const [formList, setFormList] = useState<RefuseForm[]>([])
  const [formValue, setFormValue] = useState<AnyObject>({})

  useEffect(() => {
    services.order.getRefuseFormList()
      .then(data => {
        setFormList(data)
      })
      .catch(() => {
        message.warning('获取拒收原因表单列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleSubmit = useCallback(() => {
    const result: AnyObject[] = []

    for (let i = 0; i < formList.length; ++i) {
      const form = formList[i]
      for (const field of form.item) {
        const fieldName = `${field.id}-${i}`
        const _value = Array.isArray(formValue[fieldName]) ? formValue[fieldName].join(',') : formValue[fieldName]
        if (field.is_require && _value === undefined || _value === '') {
          message.error(`${field.name}为必填！`)
          return
        }
        result.push({ name: field.name, type: `${form.name}-${i}`, value: _value || '' })
      }
    }

    setLoading(true)
    services.order.action({
      action: '拒绝收货',
      id: [orderId],
      payload: { content: '', refuse: result }
    })
      .then(() => {
        message.success('拒绝收货成功！')
        setTimeout(() => {
          window.location.href = `/order/detail/${orderId}`
        }, 200)
      })
      .catch(() => {
        message.error('拒绝收货失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [formValue, formList])

  const showRadioSheet = useCallback((fieldName: string, _message: string, options: string[]) => {
    const _options = [...options, '取消']
    ActionSheet.showActionSheetWithOptions({
      options: _options,
      cancelButtonIndex: _options.length - 1,
      message: _message,
      maskClosable: true,
    },
      buttonIndex => {
        if (buttonIndex === _options.length - 1) return
        formValue[fieldName] = _options[buttonIndex]
        setFormValue({ ...formValue })
      }
    )
  }, [formValue])

  const showCheckboxSheet = useCallback((fieldName: string, title: string, options: string[]) => {
    renderCheckboxSheet({ title, options, value: formValue[fieldName] || [] }, value => {
      formValue[fieldName] = value
      setFormValue({ ...formValue })
    })
  }, [formValue])

  const renderField = useCallback((field: RefuseFormField, index: number) => {
    const fieldName = `${field.id}-${index}`
    switch (field.type) {
      case 'single': {
        return (
          <List.Item arrow="horizontal">
            <Row align="middle">
              <Label>
                {field.is_require && <Must>*</Must>}
                {field.name}
              </Label>
              <Col style={{ flexGrow: 1 }}>
                <Input
                  placeholder={field.placeholder}
                  bordered={false}
                  value={formValue[fieldName]}
                  onClick={() => showRadioSheet(fieldName, field.name, field.options)}
                />
              </Col>
            </Row>
          </List.Item>
        )
      }
      case 'text': {
        return (
          <List.Item>
            <Row align="middle">
              <Label>
                {field.is_require && <Must>*</Must>}
                {field.name}
              </Label>
              <Col style={{ flexGrow: 1 }}>
                <Input
                  placeholder={field.placeholder}
                  bordered={false}
                  value={formValue[fieldName]}
                  onChange={e => {
                    formValue[fieldName] = e.target.value
                    setFormValue({ ...formValue })
                  }}
                />
              </Col>
            </Row>
          </List.Item>
        )
      }
      case 'multiple': {
        return (
          <List.Item arrow="horizontal">
            <Row align="middle">
              <Label>
                {field.is_require && <Must>*</Must>}
                {field.name}
              </Label>
              <Col style={{ flexGrow: 1 }}>
                <Input
                  placeholder={field.placeholder}
                  bordered={false}
                  onClick={() => showCheckboxSheet(fieldName, field.name, field.options)}
                  value={formValue[fieldName]}
                  onChange={e => {
                    formValue[fieldName] = e.target.value
                    setFormValue({ ...formValue })
                  }}
                />
              </Col>
            </Row>
          </List.Item>
        )
      }
    }
  }, [])

  return (
    <Container
      loading={loading}
      style={{ paddingBottom: 60, minHeight: '100vh' }}
      bgColor="#F7F8FA"
    >
      {formList.map((form, index) =>
        <Paper key={form.id}>
          <FormEntry>{form.name}</FormEntry>
          <List style={{ borderBottom: '10px solid #F7F8FA' }}>
            {form.item.map((field => renderField(field, index)))}
          </List>
          {form.is_add && formList[index + 1]?.id !== form.id &&
            <PlusButton
              onClick={() => {
                formList.splice(index, 0, form)
                setFormList([...formList])
              }}
            >
              <PlusOutlined />追加一组记录
        </PlusButton>
          }
        </Paper>
      )}
      <ButtonWrapper>
        <Button
          style={{ width: '40%', margin: 10 }}
          size="large"
          onClick={() => window.history.back()}
        >取消</Button>
        <Button
          style={{ width: '40%', margin: 10 }}
          size="large"
          type="primary"
          onClick={handleSubmit}
        >确认拒收</Button>
      </ButtonWrapper>
    </Container>
  )
}

export default Cancel
