/*
 * @Author: your name
 * @Date: 2021-04-12 10:52:43
 * @LastEditTime: 2021-04-14 16:50:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/InputBox/style.ts
 */
import styled from "styled-components"

export const Body = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  /* overflow: hidden; */
  white-space: nowrap;  
  margin-right: 5px;
  position: relative;
`

export const Input = styled.div`
  
`

export const Mark = styled.span`
  color: red;
  position: absolute;
  top: -5px;
  left: -5px;
`