import { Button, Drawer, Empty, message, Select, Spin } from 'antd'
import React, { FC, Fragment, ReactNode, useCallback, useEffect, useState } from 'react'
import { CustomRuleType, PeriodSpaceType, PeriodType, weekItem } from 'types/time'
import { FormatDay } from 'utils/time'
import { daily } from 'services'
import manual, { NewEventPayload, User } from 'services/manual'
import { Block, InputBox } from 'components'
import Box from '../Model/Box'
import { TimeFragment } from 'types/app'
import { debounce } from 'utils/common'
import WeekTable from 'components/WeekTable'
import { BoxSpace, NoneBox } from './style'
import { getWeekTableRange } from 'components/WeekTable/utils'
import Reread from '../Model/Reread'
import { defaultCustomRule } from 'consts/time'

let debounced: (...args: any[]) => any

const Detail: FC = () => {
  const { Option } = Select
  const now = new Date()
  const [date, setDate] = useState<Date>(now)
  const [weekList, setWeekList] = useState<weekItem[]>([])
  const [resourceList, setResourceList] = useState<{ id: string, name: string }[]>()
  const [selectResource, setSelectResource] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [FragmentList, setFragmentList] = useState<TimeFragment[]>()
  const [selectTimeFragmentList, setSelectTimeFragmentList] = useState<string[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [userList, setUserList] = useState<User[]>([])
  const [selectUser, setSelectUser] = useState<string>()
  const [render, setRender] = useState<number>(1)
  const [scrollTop, setScrollTop] = useState<number>(590)
  const [rereadRule, setRereadRule] = useState<string>("")
  const [customRule, setCustomRule] = useState<CustomRuleType>(defaultCustomRule)

  //渲染空的格子
  const renderNoneBoxList = useCallback(() => {
    const result: ReactNode[] = []
    for (let index = 0; index < 168; index++) {
      result.push(<NoneBox><BoxSpace /></NoneBox>)
    }
    return result
  }, [])

  //请求预约对象列表
  useEffect(() => {
    (async () => {
      try {
        const data = await daily.getResourceList()
        setResourceList(data)
      } catch (err) {
        err.response && message.error(err.response.data.message)
      }
    })()
  }, [])

  //请求时间列表
  useEffect(() => {
    selectResource && (async () => {
      setLoading(true)
      const data = await manual.getReserveList({
        date: FormatDay(new Date(date.getTime() + (24 * 60 * 60 * 1000))),
        resource_id: selectResource?.toString()
      })
      setLoading(false)
      const result: TimeFragment[] = []
      for (let date in data) {
        data[date].forEach(item => {
          result.push({ ...item, date, display_id: `${item.id}_${date}_${item.time}` })
        })
      }
      setFragmentList(result)
    })()
  }, [selectResource, date, render])

  const handleClickFragment = useCallback((display_id: string) => {
    if (!!selectTimeFragmentList?.some(id => id === display_id)) {
      const temp = [...selectTimeFragmentList]
      temp.splice(temp.findIndex(item => item === display_id), 1)
      setSelectTimeFragmentList(temp)
    } else {
      setSelectTimeFragmentList(selectTimeFragmentList?.concat(display_id))
    }
  }, [selectTimeFragmentList])

  const handleChangeSelect = useCallback(async (keyword: string) => {
    if (keyword === "") {
      setUserList([])
      return
    }
    if (!debounced) {
      debounced = debounce(manual.getUserList, 500)
    }
    setUserLoading(true)
    try {
      const data = await debounced({ keyword })
      setUserList(data)
    } finally {
      setUserLoading(false)
    }
  }, [setUserList, debounce])

  const handleSubmit = useCallback(async () => {
    const times: { [key: string]: number[] } = {}
    selectTimeFragmentList.map(item => {
      const [id, date, _time] = item.split("_")
      if (!(date in times)) {
        times[date] = []
      }
      times[date] = [...times[date], Number(id)]
    })

    const payload: NewEventPayload = {
      id: Number(selectUser),
      resource_id: Number(selectResource),
      times,
      reread_rule: rereadRule,
      custom_rule: customRule
    }
    try {
      setSubmitLoading(true)
      await manual.postNewEvent(payload)
      setRender(render + 1)
      setShow(false)
      setCustomRule(defaultCustomRule)
      message.success("预约添加成功")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    } finally {
      setSubmitLoading(false)
    }

  }, [
    selectUser, selectResource, selectTimeFragmentList, rereadRule, customRule, render
  ])

  //计算时间格子生成后移动高度
  useEffect(() => {
    if (!FragmentList || !FragmentList.length) return
    let min = 590
    FragmentList.forEach(item => {
      const [hour, minute] = item.time.split("-")[0].split(":")
      const startTime = Number(hour) * 60 + Number(minute)
      if (min > getWeekTableRange(startTime)) min = getWeekTableRange(startTime)
    })
    setScrollTop(min - 1 + Math.random())
  }, [FragmentList])

  useEffect(() => {
    setSelectTimeFragmentList([])
  }, [date, selectResource, render])

  return (
    <Fragment>
      <Block
        style={{ paddingTop: 17 }}
      >
        <WeekTable
          now={now}
          date={date}
          loading={loading}
          scrollTop={scrollTop}
          setDate={(payload) => { setDate(payload) }}
          renderNoneBoxList={renderNoneBoxList}
          getWeekList={(weekList) => setWeekList(weekList)}
          customHeader={
            <React.Fragment>
              <InputBox title="预约项目" style={{ marginRight: 30 }}>
                <Select
                  style={{ minWidth: 200, marginLeft: 15 }}
                  placeholder="请选择预约项目"
                  onChange={(value) => { setSelectResource(value) }}
                  value={selectResource}
                >
                  {resourceList && resourceList.length && resourceList.map(item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </InputBox>
              <Button
                disabled={!selectTimeFragmentList.length}
                onClick={() => setShow(true)}
                type="primary"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0
                }}
              >
                确认预约
                 </Button>
            </React.Fragment>
          }
        >
          {FragmentList?.map(item => <Box
            data={item}
            isSelect={!!(selectTimeFragmentList?.some(id => id === item.display_id))}
            weekList={weekList}
            onClick={handleClickFragment}
          />)}
        </WeekTable>
      </Block>
      <Drawer
        visible={show}
        onClose={() => setShow(false)}
        width={512}
        title="手动添加预约"
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button style={{ marginRight: 8 }} onClick={() => setShow(false)}>取消</Button>
            <Button loading={submitLoading} disabled={!(selectUser && !submitLoading)} onClick={handleSubmit} type="primary">确定</Button>
          </div>
        }
      >
        <div>预约用户</div>
        <Select
          style={{ width: 464, marginTop: "10px" }}
          defaultActiveFirstOption={false}
          showSearch
          value={selectUser}
          placeholder="选择要添加的用户"
          showArrow={false}
          filterOption={false}
          onSearch={handleChangeSelect}
          notFoundContent={userLoading ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          onChange={(value) => { setSelectUser(value) }}
        >
          {
            userList.map(item => <Option value={item.id}>{item.name}-{item.phone}-{item.supplier}</Option>)
          }
        </Select>
        <div style={{ marginTop: "10px" }}>已选择时间段:</div>
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", color: "#999" }}>
          {
            selectTimeFragmentList && selectTimeFragmentList.length ?
              selectTimeFragmentList.map(item => (
                <div style={{ marginTop: "5px" }}>{item.split("_")[1]}&nbsp;&nbsp;&nbsp;&nbsp;{item.split("_")[2]}</div>)
              ) : null
          }
        </div>
        <Reread
          reread_rule={rereadRule}
          custom_rule={customRule}
          onchangeRereadRule={(value) => setRereadRule(value)}
          onchangeCustomRule={(value) => setCustomRule(value)}
        />
      </Drawer>
    </Fragment>
  )
}

export default Detail