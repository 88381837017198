/*
 * @Author: your name
 * @Date: 2021-04-19 18:13:39
 * @LastEditTime: 2021-04-20 15:42:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Statistics/style.ts
 */
import styled from "styled-components"

export const BoxList = styled.div`
  display: flex;
`

export const Space = styled.div`
  width: 24px;
  height: 10px;
`

export const TitleCol = styled.div`
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 32px;
  margin-bottom: 12px;
`

export const TextCol = styled.div`
  font-size: 14px;
  min-height: 22px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
`

export const PageText = styled.div`
  color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  padding: 16px 0;
  font-weight: 500;
`