/*
 * @Author: your name
 * @Date: 2020-10-23 15:56:45
 * @LastEditTime: 2021-04-19 15:22:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/url.ts
 */
export const baseURL = process.env.REACT_APP_API
export const ossURL = 'http://storage.joyreserve.com/Public/phoenix'
export const OSS_SIGN = 'https://oss-sign.joyreserve.com'
export const OSS_EXCEL = 'https://storage.joyreserve.com/Public/mvp/scene/johnson'
export const ICONFONTCN = 'https://at.alicdn.com/t/font_2182404_q26ido0mvva.js'
