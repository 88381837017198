import styled from 'styled-components'

export const RichTextWrapper = styled.div`
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 3px;
  min-height: 70px;
  max-height: 300px;
  overflow: scroll;
`
