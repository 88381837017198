import styled from "styled-components"

export const MiniBox = styled.div`
  width: 148px;
  height: 37px;
  border: 1px solid rgba(0,0,0,0);
  position: relative;
  float: left;
`

export const MaxBox = styled.div`
  width: 148px;
  height: 888px;
  border: 1px solid rgba(0,0,0,0);
  position: relative;
  float: left;
`

export const BoxSpace = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;

  :hover {
    background: none;
  }
`

export const BoxWhite = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const BoxContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color:rgba(0,0,0,0.45);
  font-size: 12px;
  cursor: pointer;
  border-left: 1px solid white;
  border-right: 1px solid white;
`

export const Text = styled.div`
  user-select: none;
  margin-left: 5px;
  margin-top: 7px;
`

export const Moulding = styled.div`
  width: 100%;
  height: 2px;
`

