/*
 * @Author: your name
 * @Date: 2020-12-14 17:33:42
 * @LastEditTime: 2020-12-18 17:32:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/policy.ts
 */
import { get, post, put, del } from '../utils/http/http'
import { Policy, PolicyDetail } from 'types/policy'
import { Supplier } from 'types/supplier'

const baseURL = '/Scene/'

export default {
  // 新增策略分组
  add: (data: AddPayload) => post<IdResult>(`${baseURL}policys`, data),
  // 更新策略分组
  update: (data: UpdatePayload, payload: IdPayload) => put<any>(`${baseURL}policy/${payload.id}`, data),
  // 获取策略分组列表
  getSort: () => get<any>(`/Admin/Resource/resource_sup_list`),
  // 获取策略分组列表
  addSort: (payload: { resource_id: number }) => post<any>(`/Admin/Resource/add_resource_sup`, payload),
  // 获取策略分组列表
  getList: (id: number, payload: PagePayload) => get<GetListResult>(`${baseURL}policys`, { ...payload, resource_id: id }),
  // 获取策略分组详情
  getDetail: (payload: IdPayload) => get<PolicyDetail>(`${baseURL}policy/${payload.id}`, payload),
  // 获取策略分组内供应商列表
  getSupplierList: (id: string, page: PagePayload) => get<GetSupplierListResult>(`${baseURL}policy/${id}/suppliers`, page),
  // 添加供应商到策略分组
  addSuppliers: (id: string, payload: AddSuppliersPayload) => post<any>(`${baseURL}policy/${id}/suppliers`, payload),
  // 从供应商分组中移除供应商
  removeSupplier: (policyId: string, supplierId: string) => del<any>(`${baseURL}policy/${policyId}/suppliers/${supplierId}`),
  // 送货时间表-获取
  getCalendar: (payload: { id: string }) => get<CalendarResult>(`${baseURL}policy_calendar/${payload.id}`),
  // 送货送货时间表-提交通用
  updateGeneral: (payload: { id: string, data: { cycle: string[] } }) => put(`${baseURL}policy_calendar/general/${payload.id}`, payload.data),
  // 送货送货时间表-提交通用
  updateSpecial: (payload: { id: string, data: { cycle: string[] } }) => put(`${baseURL}policy_calendar/special/${payload.id}`, payload.data)
}

interface CalendarResult {
  general: string[],
  special: string[]
}

interface AddPayload {
  name: string,
  type: string,
  list: { name: string }[]
  resource_id: number,
}

interface UpdatePayload {
  name: string,
  type: string,
  remark: string
}

interface GetListResult {
  data: Policy[],
  total: number
}

interface GetSupplierListResult {
  data: Supplier[]
  total: number
}

interface AddSuppliersPayload {
  list: { name: string }[]
}
