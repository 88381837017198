import { init, RematchRootState, RematchDispatch } from '@rematch/core'
import * as models from './models'

const store = init<typeof models>({ models })

export type Dispatch = RematchDispatch<typeof models>
export type RootState = RematchRootState<typeof models>

declare global {
  type Dispatch = RematchDispatch<typeof models>
  type RootState = RematchRootState<typeof models>
}

export default store
