/*
 * File: /src/pages/System/View/Decoration/Emulator/Head.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 02:25:45
 * Last Modified: Wednesday 2020-12-09 02:25:45
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment, useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import { createFromIconfontCN, LeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import { ICONFONTCN } from 'consts/url'

const IconFont = createFromIconfontCN({ scriptUrl: ICONFONTCN })

const Head: FC = () => {
  const [time, setTime] = useState<string>(moment(Date.now()).format('HH:mm'))

  useEffect(() => {
    setInterval(() => setTime(moment(Date.now()).format('HH:mm')), 60000)
  }, [])

  return (
    <Fragment>
      <Row justify="space-between" style={{ padding: '6px 12px' }}>
        <Col>{time}</Col>
        <Col>
          <Row align="middle" gutter={[2, 0]}>
            <Col style={{ marginTop: '-2px' }}>
              <IconFont type="iconxinhao" style={{ fontSize: 16, color: '#515151' }} />
            </Col>
            <Col>
              <IconFont type="iconwifi" style={{ fontSize: 18, color: '#515151' }} />
            </Col>
            <Col>
              <IconFont type="icondianchi" style={{ fontSize: 18, color: '#515151' }} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" style={{ padding: '4px 6px' }}>
        <Col span={8}><LeftOutlined /></Col>
        <Col span={8} style={{ textAlign: 'center' }}>标题</Col>
        <Col span={8}>
          <Row style={{ borderRadius: 12, float: 'right', border: '1px solid #EFEFEF', padding: '0px 8px' }}>
            <Col>
              <IconFont type="iconxiaochengxu-gengduo" style={{ fontSize: 18 }} />
            </Col>
            <Col>
              <Divider type="vertical" />
            </Col>
            <Col style={{ marginTop: '-1px' }}>
              <IconFont type="iconxiaochengxu-guanbi" style={{ fontSize: 14 }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Head
