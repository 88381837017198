import React, { FC, useEffect, useState, useCallback, Fragment } from 'react'
import { Block } from 'components'
import { Tabs, Select, Button, Input, Table, message, Modal } from 'antd'
import { order } from 'services'
import { Header, Nav, Title, View } from './style'
import { ConfigureResult, ActionPayload, Order } from 'services/order'
import { ExportDialog, Refuse } from '../Model'
import { renderTableRow, renderTableCustom } from '../scene'
import { Tab, Column } from 'types/order'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, Dispatch } from 'src/store'
import TextArea from 'antd/lib/input/TextArea'

const List: FC<{ configure?: ConfigureResult }> = (props) => {
  const { TabPane } = Tabs
  const { Option } = Select
  const { Search } = Input
  const { order: { selectTab, selectOption, pageSize, page } } = useSelector((state: RootState) => state)
  const { setSelectTab, setSelectOption, setPageSize, setPage } = useDispatch<Dispatch>().order
  const [loading, setLoading] = useState<boolean>(true)
  const [tabList, setTabList] = useState<Tab[]>()
  const [total, setTotal] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [columns, setColumns] = useState<Column[]>([])
  const [data, setData] = useState<any>([])
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])
  const [render, setRender] = useState<number>(1)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false)
  const [actionName, setActionName] = useState<string>("")
  const [refuseList, setRefuseList] = useState<any[]>()
  const [refuseData, setRefuseData] = useState<{ [key: number]: string }>()
  const [template, setTemplate] = useState<any>()
  const [content, setContent] = useState<string>("")

  //生成列名
  const handleColumns = useCallback((table: { [key: string]: string[] }) => {
    let result = []
    for (let title in table) {
      result.push({
        key: table[title].join(),
        dataIndex: table[title].join(),
        title
      })
    }
    result.push({
      key: 'custom',
      dataIndex: 'custom',
      title: '操作'
    })
    setColumns(result)
  }, [])

  //生成数据
  const handleData = useCallback((data: Order[]) => {
    if (!data || !data.length) return []
    const result: any[] = []
    data.forEach(order => {
      const item: any = {}
      columns.forEach(column => {
        const titleList = column.key.split(',')
        item[column.key] = renderTableRow(column.key, order, titleList)
      })
      item.key = order.id
      item.custom = renderTableCustom(item.key)
      result.push(item)
    })
    return result
  }, [columns])

  //请求列表
  useEffect(() => {
    columns && columns.length && (async () => {
      setLoading(true)
      const data = await order.getList({
        status: selectOption || '',
        q: search,
        page_size: pageSize.toString(),
        page: page.toString()
      })
      setLoading(false)
      setTotal(data.total)
      setData(handleData(data.data))
    })()
  }, [page, pageSize, selectOption, search, columns, handleData, render])

  // init
  useEffect(() => {
    if (!props.configure) return
    setLoading(false)
    const { status, table } = props.configure
    let temp: Tab[] = []
    for (let name in status) { temp.push({ name, options: status[name] }) }
    temp.unshift({ name: '全部订单', options: [] })
    setTabList(temp)
    if (!selectTab) {
      setSelectTab(temp[0])
    }
    handleColumns(table)
  }, [handleColumns, props.configure, setSelectTab, selectTab])

  useEffect(() => {
    if (actionName && template && template.length) {
      const result = template.filter((item: any) => actionName === item.state)
      setRefuseList(result && result.length ? result[0] : void 0)
    }
  }, [actionName, template])

  //点击按钮
  const handleClickButton = useCallback((action) => {
    setActionName(action)
    switch (action) {
      case "同意": case "签到": case "补签": onConfirm(action); return
      default: setIsModelOpen(true)
    }
  }, [render, actionName])

  // 切换Tab事件
  const handleChangeTabs = useCallback((name) => {
    if (!tabList) return
    setPage(1)
    setSelectTab(tabList.filter(item => item.name === name)[0])
    setSelectOption(tabList.filter(item => item.name === name)[0].options[0])
    setSelectRowIdList([])
  }, [tabList, setPage])

  const onConfirm = useCallback(async (action) => {
    try {
      let payload: ActionPayload = {
        id: selectRowIdList as string[],
        action,
        payload: {}
      }
      switch (action) {
        case "同意": case "签到": break
        case "拒绝收货":
          const refuse: any[] = []
          const resultRefuse: any[] = []
          for (let id in refuseData) {
            const item: any = refuseData[id as any]
            const temp = {
              name: item.name,
              type: item.formName,
              value: void 0
            }
            switch (item.type) {
              case "text":
                if (item.require && !item.value) {
                  message.error(`请填写${item.name}`)
                  return
                }
                if (item.value && item.value.length > item.text_len) {
                  message.error(`${item.name}最多只可填写${item.text_len}字`)
                  return
                }
                temp.value = item.value
                break

              default:
                if (item.require && !item.length) {
                  message.error(`请选择${item.name}`)
                  return
                }
                temp.value = item.value instanceof Array ? item.value.join() : item.value
            }
            refuse.push(temp)
          }
          refuse.forEach(item => {
            if (item.value) {
              resultRefuse.push(item)
            }
          })
          payload.payload["refuse"] = resultRefuse
          break
        default:
          payload.payload["content"] = content
      }
      await order.action(payload)
      setRender(render + 1)
      setPage(1)
      message.success(`您已完成操作"${action}"`, 5)
    } catch (err) {
      message.error(`操作出现异常`, 5)
    }
  }, [render, selectRowIdList, setPage, content, refuseData])

  const renderButtonList = useCallback(() => {
    if (!selectOption) return null
    switch (selectOption) {
      case '待审核':
        return <Button onClick={() => handleClickButton("拒绝")} style={{ marginLeft: 8 }} disabled={!(selectRowIdList && selectRowIdList.length)}>批量拒绝</Button>
      case '待签到':
        return <Button onClick={() => handleClickButton("取消")} style={{ marginLeft: 8 }} disabled={!(selectRowIdList && selectRowIdList.length)}>批量取消</Button>
      case '已签到':
        return <Fragment>
          <Button onClick={() => handleClickButton("同意收货")} style={{ marginLeft: 8 }} disabled={!(selectRowIdList && selectRowIdList.length)}>批量收货</Button>
          <Button onClick={() => handleClickButton("爽约")} style={{ marginLeft: 8 }} disabled={!(selectRowIdList && selectRowIdList.length)}>批量爽约</Button>
        </Fragment>
    }
  }, [selectRowIdList, selectOption, onConfirm, content, refuseData])

  return <Fragment>
    <Block
      loading={loading}
      style={{
        paddingTop: '32px'
      }}>
      {tabList && tabList.length ?
        <Tabs
          defaultActiveKey={selectTab?.name}
          type="card"
          onChange={handleChangeTabs}
          style={{ borderBottom: 'none' }}
        >
          {tabList.map(item => <TabPane key={item.name} tab={item.name} />)}
        </Tabs>
        : null
      }
      <Header>
        <Title>订单列表</Title>
        <Nav>
          {selectTab && selectTab.options && selectTab.options.length ?
            <Select
              value={selectOption}
              style={{ width: 200 }}
              placeholder='全部列表'
              onChange={(value) => {
                setSelectOption(value)
                setSelectRowIdList([])
                setPage(1)
              }}
            >
              {selectTab.options.map(item => <Option key={item} value={item}>{item}</Option>)}
            </Select>
            : null
          }
          <Search
            placeholder="请输入订单号或用户名"
            onSearch={value => setSearch(value)}
            style={{ width: 320, marginLeft: 8 }}
          />
          <Button type='primary' style={{ marginLeft: 8 }} onClick={() => { setIsOpen(true) }}>导出EXCEL</Button>
          {renderButtonList()}
        </Nav>
      </Header>
      <Table
        columns={columns}
        dataSource={data}
        rowSelection={{
          selectedRowKeys: selectRowIdList,
          onChange: selectedRowKeys => { setSelectRowIdList(selectedRowKeys) }
        }}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _pageSize) => {
            if (_page !== page) setPage(_page)
            if (_pageSize !== pageSize) setPageSize(_pageSize!)
          },
        }}
        style={{
          marginTop: 24
        }}
      />
    </Block >
    {tabList ?
      <ExportDialog
        tabList={tabList}
        isOpen={isOpen}
        onClose={() => { setIsOpen(false) }}
      />
      : null
    }
    <Modal
      visible={isModelOpen}
      title={actionName}
      onOk={() => onConfirm(actionName)}
      onCancel={() => setIsModelOpen(false)}
    >
      {
        refuseList ?
          <Refuse
            refuseList={refuseList}
            setResult={(value) => { setRefuseData(value) }}
            setRefuseList={(data) => { setRefuseList(data) }}
          /> :
          <View style={{ marginTop: 0 }}>
            <p>原因:</p>
            <TextArea
              rows={4}
              value={content}
              onChange={(e) => { setContent(e.target.value) }} />
          </View>
      }
    </Modal>

  </Fragment>
}

export default List