import { Button, Col, DatePicker, Input, message, Modal, Radio, Row, Select, Table, Tabs } from "antd"
import { Block, InputBox } from "components"
import React, { FC, useCallback, useEffect, useState } from "react"
import services, { daily } from "services"
import Box from "./components/Box"
import { BoxList, PageText, Space, TextCol, TitleCol } from "./style"
import { Axis, Chart, Coordinate, Interval, Legend, Tooltip } from 'bizcharts'
import moment from "moment"
import { exportExcel } from 'xlsx-oc'

/*
 * @Author: your name
 * @Date: 2021-04-19 16:30:56
 * @LastEditTime: 2021-04-30 14:51:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Home/index.tsx
 */

const errorColumn = [{
  key: 'name',
  dataIndex: 'name',
  title: '选项'
}, {
  key: 'count',
  dataIndex: 'count',
  title: '小计'
}, {
  key: 'ratio',
  dataIndex: 'ratio',
  title: '比例'
}]

const supplierColumn = [{
  key: 'name',
  dataIndex: 'name',
  title: '供应商名称'
}, {
  key: 'total',
  dataIndex: 'total',
  title: '订单总数'
}, {
  key: 'ongoing',
  dataIndex: 'ongoing',
  title: '进行中&待审核'
}, {
  key: 'received',
  dataIndex: 'received',
  title: '收货'
}, {
  key: 'refused',
  dataIndex: 'refused',
  title: '拒收'
}, {
  key: 'didnt_show',
  dataIndex: 'didnt_show',
  title: '爽约'
}]

const Statistics: FC = () => {
  const [resourceList, setResourceList] = useState<{ id: string, name: string }[]>()
  const [selectResource, setSelectResource] = useState<string>("all")
  const [timeMode, setTimeMode] = useState<string>("month")
  const [time, setTime] = useState<moment.Moment>(moment)
  const [data1, setData1] = useState<any>()
  const [data2, setData2] = useState<any>()

  const [mode, setMode] = useState<string>("供应商表单")
  const [modeList, setModeList] = useState<string[]>()

  const [isShow, setIsShow] = useState<boolean>(false)
  const [init, setInit] = useState<boolean>(true)

  const [supplierMode, setSupplierMode] = useState<string>("week")
  const [supplierTime, setSupplierTime] = useState<[moment.Moment, moment.Moment]>([
    moment().date(1),
    moment()
  ])
  const [search, setSearch] = useState<string>()

  const [exportIsOpen, setExportIsOpen] = useState<boolean>(false)
  const [selectExportResource, setSelectExportResource] = useState<string>("all")
  const [exportSupplierTime, setExportSupplierTime] = useState<[moment.Moment, moment.Moment]>([
    moment().date(1),
    moment()
  ])
  const [timeExportMode, setTimeExportMode] = useState<string>("month")

  //请求预约对象列表
  useEffect(() => {
    (async () => {
      try {
        const data = await daily.getResourceList()
        setResourceList(data)
      } catch (err) {
        err.response && message.error(err.response.data.message)
      }
    })()
  }, [])

  useEffect(() => {
    time && (async () => {
      let month = ""
      switch (timeMode) {
        case "month":
          month = (time!.get('month') + 1).toString()
          break
        case "quarter":
          const quarter = time.quarter()
          month = [1 + (quarter - 1) * 3, 2 + (quarter - 1) * 3, 3 + (quarter - 1) * 3].join()
          break
        case "year":
          month = "1,2,3,4,5,6,7,8,9,10,11,12"
          break
      }
      const data = await services.statistics.getData1({
        year: time.year(),
        month,
        object: selectResource.toString()
      })
      setData1(data)
      setModeList(data.reasons.map(() => "表格列表"))
    })()
  }, [selectResource, time, timeMode])

  useEffect(() => {
    supplierTime && (async () => {
      const data = await services.statistics.getData2({
        search,
        range: supplierTime.map(item => item.format("YYYYMMDD")).join(""),
        filter: supplierMode,
        object: selectResource.toString()
      })
      setData2(data)
    })()
  }, [supplierTime, search, supplierMode, selectResource])

  const handleSupplierDate = useCallback((date: moment.Moment) => {
    switch (timeMode) {
      case "month":
        if ((time?.year() === date.year()) && (time.month() === date.month())) {
          return false
        }
        return true
      case "quarter":
        if ((time?.year() === date.year()) && (time.quarter() === date.quarter())) {
          return false
        }
        return true
      case "year":
        return time?.year() !== date.year()
      default:
        return true
    }
  }, [timeMode, time])

  useEffect(() => {
    if (init) {
      setInit(false)
      return
    }
    let month = null
    switch (timeMode) {
      case "month":
        month = [(time!.get('month') + 1)]
        break
      case "quarter":
        const quarter = time.quarter()
        month = [1 + (quarter - 1) * 3, 2 + (quarter - 1) * 3, 3 + (quarter - 1) * 3]
        break
      case "year":
        month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        break
    }
    const year = time.year()
    setSupplierTime([
      moment().year(year).month(month![0] - 1).date(1),
      moment().year(year).month(month![month!.length - 1]).date(0)
    ])
  }, [timeMode, time])

  const handleClickRule = useCallback(async () => {
    if (!exportSupplierTime || !exportSupplierTime.length) {
      message.error("请选择日期")
      return
    }
    const data = await services.statistics.exportData({
      filter: timeExportMode,
      range: exportSupplierTime.map(item => item.format("YYYYMMDD")).join(""),
      object: selectExportResource
    })
    exportDefaultExcel(data.data)
  }, [timeExportMode, selectExportResource])

  const exportDefaultExcel = useCallback((excel) => {
    console.log(excel)
    let header = [
      { k: 'name', v: '供应商名称' },
      { k: 'year', v: '年份' }
    ]
    if (timeExportMode === "quarter") {
      header.push({ k: 'quarter', v: '季度' })
    }
    if (timeExportMode === "month") {
      header.push({ k: 'month', v: '月份' })
    }
    header = header.concat([
      { k: 'total', v: '全部订单数量' },
      { k: 'ongoing', v: '进行中&待审核' },
      { k: 'received', v: '收费' },
      { k: 'refused', v: '拒收' },
      { k: 'didnt_show', v: '爽约' }
    ])
    const fileName = "数据统计.xlsx";
    exportExcel(header, excel, fileName);
  }, [timeExportMode])

  return (
    <div>
      <Block
        title={
          <Select
            value={selectResource}
            onChange={setSelectResource}
            style={{ width: 200 }}
          >
            <Select.Option value="all">全部仓库</Select.Option>
            {resourceList?.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
          </Select>
        }
        description={
          <Row>
            <Col>
              <Button
                style={{ color: timeMode === "month" ? "" : "#333" }}
                type="link"
                onClick={() => { setTimeMode("month") }}
              >
                月份
              </Button>
            </Col>
            <Col>
              <Button
                style={{ color: timeMode === "quarter" ? "" : "#333" }}
                type="link"
                onClick={() => { setTimeMode("quarter") }}
              >
                季度
              </Button>
            </Col>
            <Col>
              <Button
                style={{ color: timeMode === "year" ? "" : "#333" }}
                type="link"
                onClick={() => { setTimeMode("year") }}
              >
                年度
              </Button>
            </Col>
            <Col>
              <DatePicker
                value={time}
                picker={timeMode as any}
                onChange={(value) => { setTime(value!) }}
              />
            </Col>
          </Row>
        }
        style={{ padding: 24 }}
      >
        <BoxList>
          <Box
            text1="总订单量"
            number1={data1?.total}
            text2="待审核进行中单量"
            number2={data1?.ongoing}
            description="总订单量"
          />
          <Space />
          <Box
            text1="收货单量"
            number1={data1?.received}
            text2="占已签到订单比例"
            number2={data1 ? `${data1?.received_ratio}%` : ""}
            description="收货单量"
          />
          <Space />
          <Box
            text1="拒收单量"
            number1={data1?.refused}
            text2="占已签到订单比例"
            number2={data1 ? `${data1?.refused_ratio}%` : ""}
            description="拒收单量"
          />
          <Space />
          <Box
            text1="爽约单量"
            number1={data1?.didnt_show}
            text2="占已签到订单比例"
            number2={data1 ? `${data1?.didnt_show_ratio}%` : ""}
            description="爽约单量"
          />
        </BoxList>
      </Block>
      <Block
        title={
          <Tabs
            activeKey={mode}
            size="large" style={{ marginTop: 7 }}
            onChange={setMode}
          >
            <Tabs.TabPane tab="供应商表单" key="供应商表单" />
            <Tabs.TabPane tab="拒收原因统计" key="拒收原因统计" />
          </Tabs>
        }
        description={
          <Row>
            <Col>
              <Input.Search
                onSearch={(value) => { setSearch(value) }}
                style={{ width: 200, marginTop: 12 }}
                placeholder="请输入供应商名称"
              />
            </Col>
            <Col>
              <Button onClick={() => { setExportIsOpen(true) }} style={{ marginLeft: 8 }} type="primary">导出数据</Button>
            </Col>
          </Row>
        }
        style={{ paddingBottom: 33 }}
      >
        {
          mode === "供应商表单" ?
            <div>
              <Row style={{ marginTop: 16 }}>
                <Col>
                  <Button
                    style={{ color: supplierMode === "week" ? "" : "#333", paddingLeft: 0 }}
                    type="link"
                    onClick={() => { setSupplierMode("week") }}
                  >
                    按周
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ color: supplierMode === "month" ? "" : "#333" }}
                    type="link"
                    onClick={() => { setSupplierMode("month") }}
                  >
                    按月
                  </Button>
                </Col>
                <Col>
                  <DatePicker.RangePicker
                    value={supplierTime}
                    style={{ width: 256 }}
                    disabledDate={(date) => handleSupplierDate(date)}
                    onChange={(value) => { setSupplierTime(value as any) }}
                  />
                </Col>
              </Row>

              <div style={{ position: "relative", height: 336 }}>
                <div style={{ paddingRight: 430 }}>
                  <PageText>订单量趋势</PageText>
                  <Chart
                    height={280}
                    autoFit
                    data={data2?.data}
                    scale={{ amount: { range: [0, 0.9] } }}
                  >
                    <Tooltip visible={false} />
                    <Interval
                      position="name*amount"
                      color="#30A694"
                      label={["amount",
                        { style: { fill: '#999' } }
                      ]}
                      style={{
                        height: "20px"
                      }}
                    />
                  </Chart>
                </div>
                {data2 ?
                  <div style={{ position: "absolute", right: 0, top: 0 }}>
                    <PageText>订单收货状况</PageText>
                    <Chart
                      height={300}
                      width={400}
                      data={
                        [{
                          name: "收货",
                          percent: data2?.received,
                          ratioValue: `${data2?.received_ratio}%`,
                          count: data2?.received
                        }, {
                          name: "拒收",
                          percent: data2?.refused,
                          ratioValue: `${data2?.refused_ratio}%`,
                          count: data2?.refused
                        }, {
                          name: "爽约",
                          percent: data2?.didnt_show,
                          ratioValue: `${data2?.didnt_show_ratio}%`,
                          count: data2?.didnt_show
                        }]
                      }
                      autoFit>
                      <Coordinate type="theta" radius={0.75} />
                      <Interval
                        label={["ratioValue",
                          {
                            offset: 20,
                            autoRotate: false
                          }
                        ]}
                        adjust="stack"
                        position="percent"
                        color="name"
                      />
                      <Legend position="right" />
                      <Axis visible={false} />
                      <Tooltip showTitle={false} />
                    </Chart>
                  </div>
                  : null
                }
              </div>
              <PageText>表格名称</PageText>
              <Table
                bordered
                pagination={false}
                columns={supplierColumn}
                dataSource={data2?.suppliers}
              />
            </div>
            : null
        }
        {
          mode === "拒收原因统计" && modeList?.length ?
            <div>
              {data1?.reasons.map((item: any, index: number) => (
                <div>
                  <PageText>{item.name}</PageText>
                  <Row align="middle">
                    <Col>
                      <Radio.Group value={modeList![index]} onChange={(e) => {
                        const newData = JSON.parse(JSON.stringify(modeList))
                        newData[index] = e.target.value
                        setModeList(newData)
                      }}>
                        <Radio.Button value="表格列表">表格列表</Radio.Button>
                        {item.type === "single" ? <Radio.Button value="饼状图">饼状图</Radio.Button> : null}
                        <Radio.Button value="柱状图">柱状图</Radio.Button>
                      </Radio.Group>
                    </Col>
                    <Col >
                      <div
                        style={{
                          marginLeft: 24,
                          textDecoration: "underline",
                          color: "#30A694",
                          fontSize: 14,
                          cursor: "pointer"
                        }}
                        onClick={() => setIsShow(true)}
                      >
                        查看百分比统计计算方法
                       </div>
                    </Col>
                  </Row>
                  {
                    modeList![index] === "表格列表" ?
                      <Table
                        bordered
                        columns={errorColumn}
                        dataSource={item.values.map((item: any) => ({ ...item, ratio: `${item.ratio}%` }))}
                        style={{ marginTop: 17 }}
                        pagination={false}
                      />
                      : null
                  }
                  {
                    modeList![index] === "柱状图" ?
                      <div style={{ padding: "30px 0" }}>
                        <Chart
                          height={320}
                          autoFit
                          data={item.values}
                          scale={{ count: { range: [0, 0.9] } }}
                        >
                          <Tooltip visible={false} />
                          <Interval
                            position="name*count"
                            color="#30A694"
                            size={40}
                            label={["count",
                              { style: { fill: '#999' } }
                            ]}
                            style={{
                              height: "20px"
                            }}
                          />
                        </Chart>
                      </div>
                      : null
                  }
                  {
                    modeList![index] === "饼状图" ?
                      <div style={{ padding: "30px 0", display: "flex" }}>
                        <Chart
                          height={300}
                          width={400}
                          data={
                            item.values.map((item: any) => (
                              { ...item, percent: item.ratio / 100, ratioValue: `${item.ratio}%` }
                            ))
                          }
                          autoFit>
                          <Coordinate type="theta" radius={0.75} />
                          <Interval
                            label={["ratioValue",
                              {
                                offset: 20,
                                autoRotate: false
                              }
                            ]}
                            adjust="stack"
                            position="percent"
                            color="name"
                          />
                          <Legend position="right" />
                          <Axis visible={false} />
                          <Tooltip showTitle={false} />
                        </Chart>
                      </div>
                      : null
                  }
                </div>
              ))}
            </div>
            : null
        }
      </Block>
      <Modal
        visible={isShow}
        title="百分比统计计算方法"
        footer={null}
        onCancel={() => { setIsShow(false) }}
      >
        <TitleCol style={{ marginTop: 8 }}>单选题百分比计算：</TitleCol>
        <TextCol>1. 单选题选项百分比 = 该选项被选择次数/拒收订单次数</TextCol>
        <TextCol>2. 含义：该选项被选择的次数占<b>所有选项被选择次数</b>的比例（所有选项的比例加起来等于百分之百)</TextCol>
        <TitleCol>多选题百分比计算：</TitleCol>
        <TextCol>1. 多选题选项百分比 = 该选项被选择次数/拒收订单次数</TextCol>
        <TextCol>2. 含义：该选项被选择的次数占所有拒收订单数量的比例（所有选项的比例加起来可能超过100%）</TextCol>
        <TextCol></TextCol>
        <TextCol>例：该多选题有A，B，C三个问题。管理员拒绝收货订单一次，并同时填选了A，B，C。则A，B，C的比例都是100%</TextCol>
        <TextCol></TextCol>
      </Modal>
      <Modal
        title="导出报表"
        visible={exportIsOpen}
        onCancel={() => setExportIsOpen(false)}
        footer={
          <div>
            <Button type="primary" onClick={handleClickRule}>导出</Button>
            <Button onClick={() => { setExportIsOpen(false) }}>
              取消
            </Button>
          </div>
        }
      >
        <>
          <InputBox title="请选择导出数据纬度">
            <Select
              value={timeExportMode}
              onChange={setTimeExportMode}
              style={{ width: 200 }}
            >
              <Select.Option value="month">按月</Select.Option>
              <Select.Option value="quarter">按季度</Select.Option>
              <Select.Option value="year">按年</Select.Option>
            </Select>
          </InputBox>
          <InputBox title="选择日期" style={{ marginTop: 16 }}>
            <DatePicker.RangePicker
              value={exportSupplierTime}
              style={{ width: 256 }}
              onChange={(value) => { setExportSupplierTime(value as any) }}
            />
          </InputBox>
          <InputBox title="选择项目" style={{ marginTop: 16 }}>
            <Select
              value={selectExportResource}
              onChange={setSelectExportResource}
              style={{ width: 200 }}
            >
              <Select.Option value="all">全部仓库</Select.Option>
              {resourceList?.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
            </Select>
          </InputBox>
        </>
      </Modal>
    </div >
  )
}

export default Statistics