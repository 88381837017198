/*
 * @Author: your name
 * @Date: 2020-12-08 17:29:45
 * @LastEditTime: 2020-12-08 17:30:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/manual.ts
 */
export const rereadCol = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: '16px'
}