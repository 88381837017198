/*
 * @Author: your name
 * @Date: 2021-04-20 17:40:41
 * @LastEditTime: 2021-04-28 15:56:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/statistics.ts
 */
import { get, post } from '../utils/http/http'

const baseURL = '/Scene/'

export default {
  //数据1
  getData1: (payload: any) => get<any>(`${baseURL}statistics`, payload),
  //数据2
  getData2: (payload: any) => get<any>(`${baseURL}sup_stat`, payload),
  //导出
  exportData: (payload: any) => get<any>(`${baseURL}stat_export`, payload),
}