import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Select, Space, Spin, Table } from 'antd'
import { Block } from 'components'
import { columns } from 'consts/user'
import { Link } from 'react-router-dom'
import services from 'services'
import { Brief } from 'types/user'
import { Title } from './style'
import { SearchOutlined } from '@ant-design/icons'
import { formatTime } from 'utils/time'
import AddUser from '../Model/AddUser'
import ImportUser from '../Model/ImportUser'
import { debounce } from 'utils/common'
import { exportExcel } from 'xlsx-oc'

let debounced: (...args: any[]) => any

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [list, setList] = useState<any[]>([])
  const [keyword, setKeyword] = useState<string>('')
  const [fetching, setFetching] = useState<boolean>(false)
  const [suppliers, setSuppliers] = useState<any[]>([])
  const [groupId, setGroupId] = useState<number>()
  const [weight, setWeight] = useState<string>()
  const [addUserVisible, setAddUserVisible] = useState<boolean>(false)
  const [importUserVisible, setImportUserVisible] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(0)
  
  const handleData = useCallback((data: Brief[]) => {
    return data.map((item) => ({
      key: item.phone,
      name: item.name,
      phone: item.phone,
      order_time: item.order_time,
      supplier: item.supplier,
      group: item.group,
      operation: (
        <Fragment>
          <Link to={`/user/detail/${item.id}`}>查看详情</Link>
          {/* <Divider type="vertical" />
          <Link to={`/user/detail/${item.id}`}>添加预约</Link> */}
        </Fragment>
      )
    }))
  }, [])

  const loadList = useCallback(() => {
    services.user.getList({
      page,
      page_size: pageSize,
      q: keyword,
      weight: weight,
      supplier_id: groupId
    })
      .then((data) => {
        setList(handleData(data.data))
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取用户列表失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [keyword, groupId, weight, page, pageSize])

  useEffect(loadList, [page, pageSize])

  const fetchData = useCallback(async (value) => {
    if (!debounced) {
      debounced = debounce(services.user.getSupplierList, 800)
    }
    setFetching(true)
    try {
      const data = await debounced({ q: value })
      setSuppliers(data.suppliers)
    } finally {
      setFetching(false)
    }
  }, [])

  const resetInput = useCallback(() => {
    setKeyword('')
    setGroupId(undefined)
    setWeight(undefined)
  }, [])

  const handleExport = useCallback(async () => {
    try {
      const { data } = await services.user.getList({ page: 1, page_size: 1000000 })
      const header = [
        { k: 'id', v: '用户Id' },
        { k: 'name', v: '用户名' },
        { k: 'phone', v: '联系方式' },
        { k: 'email', v: '电子邮件地址' },
        { k: 'supplier', v: '供应商名称' },
        { k: 'supplier_weight', v: '供应商权重' },
        { k: 'group', v: '供应商策略分组' },
        { k: 'group_type', v: '策略分组类型' },
        { k: 'order_time', v: '最近一次下单时间' },
        { k: 'appointment_time', v: '最近一次预约时间' },
        { k: 'register_time', v: '注册时间' }
      ]
      exportExcel(header, data, `用户列表${formatTime(Date.now(), 'datetime')}.xlsx`)
    } catch (error) {
      message.error('导出用户列表时遇到不可预知的错误')
    }
  }, [])

  return (
    <Block loading={loading}>
      <Row gutter={[12, 20]} >
        <Col span={7}>
          <Form.Item label="搜索">
            <Input
              placeholder="请输入关键字"
              suffix={<SearchOutlined />}
              onChange={e => setKeyword(e.target.value)}
              value={keyword}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="所属供应商">
            <Select
              showSearch
              placeholder="请选择"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={fetchData}
              onChange={id => setGroupId(id)}
              style={{ width: '100%' }}
              value={groupId}
            >
              {suppliers.map(supplier => (
                <Select.Option key={supplier.id} value={supplier.id}>{supplier.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="供应商权重">
            <Input
              type="number"
              placeholder="请输入供应商权重"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space size="middle">
            <Button type="primary" onClick={() => loadList()}>查询</Button>
            <Button onClick={resetInput}>重置</Button>
          </Space>
        </Col>
      </Row>
      <Title>用户信息</Title>
      <Row style={{ margin: '14px 0px' }}>
        <Col span={20}>
          <Space size="middle">
            <Button type="primary" onClick={() => setImportUserVisible(true)}>导入用户列表</Button>
            <Button onClick={() => setAddUserVisible(true)}>添加用户</Button>
          </Space>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={handleExport}>导出Excel</Button>
        </Col>
      </Row>
      <Table
        dataSource={list}
        columns={columns}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _pageSize) => {
            if (_page !== page) setPage(_page)
            if (_pageSize !== pageSize) setPageSize(_pageSize!)
          },
        }}
      />
      <AddUser
        visible={addUserVisible}
        onCancel={() => setAddUserVisible(false)}
        onOk={loadList}
      />
      <ImportUser
        visible={importUserVisible}
        onCancel={() => setImportUserVisible(false)}
        onOk={loadList}
      />
    </Block >
  )
}

export default List
