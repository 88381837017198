import React, { FC, useCallback, useEffect } from 'react'
import { RichTextWrapper } from './style'

interface Props {
  value?: string,
  onChange?: (value?: string) => any,
  defaultValue?: string
}

const RichText: FC<Props> = (props) => {
  const ref = React.createRef<HTMLDivElement>()

  const handleInput = useCallback(() => {
    props.onChange && props.onChange(ref.current?.innerHTML)
  }, [ref, props])

  useEffect(() => {
    const richTextDOM = document.getElementById('rich-text')
    if (richTextDOM) {
      richTextDOM.innerHTML = props.defaultValue || ''
    }
  }, [props.defaultValue])

  return (
    <RichTextWrapper
      id="rich-text"
      ref={ref}
      contentEditable
      onInput={handleInput}
    ></RichTextWrapper>
  )
}

export default RichText
