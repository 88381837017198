import http from '../utils/http'
import { Notice } from 'types/notice'

const resource = ''

export default {
  // 创建公告
  create: (payload: Payload.Create) => http.post<any>('/Scene/notices', payload),
  // 删除公告
  delete: (id: number) => http.del<any>(`Scene/notices/${id}`),
  // 更新公告
  update: (payload: Payload.Update) => http.put<any>('/Scene/notices', payload),
  // 获取公告列表
  getList: (payload: PagePayload) => http.get<DataList<Notice>>('/Scene/notices', payload),
  // 获取供应商列表
  getPolicys: (payload: any) => http.get<any>('/Scene/notice/policys', payload)
}

declare namespace Payload {
  export interface Create {
    title: string,
    content: string,
    open: boolean,
    rule: string,
    cycle: number,
    policys: string[]
  }

  export interface Update extends Create {
    id: string
  }
}
