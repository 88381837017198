import React, { FC, useState, useCallback } from 'react'
import { Modal, Form, Input, Select, Button, message } from 'antd'
import services from 'services'
import XLSX, { exportExcel } from 'components/XLSX'
import { mapKeys } from 'utils/common'

interface Props {
  resource_id: number,
  visible: boolean,
  onAdd: () => any,
  onClose: () => any
}

const AddPolicy: FC<Props> = (props) => {
  const { visible, onAdd, onClose, resource_id } = props
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [supplierList, setSupplierList] = useState<AnyObject[]>([])

  const handleCancel = useCallback(() => {
    form.resetFields()
    onClose()
  }, [form, onClose])

  const handleOk = useCallback(async () => {
    form.validateFields()
      .then((data) => {
        setConfirmLoading(true)
        services.policy.add({
          resource_id,
          name: data.name,
          type: data.type,
          list: supplierList as { name: string }[]
        })
          .then(() => {
            message.success(`策略分组“${data.name}”添加成功`)
          })
          .catch(error => {
            const failList = error.response.data?.data?.fail_list
            if (failList) {
              const mapping = { name: '供应商名称', fail_reason: '错误原因' }
              exportExcel(mapKeys(failList, mapping), '错误报告-新建供应商分组时供应商导入')
              message.error('部分数据导入失败，错误报告已下载，请更改并重新上传')
            } else {
              message.error('导入失败~')
            }
          })
          .finally(() => {
            onAdd()
            onClose()
            setConfirmLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单')
      })
  }, [supplierList, form])

  const handleXLSXChange = useCallback((matrix: AnyObject[]) => {
    setSupplierList(matrix)
  }, [])

  return (
    <Modal
      title="添加策略分组"
      visible={visible}
      okText="确认"
      cancelText="重置"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        name="item"
        form={form}
        validateMessages={{ required: "'${label}' 不能为空" }}
        preserve={false}
      >
        <Form.Item
          label="分组名称"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="供应商策略类型"
          name="type"
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: 200 }}
            placeholder="请选择供应商策略类型"
          >
            <Select.Option value="固定型">固定型</Select.Option>
            <Select.Option value="优先型">优先型</Select.Option>
            <Select.Option value="VIP型">VIP型</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="导入供应商列表">
          <XLSX
            mapping={{ 'A': { name: 'name' } }}
            onChange={handleXLSXChange}
            hasHeader
          >
            <Button type="link" >点击上传</Button>
          </XLSX>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddPolicy
