import React, { FC, useCallback } from "react"
import { MiniBox, MaxBox, BoxContent, BoxSpace, Text, Moulding } from "./style"

interface ItemType {
  data: string,
  type: string,
  state: string,
  onClick: (data: string) => void
}

const Item: FC<ItemType> = (props) => {
  const { data, type, state, onClick } = props

  const switchBackgroundColor = useCallback((boxState: string) => {
    switch (boxState) {
      case "none": return ""
      case "general": return "#E3F3EE"
      case "special": return "#E6F7FF"
    }
  }, [])

  const switchMouldingColor = useCallback((boxState: string) => {
    switch (boxState) {
      case "general": return "#30A694"
      case "special": return "#1890FF"
      case "none":
        if (state === "general") {
          return "#30A694"
        } else if (state === "special") {
          return "#1890FF"
        }
    }
  }, [state, type])

  const switchTextColor = useCallback((boxState: string) => {
    switch (boxState) {
      case "none": return "#333"
      case "general": return "#30A694"
      case "special": return "#1890FF"
    }
  }, [])

  const renderContent = useCallback((boxState, time) => {
    if (state === "display" && boxState === "none") {
      return null
    }
    if (state !== "display" && boxState === "none") {
      return (
        <BoxContent style={{ background: switchBackgroundColor(boxState) }}>
          <Moulding style={{ background: switchMouldingColor(boxState) }} />
          <Text style={{ color: switchTextColor(boxState) }}>{time}</Text>
          <BoxSpace />
        </ BoxContent >
      )
    }
    return (
      <BoxContent style={{ background: switchBackgroundColor(boxState) }}>
        <Moulding style={{ background: switchMouldingColor(boxState) }} />
        <Text style={{ color: switchTextColor(boxState) }}>{time}</Text>
      </ BoxContent >
    )
  }, [state, type])

  const handleClick = useCallback((data, boxState) => {
    if ((state === "general" && boxState !== "special") || (state === "special" && boxState !== "general")) onClick(data)
  }, [state, onClick])

  const timeStr = data.split("_")[2]
  const boxState = `${data.split("_")[3]}`
  switch (type) {
    case "固定型":
      const time = `${timeStr[0]}${timeStr[1]}:${timeStr[2]}${timeStr[3]}-${timeStr[4]}${timeStr[5]}:${timeStr[6]}${timeStr[7]}`
      return (
        <MiniBox onClick={() => handleClick(data, boxState)}>
          {renderContent(boxState, time)}
        </MiniBox>
      )
    case "优先型":
    case "VIP型":
      return (
        <MaxBox onClick={() => handleClick(data, boxState)}>
          {renderContent(boxState, data.split("_")[2])}
        </MaxBox>
      )
    default:
      return null
  }
}

export default Item

