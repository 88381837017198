import { Button, Col, message, Modal, Row, Select, Spin, Typography } from 'antd'
import XLSX, { exportExcel } from 'components/XLSX'
import { OSS_EXCEL } from 'consts/url'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import services from 'services'
import { debounce, mapKeys } from 'utils/common'

let debounced: (...args: any[]) => any

interface Props {
  onFlush: () => void
}

const AddSupplier: FC<Props> = (props) => {
  const { id } = useParams<{ id: string }>()
  const [visible, setVisible] = useState<boolean>(false)
  const [visible2, setVisible2] = useState<boolean>(false)
  const [nameList, setNameList] = useState<string[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [suppliers, setSuppliers] = useState<any[]>([])

  const fetchData = useCallback(async (value) => {
    if (!debounced) {
      debounced = debounce(services.user.getSupplierList, 800)
    }
    setFetching(true)
    try {
      const data = await debounced({ q: value })
      setSuppliers(data.suppliers)
    } finally {
      setFetching(false)
    }
  }, [])

  const handleChange = useCallback((data: AnyObject[]) => {
    setNameList(data.map(item => item.name))
  }, [])

  const handleAddSupplier = useCallback(async () => {
    if (!nameList.length) return
    const list: { name: string }[] = nameList.map(name => ({ name }))
    services.policy.addSuppliers(id, { list })
      .then(() => {
        message.success('添加成功！')
      })
      .catch(error => {
        const failList = error.response.data?.data?.fail_list
        if (failList) {
          const mapping = { name: '供应商名称（必填）', fail_reason: '错误原因' }
          exportExcel(mapKeys(failList, mapping), '错误报告-供应商分组内供应商导入')
          message.error('部分数据导入失败，错误报告已下载，请更改并重新上传')
        } else {
          message.error('导入失败~')
        }
      })
      .finally(() => {
        props.onFlush()
        setVisible(false)
        setVisible2(false)
      })
  }, [nameList])

  return (
    <Row>
      <Col span={12}>
        <Button style={{ marginRight: 12 }} onClick={() => setVisible(true)}>添加供应商</Button>
      </Col>
      <Col span={12}>
        <Button type="primary" onClick={() => setVisible2(true)} >导入供应商</Button>
      </Col>
      <Modal
        title="供应商分组中供应商"
        visible={visible2}
        onCancel={() => setVisible2(false)}
        footer={null}
      >
        <XLSX
          mapping={{ 'A': { name: 'name' } }}
          onChange={handleChange}
          type="dragger"
          hasHeader
        />
        <Typography.Text style={{ color: '#929292', display: 'block', margin: '8px auto' }}>
          不知道格式？请查看
          <Typography.Link href={`${OSS_EXCEL}/supplier_in_group.xlsx`} target="_blank">
            供应商分组中供应商导入模板
          </Typography.Link>
        </Typography.Text>
        <Button
          type="primary"
          style={{ display: 'block', margin: '10px auto 0px auto' }}
          onClick={handleAddSupplier}
        >确定导入</Button>
      </Modal>
      <Modal
        visible={visible}
        okText="确认"
        cancelText="取消"
        onCancel={() => setVisible(false)}
        closable={false}
        onOk={handleAddSupplier}
      >
        <Select
          showSearch
          placeholder="请选择"
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={fetchData}
          onChange={name => setNameList([name as string])}
          style={{ width: '100%' }}
        >
          {suppliers.map(supplier => (
            <Select.Option key={supplier.id} value={supplier.name}>{supplier.name}</Select.Option>
          ))}
        </Select>
      </Modal>
    </Row>
  )
}

export default AddSupplier
