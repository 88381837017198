/*
 * @Author: your name
 * @Date: 2021-04-12 14:20:28
 * @LastEditTime: 2021-04-13 16:22:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Reject/View/List.tsx
 */
import { Button, message, Popconfirm, Table, Tag, Tooltip } from "antd"
import { Block, Header } from "components"
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { item, system } from "services"

const columns = [{
  key: 'name',
  dataIndex: 'name',
  title: '拒收表单名称',
  className: 'drag-visible'
}, {
  key: 'is_disable',
  dataIndex: 'is_disable',
  title: '在订单中显示'
}, {
  key: 'is_add',
  dataIndex: 'is_add',
  title: '是否允许添加多组'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]

const CustomOrderList: FC = () => {
  const [data, setData] = useState<any[]>()
  const [render, setRender] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const data = await system.getCustomOrderList()
        setData(data)
      } catch (error) {
        message.error("获取失败")
      } finally {
        setLoading(false)
      }
    })()
  }, [render])

  const handleClickDel = useCallback(async (id: number) => {
    setLoading(true)
    try {
      await system.delCustomOrder({ id })
      setRender(render + 1)
      message.success("删除成功")
    } catch (err) {
      message.error("删除失败")
    } finally {
      setLoading(false)
    }
  }, [render])

  const handleData = useCallback((data?: any[]) => {
    if (!data || !data.length) return []
    return data.map(item => {
      let tempList: string[] = []
      let resourceColumn: ReactNode = null
      if (tempList.length === 1) {
        resourceColumn = tempList[0]
      }
      if (tempList.length > 1) {
        resourceColumn = <Tooltip title={tempList.map(item => <div>{item}</div>)}>{tempList[0]}...</Tooltip>
      }
      return ({
        ...item,
        is_disable: item.is_disable ? <Tag color="default">不显示</Tag> : <Tag color="success">显示</Tag>,
        is_add: item.is_add ? <Tag color="success">允许</Tag> : <Tag color="default">不允许</Tag>,
        resource_list: resourceColumn,
        handle: <div style={{ marginLeft: -15 }}>
          <Link to={`/reject/detail/${item.id}`}>
            <Button type="link">编辑</Button>
          </Link>
          <Popconfirm title="确定删除" onConfirm={() => handleClickDel(item.id)}>
            <Button type="link">删除</Button>
          </Popconfirm>
        </div>
      })
    })
  }, [render])

  return (
    <div>
      <Block loading={loading}>
        <Link to="/reject/detail/0">
          <Button type="primary" style={{ margin: "24px 0" }}>添加拒收原因</Button>
        </Link>
        <Table
          columns={columns}
          dataSource={handleData(data)}
          pagination={false}
        />
      </Block>
    </div>
  )
}

export default CustomOrderList