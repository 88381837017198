import styled from 'styled-components'
import { Input, Select } from 'antd'

export const Box = styled.div`
  padding: 15px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #EBEDF0;
  margin-bottom: 10px;
  background-color: #FFFFFF;
`

export const Title = styled.h4`
  font-size: 16px;
  color: #323232;
  line-height: 20px;
  padding-bottom: 10px;
`

export const Search = styled(Input.Search)`
  width: 90%;
  margin: 10px 5% 0px 5%;
  border-radius: 12px;
`

export const StatusSelect = styled(Select)`
  width: 80%;
  display: block;
  margin: -5px auto 10px auto;
`

export const SheetWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(0,0,0,0.5);
  z-index: 1001;
`

export const Sheet = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
`

export const SheetHeader = styled.div`
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  color: #ababab;
  line-height: 40px;
  border-bottom: 1px solid #f5f5f5;
`

export const CancelButton = styled.div`
  line-height: 50px;
  background-color: #fff;
  text-align: center;
  border-top: 6px solid #e7e7e7;
`

export const FormEntry = styled.div`
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 8px;
  border-left: 4px solid #30A694;
  background-color: #fff;
`

export const PlusButton = styled.div`
  color: #30A694;
  line-height: 44px;
  text-align: center;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  background-color: #fff;
`

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 42px;
  width: 100%;
  text-align: center;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  background-color: #fff;
  z-index: 1000;
`

export const Paper = styled.div`
  border-bottom: 10px solid #F7F8FA;
`

export const Label = styled.label`
  font-size: 14px;
`

export const Must = styled.span`
  color: red;
`

export const FormEntry2 = styled.div`
  line-height: 20px;
  font-size: 14px;
  padding: 0px 4px;
  margin: 8px 0px;
  border-left: 2px solid #30A694;
  background-color: #fff;
`
