/*
 * File: /src/pages/System/View/Decoration/Emulator/Shortcut.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 12:53:45
 * Last Modified: Wednesday 2020-12-09 12:53:45
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC } from 'react'
import { Row, Col, Typography } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'

const IconFont = createFromIconfontCN({
  scriptUrl: 'https://at.alicdn.com/t/font_1739290_eq85v2w7hko.js'
})

interface MenuProps {
  icon: string
}

const Menu: FC<MenuProps> = (props) => {
  return (
    <Col>
      <div
        style={{
          borderRadius: '50%',
          backgroundColor: '#FC7D61',
          width: 35,
          height: 35,
          color: '#FFF',
          textAlign: 'center',
          fontSize: 22,
          margin: 'auto'
        }}>
        <IconFont type={props.icon}/>
      </div>
      <Typography.Text style={{ fontSize: 12 }}>预约须知</Typography.Text>
    </Col>
  )
}

const Shortcut: FC = () => {
  return (
    <Row align="middle" justify="space-around" style={{ padding: '12px 0px' }}>
      <Menu icon="iconlijiyuyue" />
      <Menu icon="iconyuyuexuzhi" />
      <Menu icon="iconlianxifangshi" />
    </Row>
  )
}

export default Shortcut
