import React, { FC, useState, useEffect } from 'react'
import { Block } from 'components'
import { policy, item } from 'services'
import { List } from './style'
import Card from 'components/Card'
import Add from '../Model/Add'
import Dialog from '../Model/Add/dialog'
import { CardList } from 'services/time'
import { Link } from 'react-router-dom'

const Sort: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [addList, setAddList] = useState<{
    id: number;
    name: string;
  }[]>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [render, setRender] = useState<number>(0)
  const [cardList, setCardList] = useState<CardList[]>()

  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await item.getSummaryList()
      setLoading(false)
      setAddList(data)
      setRender(2)
    })()
  }, [])

  useEffect(() => {
    addList && (async () => {
      setLoading(true)
      const data = await policy.getSort()
      setLoading(false)
      setCardList(data)
    })()
  }, [render])

  return (
    <React.Fragment>
      <Block
        title="各园区供应商策略分组"
        loading={loading}
      />
      <List>
        <Add onClick={() => setIsOpen(true)} />
        {cardList && cardList.length ? cardList.map(item =>
          <Link to={`/policy/list/${item.id}`}>
            <Card
              key={item.id}
              name={item.name}
              description={item.description}
              img_url={item.img_url}
            />
          </Link>
        ) : null
        }
      </List>
      <Dialog
        isOpen={isOpen}
        list={addList}
        onSubmit={() => {
          setIsOpen(false)
          setRender(render + 1)
        }}
        onClose={() => setIsOpen(false)}
      />
    </React.Fragment>
  )
}

export default Sort