/*
 * @Author: your name
 * @Date: 2021-04-19 17:53:46
 * @LastEditTime: 2021-04-19 18:39:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Statistics/components/Box/style.ts
 */
import styled from "styled-components"

export const Body = styled.div`
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  height: 217px;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 20px 24px; 
`

export const Top = styled.div`
  height: 87px;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Bottom = styled.div`
  margin-top: 24px;
`

export const Title = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  height: 22px;
`
export const Value = styled.div`
  margin-top: 4px;
  height: 38px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 38px;
`

