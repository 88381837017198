/*
 * @Author: your name
 * @Date: 2020-09-02 15:12:12
 * @LastEditTime: 2020-12-08 15:39:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/time.ts
 */
import { get, post } from '../utils/http/http'
import { RereadRuleType } from 'types/time'

const baseURL = '/Admin/Time/'

export default {
  //预约对象-列表
  getRoomList: () => get<RoomList[]>(`${baseURL}room_list`),
  //时间表对象-新增
  addNewCard: (payload: { resource_id: string }) => post<{ resource_id: number }>(`${baseURL}add`, payload),
  //时间表对象-删除
  deleteCard: (payload: { resource_id: string }) => post<{ resource_id: number }>(`${baseURL}delete`, payload),
  //时间表对象-列表
  getCardList: (payload?: { q: string }) => post<CardList[]>(`${baseURL}card_list`, payload),
  //时间格子-查看列表
  getTimeList: (payload: TimeListPayload) => post<TimeListType[]>(`${baseURL}info`, payload),
  //时间格子-新增
  newTimeItem: (payload: TimeItemType) => post<any>(`${baseURL}info_edit`, payload),
  //时间格子-更新
  editTimeItem: (payload: TimeItemType) => post<any>(`${baseURL}edit_time`, payload),
  //时间格子-删除
  removeTimeItem: (payload: RemoveTimeItemType) => post<any>(`${baseURL}info_delete`, payload),
  //时间格子-获取生效日期
  getEffectTime: (payload: EffectTimeResult) => get<{ show_start_date: string }>(`${baseURL}last_reserve`, payload),
  //时间格子-查看详情
  getTimeDetail: (payload: { time_id: number }) => post<TimeItemType>(`${baseURL}info_data`, payload),
  //一键同步
  submitSync: (payload: submitSyncPayload) => post<any>(`${baseURL}info_copy`, payload),
  //快速生成
  submitQuick: (payload: SubmitQuickPayload) => post<any>(`${baseURL}info_auto`, payload),
  //时间格子-验证重叠
  VACTipTime: (payload: TimeItemType) => post<VACTipTimeType>(`${baseURL}is_tip`, payload),
  //时间格子-验证重叠
  VACTipTimeQuick: (payload: SubmitQuickPayload) => post<VACTipTimeType>(`${baseURL}auto_is_tip`, payload),
}
interface VACTipTimeType {
  is_overlap: boolean;
  is_overlapping: boolean;
}

interface EffectTimeResult {
  time_id: number,
  date: string,
}

export interface SubmitQuickPayload {
  start_time: string,
  end_time: string,
  time: number,
  resource_id: number,
  space_time: number,
  load: number,
  weight: number,
  reread_rule: RereadRuleType,
  custom_rule: any,
}

interface submitSyncPayload {
  resource_id: number,
  check_resource_id: number,
}

interface TimeListPayload {
  date: string,
  resource_id: number,
}

export interface TimeListType {
  id: number,
  start_time: number,
  end_time: number,
  content: { [key: string]: string },
  disable: boolean,
  is_edit: boolean,
}


export interface RoomList {
  id: number,
  name: string,
}

export interface CardList {
  id: number,
  name: string,
  img_url: string,
  description: string,
}

export interface TimeItemType {
  resource_id: number,
  time_id?: number,
  tag?: string,
  date: string,
  load?: number,
  weight: number,
  start_time: string,
  end_time: string,
  disable: boolean,
  reread_rule?: RereadRuleType,
  custom_rule?: any,
  month_number: number,
  is_alone?: boolean,
}

interface RemoveTimeItemType {
  time_id: number,
  is_repeat_del: boolean,
  date: string,
}