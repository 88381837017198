import React, { FC } from "react"
import { DatePicker, Input, InputNumber, Radio, Select } from "antd"
import { InputBox } from "components"
import { CustomRuleType, PeriodSpaceType, PeriodType, RereadRuleType } from "types/time"
import { PanelLeftRowStyle, PanelRowStyle, periodList, rereadRuleList } from "consts/time"
import SelectWeek from "components/SelectWeek"
import moment from "moment"
import { rereadCol } from "consts/manual"

interface RereadType {
  disabled?: boolean
  reread_rule: string
  custom_rule: CustomRuleType
  onchangeRereadRule: (reread_rule: string) => void
  onchangeCustomRule: (custom_rule: CustomRuleType) => void
}
const Reread: FC<RereadType> = (props) => {
  const { reread_rule, custom_rule, onchangeRereadRule, onchangeCustomRule, disabled } = props
  const { Option } = Select

  return (
    <div >
      <InputBox style={rereadCol} title='重复规则'>
        <Select
          disabled={disabled}
          value={reread_rule}
          onChange={(value) => { onchangeRereadRule(value) }}
          placeholder='请选择重复规则'
          style={{ width: 235 }}
        >
          <Option value="">不重复</Option>
          <Option value="day">每日</Option>
          <Option value="work">工作日</Option>
          <Option value="rest">休息日</Option>
          <Option value="week">周</Option>
          <Option value="month">月</Option>
          <Option value="custom">自定义规则</Option>
        </Select>
      </InputBox>
      {
        reread_rule === RereadRuleType.custom ?
          <React.Fragment>
            <InputBox
              style={rereadCol}
              title='重复周期'
            >
              &nbsp;每&nbsp;
              <InputNumber
                disabled={disabled}
                value={custom_rule.period_value}
                onChange={(value) => { onchangeCustomRule({ ...custom_rule, period_value: Number(value) }) }}
                style={{ width: '60px' }}
                min={0}
              />
              <Select
                disabled={disabled}
                value={custom_rule.period_space}
                onChange={(value) => { onchangeCustomRule({ ...custom_rule, period_space: value }) }}
                style={{ width: '60px', marginLeft: '8px' }}
              >
                {periodList.map(item => <Option key={item.value} value={item.value}>{item.name}</Option>)}
              </Select>
              &nbsp;执行一次&nbsp;
            </InputBox>
            {custom_rule.period_space === PeriodSpaceType.week ?
              <InputBox
                style={rereadCol}
                title='按日重复'
              >
                <SelectWeek
                  disabled={disabled}
                  value={custom_rule.day}
                  onChange={(value) => { onchangeCustomRule({ ...custom_rule, day: value }) }}
                />
              </InputBox>
              : null
            }
          </React.Fragment>
          : null
      }
      {
        reread_rule !== "" ?
          <InputBox style={{ ...rereadCol, alignItems: "flex-start" }} title='重复次数'>
            <Radio.Group
              value={custom_rule.period_type}
              disabled={disabled}
              onChange={(e) => {
                onchangeCustomRule(
                  {
                    ...custom_rule,
                    period_type: e.target.value,
                    period_data: ""
                  }
                )
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 110
              }}
            >
              <Radio value={PeriodType.always}>一直</Radio>
              <Radio value={PeriodType.date}>
                从
                    <DatePicker
                  disabled={((custom_rule.period_type !== PeriodType.date) || disabled)}
                  style={{ width: 180, marginLeft: 4, marginRight: 4 }}
                  value={
                    custom_rule.period_type === PeriodType.date &&
                      custom_rule.period_data.length === 10 ? moment(custom_rule.period_data) : null
                  }
                  onChange={(_value, string) => {
                    onchangeCustomRule({ ...custom_rule, period_data: string })
                  }}
                />
                    开始
                  </Radio>
              <Radio value={PeriodType.number}>
                执行
                    <InputNumber
                  disabled={((custom_rule.period_type !== PeriodType.number) || disabled)}
                  style={{ width: 80, marginLeft: 4, marginRight: 4 }}
                  value={custom_rule.period_type === PeriodType.number ? Number(custom_rule.period_data) : 0}
                  min={0}
                  onChange={(value) => { onchangeCustomRule({ ...custom_rule, period_data: value }) }}
                />
                    次
                  </Radio>
            </Radio.Group>
          </InputBox>
          : null
      }
    </div >
  )
}

export default Reread