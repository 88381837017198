import React, { FC, useState, Fragment, useCallback, useEffect } from 'react'
import { Table, Button, message, Typography, Divider } from 'antd'
import services from 'services'
import { Block } from 'components'
import AddPolicy from '../Model/AddPolicy'
import { Link, useParams } from 'react-router-dom'
import { columns } from 'consts/policy'
import { Policy } from 'types/policy'

const { Paragraph } = Typography

const List: FC = () => {
  const id = Number(useParams<{ id: string }>().id)
  const [loading, setLoading] = useState<boolean>(true)
  const [list, setList] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(0)
  const [addPolicyVisible, setAddPolicyVisible] = useState<boolean>(false)

  const handleData = useCallback((data: Policy[]) => {
    return data.map((item, index) => {
      return {
        key: index,
        name: <Paragraph ellipsis={{ rows: 2 }}>{item.name}</Paragraph>,
        type: <Paragraph ellipsis={{ rows: 2 }}>{item.type}</Paragraph>,
        cycle: <Paragraph ellipsis={{ rows: 2 }}>{item.cycle}</Paragraph>,
        operation:
          <Fragment>
            {item.type === '普通型' ? null
              : (<Fragment>
                <Link to={`/policy/calendar/${item.id}/${item.name}/${item.type}`}>送货日历</Link>
                <Divider type="vertical" />
              </Fragment>)
            }
            <Link to={`/policy/detail/${item.id}`}>分组详情</Link>
          </Fragment>
      }
    })
  }, [])

  const loadList = useCallback(() => {
    services.policy.getList(id, { page, size: pageSize })
      .then((data) => {
        setList(handleData(data.data))
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取策略分组列表失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, pageSize, id])

  useEffect(loadList, [page])

  return (
    <Block
      loading={loading}
      style={{
        paddingTop: '32px'
      }}>
      <Button
        type="primary"
        onClick={() => setAddPolicyVisible(true)}
      >
        添加策略分组
      </Button>
      <Table
        dataSource={list}
        columns={columns}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _pageSize) => {
            if (_page !== page) setPage(_page)
            if (_pageSize !== pageSize) setPageSize(_pageSize!)
          },
        }}
        style={{
          marginTop: 24
        }}
      />
      <AddPolicy
        resource_id={id}
        visible={addPolicyVisible}
        onAdd={loadList}
        onClose={() => setAddPolicyVisible(false)}
      />
    </Block>
  )
}

export default List
