/*
 * @Author: your name
 * @Date: 2021-04-12 10:52:43
 * @LastEditTime: 2021-04-19 16:36:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/App.tsx
 */
import React, { useState, Fragment, useEffect } from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import UserNavBar from 'components/UserNavBar'
import NavBar from 'components/NavBar'
import { Item, Order, Supplier, Time, User, Daily } from './pages'
import { useSelector, useDispatch } from 'react-redux'
import { Drawer } from 'antd'
import { Dispatch, RootState } from './store'
import { Scene } from 'types/app'
import Policy from './pages/Policy'
import LoginModel from 'components/LoginModel'
import { isMobile } from 'utils/ua'
import Mobile from './mobile'
import Manual from './pages/Manual'
import Notice from './pages/Notice'
import Administrators from './pages/Administrators'
import Car from './pages/Car'
import System from './pages/System'
import './customAntd.less'
import './config/i18n'
import Announcement from './pages/System/View/Announcement'
import Reject from './pages/Reject'
import Statistics from './pages/Statistics'

const root = document.getElementById('root')

function App() {
  const { app: { configure, render } } = useSelector((state: RootState) => state)
  const [isMini, setIsMini] = useState<boolean>((root!.offsetWidth < 1395))
  const appDispatch = useDispatch<Dispatch>().app

  window.onresize = function () {
    setIsMini((root!.offsetWidth < 1395))
  }

  useEffect(() => {
    setTimeout(() => appDispatch.setConfigure({ scene: Scene.johnson }), 0)
  }, [appDispatch])

  if (isMobile()) {
    return <Mobile />
  }

  return (
    <Fragment>
      <div className='App'>
        <BrowserRouter>
          <div className='g-body' key={`body${render}`}>
            <Drawer></Drawer>
            <div className='g-nav'>
              <NavBar inlineCollapsed={isMini} />
            </div>
            <div className='g-main' style={{ marginLeft: isMini ? '85px' : '257px' }}>
              <UserNavBar />
              {configure ?
                <Fragment>
                  <Route path='/statistics' render={() => <Statistics />}></Route>
                  <Route path='/item' render={() => <Item />}></Route>
                  <Route path='/time' render={() => <Time />}></Route>
                  <Route path='/order' render={() => <Order />}></Route>
                  <Route path='/supplier' render={() => <Supplier />}></Route>
                  <Route path='/policy' render={() => <Policy />}></Route>
                  <Route path='/user' render={() => <User />}></Route>
                  <Route path='/administrators' render={() => <Administrators />} ></Route>
                  <Route path='/daily' render={() => <Daily />}></Route>
                  <Route path='/manual' render={() => <Manual />} ></Route>
                  <Route path='/notice' render={() => <Notice />} ></Route>
                  <Route path='/car' render={() => <Car />} ></Route>
                  <Route path='/system' render={() => <System />} ></Route>
                  <Route path='/announcement' render={() => <Announcement />} ></Route>
                  <Route path='/reject' render={() => <Reject />} />
                </Fragment>
                : '应用初始化界面，当初始化函数没加载完全时会呈现这种状态'
              }
            </div>
          </div>
        </BrowserRouter>
      </div>
      <LoginModel />
    </Fragment>
  );
}

export default App
