import React, { FC, useEffect, useState } from "react"
import { Modal, Select } from "antd"
import { InputBox } from "components"
import { Group } from "services/supplier"

interface DialogType {
  list?: Group[],
  isOpen: boolean,
  resourceId: any,
  onSubmit: (id: number) => void
  onClose: () => void
}

const Dialog: FC<DialogType> = (props) => {
  const { Option } = Select
  const [option, setOption] = useState<number>()

  return (
    <Modal
      title='变更分组'
      visible={props.isOpen}
      onCancel={props.onClose}
      onOk={() => option && props.onSubmit(option)}
    >
      {props.list ?
        <InputBox style={{ display: 'flex', justifyContent: 'center' }} title='选择目标分组'>
          <Select key={props.resourceId} onChange={(value) => { setOption(value as number) }} style={{ width: 200 }} placeholder='请选择预约对象'>
            {props.list && props.list.length ? props.list.map(item =>
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ) : null
            }
          </Select>
        </InputBox> : null
      }

    </Modal>
  )
}

export default Dialog