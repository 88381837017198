import { InputBox } from "components"
import React, { FC, useCallback, useRef } from "react"
import { Link } from "react-router-dom"
import { TimeFragment } from "services/dilay"
import { weekItem } from "types/time"
import { timeDoubleFormat } from "utils/time"
import { Box as Border } from "./style"

interface BoxType {
  type: string,
  selectResourceList?: number[] | undefined,
  weekList?: weekItem[],
  data: TimeFragment,
}

const Box: FC<BoxType> = (props) => {
  const border = useRef(null)
  const { data, selectResourceList, type, weekList } = props
  const getSecond = useCallback((time: string) => {
    return (Number(time.split(":")[0]) * 60) + Number(time.split(":")[1])
  }, [])

  const getWeekTableRange = useCallback((time: number) => {
    const min = Math.floor(time / 60)
    const second = time % 60
    return min * 98 + second * 1.6 + 1
  }, [])

  const switchStateColor = useCallback((state: string) => {
    let primary = ""
    let secondary = ""
    switch (state) {
      case "empty":
        primary = "#1890FF"
        secondary = "#E6F7FF"
        break
      case "usable":
        primary = "#52C41A"
        secondary = "#F6FFED"
        break
      case "full":
        primary = "#FAAD14"
        secondary = "#FFFBE6"
        break
      case "disable":
        primary = "#ddd"
        secondary = "#fff"
        break
      default:
        primary = ""
        secondary = ""
        break
    }
    return { primary, secondary }
  }, [])

  const [startTime, endTime] = data.time.split("-")
  const startTimeLength = getSecond(startTime)
  const endTimeLength = getSecond(endTime)
  const timeLength = endTimeLength - startTimeLength
  let key = ""
  const { primary, secondary } = switchStateColor(data.color)
  let timeLengthIndex = 0
  if (type === "resource") {
    key = data.resource_id.toString() + data.id.toString()
    selectResourceList!.forEach((selectResource, index) => {
      if (selectResource === Number(data.resource_id)) {
        timeLengthIndex = index
      }
    })
  }
  if (type === "time") {
    key = data.date + data.resource_id.toString() + data.id.toString()
    weekList!.forEach((week, index) => {
      if (`${week.year}-${timeDoubleFormat(Number(week.month))}-${timeDoubleFormat(Number(week.day))}` === data.date) {
        timeLengthIndex = index
      }
    })
  }

  const top = getWeekTableRange(startTimeLength)
  const height = getWeekTableRange(timeLength)
  const left = timeLengthIndex * 75.7
  const displayInfoList = []
  for (let name in data.display_list) {
    displayInfoList.push(<InputBox title={name}>{data.display_list[name]}</InputBox>)
  }
  return (
    <Link to={`/daily/detail/${data.resource_id}_${data.date}_${data.time}_${data.id}`}>
      <Border
        ref={border}
        tabIndex={-1}
        key={key}
        style={{
          top,
          left,
          height,
          background: secondary,
          color: primary,
          border: `1px solid ${primary}`
        }}
      >
        {data.color === "disable" ?
          <div>{data.state}</div> :
          <div>({data.use_load}/{data.max_load}) </div>
        }
      </Border>
    </Link>
  )
}

export default Box