import { CalendarOutlined, FileTextOutlined } from '@ant-design/icons'
import React, { FC } from 'react'
import { Route } from 'react-router'
import { BrowserRouter, Link } from 'react-router-dom'
import Daily from './Daily'
import Order from './Order'

const Mobile: FC = () => {
  return (
    <div>
      <BrowserRouter>
        <Route path='/order' render={() => <Order />} />
        <Route path='/daily' render={() => <Daily />} />
        <div style={{ height: 45 }}></div>
        <div
          style={{
            height: 45,
            borderTop: "1px solid #ccc",
            position: "fixed", bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            background: "white",
            zIndex: 1000
          }}
        >
          <Link to='/daily/list'>
            <div style={{ height: 42, textAlign: 'center', display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
              <CalendarOutlined size={20} />
              <p>预约实况</p>
            </div>
          </Link>
          <Link to='/order/list'>
            <div style={{ height: 42, textAlign: 'center', display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
              <FileTextOutlined size={20} />
              <p>订单列表</p>
            </div>
          </Link>
        </div>
      </BrowserRouter>
    </div>
  )
}

export default Mobile
