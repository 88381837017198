export const whiteColumns = [{
  key: 'name',
  dataIndex: 'name',
  title: '供应商名称'
}, {
  key: 'group',
  dataIndex: 'group',
  title: '策略分组'
}, {
  key: 'weight',
  dataIndex: 'weight',
  title: '供应商权重'
}, {
  key: 'phone',
  dataIndex: 'phone',
  title: '联系方式'
}, {
  key: 'create_time',
  dataIndex: 'create_time',
  title: '注册时间'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]

export const reviewColumns = [{
  key: 'name',
  dataIndex: 'name',
  title: '供应商名称'
}, {
  key: 'create_time',
  dataIndex: 'create_time',
  title: '注册时间'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]

export const userColumns = [{
  key: 'name',
  dataIndex: 'name',
  title: '用户姓名'
}, {
  key: 'phone',
  dataIndex: 'phone',
  title: '联系方式'
}, {
  key: 'weight',
  dataIndex: 'weight',
  title: '供应商权重'
}, {
  key: 'order_time',
  dataIndex: 'order_time',
  title: '下单时间'
}, {
  key: 'group',
  dataIndex: 'group',
  title: '策略分组'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]

export const supplierDialogStyle = {
  marginTop: '16px',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '80px'
}

export const infoStyle = {
  flex: '0 0 33%',
  marginBottom: '15px',
  height: '25px',
}