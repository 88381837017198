/*
 * @Author: your name
 * @Date: 2020-09-29 15:26:02
 * @LastEditTime: 2021-04-20 17:41:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/index.ts
 */
import finance from './finance'
import order from './order'
import time from './time'
import item from './item'
import supplier from './supplier'
import userLogin from './userLogin'
import policy from './policy'
import daily from './dilay'
import user from './user'
import administrator from './administrator'
import notice from './notice'
import car from './car'
import system from './system'
import common from './common'
import announcement from './announcement'
import statistics from './statistics'

export {
  finance,
  time,
  supplier,
  userLogin,
  daily,
  administrator,
  system,
  announcement,
  item,
  policy,
  order,
  user,
  notice,
  car,
  common,
  statistics
}

export default {
  finance,
  time,
  supplier,
  userLogin,
  daily,
  administrator,
  system,
  announcement,
  item,
  policy,
  order,
  user,
  notice,
  car,
  common,
  statistics
}