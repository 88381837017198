import React, { FC, useState, useEffect, useCallback, Fragment } from 'react'
import { InputBox } from 'components'
import { RereadRuleType, CustomRuleType } from 'types/time'
import moment from "moment"
import { Drawer, Tabs, Input, DatePicker, Switch, Button, message, Spin, Modal } from 'antd'
import { PanelRowStyle, PanelLeftRowStyle, PanelInput, defaultCustomRule } from 'consts/time'
import { useParams } from 'react-router'
import { time } from 'services'
import { FormatDay } from 'utils/time'
import { timeTableNewItem } from 'utils/vac'
import EditModal from '../Modal/Modal'
import EditModalConfirm from '../Modal/ModalConfirm'
import { advancedContras } from 'utils/common'
import Reread from '../Reread'
import { TimeItemType } from 'services/time'

interface EditPanelType {
  isOpen: boolean,
  defaultTimeId: number,
  defaultDate: string,
  defaultIsEdit: boolean,
  onClose: () => void,
  onSubmit: () => void,
}

const EditPanel: FC<EditPanelType> = (props) => {
  const { isOpen, defaultTimeId, defaultDate, defaultIsEdit, onClose, onSubmit } = props
  const { cardId: resource_id } = useParams<{ cardId: string }>()
  const { TabPane } = Tabs
  const { RangePicker } = DatePicker
  const [tag, setTag] = useState<string>()
  const [load, setLoad] = useState<number>()
  const [date, setDate] = useState<Date>()
  const [initDate, setInitDate] = useState<Date>()
  const [timeRange, setTimeRange] = useState<string[]>()
  const [rereadRule, setRereadRule] = useState<RereadRuleType>()
  const [initRereadRule, setInitRereadRule] = useState<RereadRuleType>()
  const [isUseRereadRule, setIsUseRereadRule] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(true)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [removeIsOpen, setRemoveIsOpen] = useState<boolean>(false)
  const [editIsOpen, setEditIsOpen] = useState<boolean>(false)
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false)
  const [orderLastTime, setOrderLastTime] = useState<string>("")
  const [isAlone, setIsAlone] = useState<boolean>(false)
  const [customRule, setCustomRule] = useState<CustomRuleType>(defaultCustomRule)
  const [initCustomRule, setInitCustomRule] = useState<CustomRuleType>(defaultCustomRule)
  const [payload, setPayload] = useState<TimeItemType>()
  const [isTipOpen, setIsTipOpen] = useState<boolean>(false)

  useEffect(() => {
    isOpen && (async () => {
      setPageLoading(true)
      const data = await time.getTimeDetail({ time_id: defaultTimeId })
      setPageLoading(false)
      setTag(data.tag)
      setLoad(data.load)
      setDate(new Date(defaultDate))
      setInitDate(new Date(data.date))
      setTimeRange([data.start_time, data.end_time])
      setDisable(data.disable)
      setIsAlone(data.is_alone!)
      setInitRereadRule(data.reread_rule)
      setRereadRule(data.reread_rule)
      setInitCustomRule(data.custom_rule)
      setCustomRule(data.custom_rule)
      setIsUseRereadRule(data.reread_rule !== 'today')
    })()
  }, [defaultTimeId, defaultDate, isOpen])


  const submit = useCallback(async (payload) => {
    try {
      await time.editTimeItem(payload)
      setLoading(false)
      setEditIsOpen(false)
      setConfirmIsOpen(false)
      setIsTipOpen(false)
      onSubmit()
    } catch (err) {
      setLoading(false)
      err.response && message.error(err.response.data.message)
    }
  }, [onSubmit])

  //修改时段
  const editTimeItem = useCallback(async (type) => {
    if (timeTableNewItem(date, timeRange)) return
    setLoading(true)
    const payload = {
      time_id: defaultTimeId,
      tag: tag || '',
      weight: 1,
      load: load || -1,
      resource_id: Number(resource_id),
      date: FormatDay(date!),
      start_time: timeRange![0],
      end_time: timeRange![1],
      reread_rule: isUseRereadRule && rereadRule ? rereadRule : RereadRuleType.today,
      custom_rule: customRule,
      disable,
      month_number: 0,
      is_repeat_update: isAlone ? false : type ? !!!(type === "single") : void 0
    }
    setPayload(payload)
    const data = await time.VACTipTime(payload)
    if (data.is_overlapping || data.is_overlap) {
      setIsTipOpen(true)
    } else {
      submit(payload)
    }
  }, [date, defaultTimeId, timeRange, tag, load, resource_id, isUseRereadRule, rereadRule, customRule, disable, onSubmit, isAlone])

  //点击按钮调用当前时间方法
  const handleClickFooterButton = useCallback(async (callBack: () => void) => {
    const data = await time.getEffectTime({
      time_id: defaultTimeId,
      date: FormatDay(initDate!)
    })
    setOrderLastTime(data.show_start_date)
    callBack()
  }, [defaultTimeId, initDate])

  //删除时段方法
  const handleClickRemove = useCallback(async (type) => {
    setLoading(true)
    try {
      await time.removeTimeItem({
        time_id: defaultTimeId,
        is_repeat_del: !!!(type === "single"),
        date: FormatDay(date!)
      })
      onSubmit()

    } catch (err) {
      message.error(err.response.data.message)
    } finally {
      setRemoveIsOpen(false)
      setLoading(false)
    }
  }, [defaultTimeId, date, onSubmit])

  const switchModal = useCallback(() => {
    if (initRereadRule === rereadRule && advancedContras(customRule, initCustomRule)) {
      setEditIsOpen(true)
    } else {
      setConfirmIsOpen(true)
    }
  }, [initRereadRule, rereadRule, customRule, initCustomRule])

  return (
    <Fragment>
      <Drawer
        destroyOnClose
        visible={isOpen}
        width={512}
        title='修改'
        bodyStyle={{
          padding: ' 0 24px',
        }}
        onClose={onClose}
        footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
        footer={
          <Fragment>
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => handleClickFooterButton(() => setRemoveIsOpen(true))}
            >
              删除
            </Button>
            <Button
              loading={loading}
              disabled={loading || !defaultIsEdit}
              type='primary'
              style={{ marginLeft: '8px' }}
              onClick={() => handleClickFooterButton(switchModal)}
            >
              编辑
            </Button>
          </Fragment>
        }
      >
        <Tabs defaultActiveKey='1'>
          <TabPane tab='&nbsp;&nbsp;基本设置&nbsp;&nbsp;' key='1'>
            <Spin spinning={pageLoading}>
              <InputBox style={PanelRowStyle} title='时间段标签'>
                <Input
                  value={tag}
                  onChange={(e) => { setTag(e.target.value) }}
                  style={PanelInput}
                  disabled={!defaultIsEdit}
                />
              </InputBox>
              <InputBox style={PanelRowStyle} title='选择日期'>
                <DatePicker
                  value={moment(date)}
                  style={PanelInput}
                  onChange={(_value, string) => setDate(new Date(string))}
                  allowClear={false}
                  disabled={!defaultIsEdit}
                />
              </InputBox>
              <InputBox style={PanelRowStyle} title='起止时间'>
                <RangePicker
                  picker='time'
                  format='HH:mm'
                  value={timeRange && timeRange.length ? [moment(timeRange[0], 'HH:mm'), moment(timeRange[1], 'HH:mm')] : undefined}
                  onChange={(_value, string) => setTimeRange(string)}
                  allowClear={false}
                  disabled={!defaultIsEdit}
                  style={{
                    width: '224px'
                  }}
                />
              </InputBox>
              <InputBox style={PanelRowStyle} title='可预约人数'>
                <Input
                  type='number'
                  value={load}
                  onChange={(e) => { setLoad(Number(e.target.value) < 1 ? 1 : Number(e.target.value)) }}
                  style={PanelInput}
                  disabled={!defaultIsEdit}
                />
              </InputBox>
              <InputBox style={PanelLeftRowStyle} title='开放状态'>
                <Switch
                  checked={disable}
                  disabled={!defaultIsEdit}
                  checkedChildren="开放"
                  unCheckedChildren="禁用"
                  onChange={(value) => { setDisable(value) }}
                />
              </InputBox>
              {
                isAlone ? null :
                  <Fragment>
                    <InputBox style={PanelLeftRowStyle} title='重复生成'>
                      <Switch
                        disabled={!defaultIsEdit}
                        checked={isUseRereadRule}
                        checkedChildren="使用"
                        unCheckedChildren="禁用"
                        onChange={(value) => { setIsUseRereadRule(value) }}
                      />
                    </InputBox>
                    {
                      isUseRereadRule ?
                        <Reread
                          disabled={!defaultIsEdit}
                          reread_rule={rereadRule!}
                          custom_rule={customRule}
                          onchangeRereadRule={(value) => setRereadRule(value)}
                          onchangeCustomRule={(value) => setCustomRule(value)}
                        />
                        : null
                    }
                  </Fragment>
              }
            </Spin>
          </TabPane>
          <TabPane tab='&nbsp;&nbsp;高级设置&nbsp;&nbsp;' key='2'>
            暂无可选项
        </TabPane>
        </Tabs>
      </Drawer>
      {
        isAlone ?
          <Fragment>
            <EditModalConfirm
              isOpen={removeIsOpen}
              onClose={() => setRemoveIsOpen(false)}
              callBack={handleClickRemove}
              title="删除时段"
            >
              确认删除时段
            </EditModalConfirm>
            <EditModalConfirm
              isOpen={editIsOpen}
              onClose={() => setEditIsOpen(false)}
              callBack={editTimeItem}
              title="修改时段"
            >
              确认修改时段
            </EditModalConfirm>
          </Fragment> :
          <Fragment>
            <EditModal
              isOpen={removeIsOpen}
              onClose={() => setRemoveIsOpen(false)}
              callBack={handleClickRemove}
              title="删除"
            >
              请确保，以该规则创建的事件无预约订单， 否则删除会导致已预约的订单被取消。最后订单时间为:{orderLastTime}
            </EditModal>
            <EditModal
              isOpen={editIsOpen}
              onClose={() => setEditIsOpen(false)}
              callBack={editTimeItem}
              title="项目时间段配置已更改，您希望同时应用至其他重复时间段还是仅更改当前时间段配置？"
            >
              请确保以改规则创建的事件无预约订单，否则更改只会从没有订单的日期之后生效。最后订单时间为:{orderLastTime}
            </EditModal>
            <EditModalConfirm
              isOpen={confirmIsOpen}
              onClose={() => setConfirmIsOpen(false)}
              callBack={editTimeItem}
              title="项目时间段配置已更改，是否保存？"
            >
              请确保以改规则创建的事件无预约订单，否则更改只会从没有订单的日期之后生效。最后订单时间为:{orderLastTime}
            </EditModalConfirm>
          </Fragment>
      }
      <Modal
        title="提示"
        visible={isTipOpen}
        onOk={() => submit(payload)}
        onCancel={() => {
          setIsTipOpen(false)
          setLoading(false)
        }}
      >
        <p>该时段已存在时段,仍要将该时段修改到此时段?</p>
      </Modal>
    </Fragment >
  )
}

export default EditPanel