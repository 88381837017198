import React, { FC, useState } from "react"
import ReactDOM from "react-dom"
import { Checkbox } from "antd-mobile"
import { CancelButton, Sheet, SheetHeader, SheetWrapper } from "./style"

interface Props {
  title: string;
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
  onClose: () => void;
}

const CheckboxSheet: FC<Props> = props => {
  const { title, options, value, onChange, onClose } = props

  const [checkedList, setCheckedList] = useState<string[]>(value)

  return (
    <SheetWrapper onClick={onClose}>
      <Sheet onClick={e => e.stopPropagation()}>
        <SheetHeader>{title}</SheetHeader>
        {options.map(option =>
          <Checkbox.CheckboxItem
            checked={checkedList.indexOf(option) !== -1}
            onChange={(e: any) => {
              if (e.target.checked) {
                checkedList.push(option)
              } else {
                const index = checkedList.findIndex((value: any) => value === option)
                if (index !== -1) {
                  checkedList.splice(index, 1)
                }
              }
              setCheckedList([...checkedList])
              onChange(checkedList)
            }}
          >{option}</Checkbox.CheckboxItem>
        )}
        <CancelButton onClick={onClose}>取消</CancelButton>
      </Sheet>
    </SheetWrapper >
  )
}

interface Params {
  title: string;
  options: string[];
  value: string[];
}

function renderCheckboxSheet(params: Params, onChange: (value: string[]) => void) {
  if (!document.getElementById('sheet')) {
    const element = document.createElement('div')
    element.id = 'sheet'
    document.body.appendChild(element)
  }

  const mountEl = document.getElementById('sheet') as HTMLDivElement

  const { title, options, value } = params

  ReactDOM.render(
    <CheckboxSheet
      title={title}
      options={options}
      value={value}
      onChange={onChange}
      onClose={() => {
        ReactDOM.render([], mountEl)
        document.body.removeChild(mountEl)
      }}
    />,
    mountEl
  )
}

export default renderCheckboxSheet

