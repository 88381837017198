import React, { FC, useState, useCallback, useEffect } from "react"
import { Modal, Button, Alert, Table, Popconfirm, message } from "antd"
import { useParams } from "react-router-dom"
import { order } from "services"
import { Queue } from "services/order"
import { QueueColumns } from "consts/order"


interface ExportDialogType {
  isOpen: boolean,
  onClose: () => void
  onSubmit: () => void
}

const QueueDialog: FC<ExportDialogType> = (props) => {
  const { orderId } = useParams<{ orderId: string }>()
  const [list, setList] = useState<any[]>()

  const handleClickDirect = useCallback(async () => {
    try {
      await order.action({
        id: [orderId],
        action: "插队",
        payload: {
          type: "direct"
        }
      })
      message.success("替换成功-审核已同意")
      props.onSubmit()
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [orderId])

  const handleClickReplace = useCallback(async (index) => {
    try {
      await order.action({
        id: [orderId],
        action: "插队",
        payload: {
          type: "replace",
          num: index
        }
      })
      message.success("插队成功-审核已同意")
      props.onSubmit()
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [orderId])

  const handleList = useCallback((list: Queue[]) =>
    list.map((item, _index) => {
      const index = _index + 1
      return ({
        index,
        name: item.name,
        cellphone: item.cellphone,
        supplier: item.supplier,
        supplier_type: item.supplier_type,
        custom: (
          <Popconfirm
            placement="bottom"
            title={"您要确定替换此订单么"}
            onConfirm={() => handleClickReplace(index)}
            okText={"确定"}
            cancelText="暂不操作"
          >
            <Button size="small" type="link">替换</Button>
          </Popconfirm>
        )
      })
    }), [handleClickReplace]
  )

  useEffect(() => {
    (async () => {
      const { orders: list } = await order.getQueue({ id: orderId })
      setList(list.length ? handleList(list) : [])
    })()
  }, [orderId])

  return (
    <Modal
      title="插队提示"
      visible={props.isOpen}
      onCancel={props.onClose}
      width={704}
      footer={
        <div>
          <Button onClick={props.onClose}> 取消</Button>
          <Popconfirm placement="bottom" title={"您要直接插入此订单么"} onConfirm={handleClickDirect} okText={"确定"} cancelText="暂不操作">
            <Button type="primary" >直接插入</Button>
          </Popconfirm>

        </div>
      }
    >
      <Alert
        message="本时段已无剩余可预约名额，请选择直接插队或替代已在队列中的一位用户。"
        type="warning"
        closable
      />
      <Table
        columns={QueueColumns}
        dataSource={list}
        bordered
        style={{ marginTop: 17, background: "white" }}
        pagination={false}
        size="small"
      />
    </Modal>
  )
}

export default QueueDialog