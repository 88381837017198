/*
 * File: /src/pages/System/View/Decoration/Home/Carousel.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Thursday 2020-12-10 10:00:26
 * Last Modified: Thursday 2020-12-10 10:00:26
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Col, Divider, Image, Row, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import OSSUpload from 'components/OSSUpload'
import { IndexStyle } from 'types/decoration'

interface ImgProps {
  index: number
}

const Img: FC<ImgProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false)
  const indexStyle = useSelector((state: RootState) => state.decoration).indexStyle as IndexStyle
  const dispatch = useDispatch<Dispatch>().decoration

  const handleChange = useCallback((url?: string) => {
    if (url) {  // 替换
      indexStyle.carouseList.splice(props.index, 1, { url })
    } else {  // 删除
      indexStyle.carouseList.splice(props.index, 1)
    }
    dispatch.setIndexStyle(indexStyle)
  }, [props, indexStyle])

  return (
    <Col
      span={12}
      style={{ position: 'relative' }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <Image src={indexStyle.carouseList[props.index].url} height={100} />
      <Row
        align="middle"
        justify="center"
        style={{
          position: 'absolute',
          width: '100%',
          left: '50%',
          top: '50%',
          display: visible ? 'flex' : 'none',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Col>
          <OSSUpload
            fullpath
            crop={false}
            showUploadList={false}
            onUploaded={url => handleChange(url)}
          >
            <Button type="text" style={{ color: '#FFF', padding: 0 }}>更换</Button>
          </OSSUpload>
        </Col>
        <Col>
          <Divider type="vertical" style={{ backgroundColor: '#FFF' }} />
        </Col>
        <Col>
          <Button
            type="text"
            style={{ color: '#FFF', padding: 0 }}
            onClick={() => handleChange()}
          >删除</Button>
        </Col>
      </Row>
    </Col>
  )
}

const Carousel: FC = () => {
  const indexStyle = useSelector((state: RootState) => state.decoration).indexStyle as IndexStyle
  const dispatch = useDispatch<Dispatch>().decoration

  return (
    <Fragment>
      <Typography.Title level={4}>轮播图设置</Typography.Title>
      <Row gutter={[12, 6]}>
        {indexStyle.carouseList.map((img, index) => <Img index={index} />)}
      </Row>
      <OSSUpload
        fullpath
        showUploadList={false}
        crop={false}
        onUploaded={url => {
          indexStyle.carouseList.push({ url })
          dispatch.setIndexStyle(indexStyle)
        }}
      >
        <Button type="dashed" style={{ width: 350, borderRadius: 6 }}>
          <PlusOutlined /> 添加图片
        </Button>
      </OSSUpload>
    </Fragment>
  )
}

export default Carousel
