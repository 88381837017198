import React, { FC } from 'react'
import { Route } from 'react-router'
import Detail from './View/detail'
import List from './View/list'


const Daily: FC = () => {


  return (
    <div>
      <Route path='/daily/list/:id?' render={() => <List />} />
      <Route path='/daily/detail/:id?' render={() => <Detail />} />
    </div>
  )
}

export default Daily
