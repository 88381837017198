/*
 * @Author: your name
 * @Date: 2020-10-26 16:17:23
 * @LastEditTime: 2020-10-27 09:56:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/models/administrators.ts
 */
import { createModel } from '@rematch/core'
import { ReactNode } from 'react'
import { Column, Form } from 'types/administrator'

interface AdministratorsState {
  form: Form,
  tempList: Column[]
}

const data: AdministratorsState = {
  form: {
    name: "",
    account: "",
    password: "",
    phone: void 0,
    is_use_wechat_message: false,
    description: "",
    resource_id_list: [],
  },
  tempList: [] as Column[]
}

export const administrators = createModel({
  state: data as AdministratorsState,
  reducers: {
    setForm: (state, payload: any): AdministratorsState => ({
      ...state,
      form: payload
    }),
    setTempList: (state, payload: Column[]): AdministratorsState => ({
      ...state,
      tempList: payload
    }),
    resetState: (): AdministratorsState => (data)
  }
})

