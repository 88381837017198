import React, { FC, useCallback, useState } from 'react'
import { Button, message, Modal, Typography } from 'antd'
import XLSX, { exportExcel } from 'components/XLSX'
import services from 'services'
import { OSS_EXCEL } from 'consts/url'
import { mapKeys } from 'utils/common'

interface Props {
  visible: boolean,
  onCancel: () => void,
  onOk: () => void
}

const ImportUser: FC<Props> = (props) => {
  const { visible, onCancel, onOk } = props
  const [matrix, setMatrix] = useState<AnyObject[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = useCallback(async () => {
    if (!matrix.length) {
      message.warning('请选择要导入的用户列表')
      return
    }
    setLoading(true)
    services.user.add({ users: matrix })
      .then(() => {
        message.success('导入用户列表成功')
      })
      .catch((error) => {
        const failList = error.response.data?.data?.fail_list
        if (failList) {
          const mapping = {
            username: '用户姓名（必填）',
            phone: '联系方式（非必填）',
            email: '邮箱账号（非必填）',
            password: '登录密码（非必填）',
            supplier: '供应商（必填）',
            fail_reason: '失败原因'
          }
          exportExcel(mapKeys(failList, mapping), '错误报告-用户导入')
          message.error('部分数据导入失败，错误报告已下载，请更改并重新上传')
        } else {
          message.error('导入失败~')
        }
      })
      .finally(() => {
        onOk()
        onCancel()
        setLoading(false)
      })
  }, [matrix])

  return (
    <Modal
      title="导入用户列表"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <XLSX
        mapping={{
          'A': { name: 'username' },
          'B': { name: 'phone' },
          'C': { name: 'email' },
          'D': { name: 'password', default: '654321' },
          'E': { name: 'supplier' }
        }}
        onChange={matrix => setMatrix(matrix)}
        hasHeader
        type="dragger"
        loading={loading}
      />
      <Typography.Text style={{ color: '#929292', display: 'block', margin: '8px auto' }}>
        不知道格式？请查看
          <Typography.Link href={`${OSS_EXCEL}/user.xlsx`} target="_blank">
          用户导入模板
          </Typography.Link>
      </Typography.Text>
      <Button
        type="primary"
        style={{ display: 'block', margin: '10px auto 0px auto' }}
        onClick={handleClick}
      >确定导入</Button>
    </Modal>
  )
}

export default ImportUser
