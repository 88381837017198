export const tabList = [{
  key: '1',
  tab: '全部订单'
}, {
  key: '2',
  tab: '进行中'
}, {
  key: '3',
  tab: '退款中'
}, {
  key: '4',
  tab: '已关闭'
}]

export const columns = [{
  key: 'date',
  dataIndex: 'date',
  title: '预约日期'
}, {
  key: 'lesson',
  dataIndex: 'lesson',
  title: '时段'
}, {
  key: 'week',
  dataIndex: 'week',
  title: '日期'
}, {
  key: 'name',
  dataIndex: 'name',
  title: '预约项目'
}, {
  key: 'sequence_number',
  dataIndex: 'sequence_number',
  title: '排位序号'
}, {
  key: 'states',
  dataIndex: 'states',
  title: '审核原因'
}]

export const fieldColumns = [{
  key: 'name',
  dataIndex: 'name',
  title: '信息名称'
}, {
  key: 'field_content',
  dataIndex: 'field_content',
  title: '信息内容'
}]

export const infoStyle = {
  flex: '0 0 33%',
  marginBottom: '15px'
}

export const QueueColumns = [{
  key: 'index',
  dataIndex: 'index',
  title: '排队序号'
}, {
  key: 'name',
  dataIndex: 'name',
  title: '用户名称'
}, {
  key: 'cellphone',
  dataIndex: 'cellphone',
  title: '联系方式'
}, {
  key: 'supplier',
  dataIndex: 'supplier',
  title: '供应商名称'
}, {
  key: 'supplier_type',
  dataIndex: 'supplier_type',
  title: '供应商类型'
}, {
  key: 'custom',
  dataIndex: 'custom',
  title: '操作'
}]

export const STATUS: AnyObject = {
  '待签到': {
    moveDownText: '签到',
    moveUpText: '取消',
  },
  '已签到': {
    moveDownText: '同意收货',
    moveUpText: '拒绝收货'
  },
  '待审核': {
    moveDownText: '同意',
    moveUpText: '拒绝'
  },
  '未签到': {
    moveDownText: '补签',
    moveUpText: '取消',
  }
}
