/*
 * @Author: your name
 * @Date: 2021-04-12 14:50:49
 * @LastEditTime: 2021-04-13 15:24:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Reject/View/Alert.tsx
 */
import { Button, Divider, Input, message, } from "antd"
import { Block, InputBox } from "components"
import React, { FC, useCallback, useContext, useEffect, useState } from "react"
import { system } from "services"
import { Title } from "./style"


const CustomOrderList: FC = () => {

  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      const data = await system.getConfig()
      setData(data)
    })()
  }, [])

  const submit = useCallback(async () => {
    try {
      setLoading(true)
      await system.setConfig({
        car_tip: data.pages_tips.car_tip,
        login_tip: data.pages_tips.login_tip
      })
      message.success("修改成功")
    } catch (err) {
      message.error(err)
    } finally {
      setLoading(false)
    }
  }, [data])

  return (
    <div style={{
      height: "100%",
      position: "absolute",
      width: "100%",
      top: 0,
      boxSizing: "border-box",
      paddingTop: 215,
      paddingBottom: 20,
      overflow: "hidden"
    }}>
      <Block
        loading={loading}
        style={{
          height: "100%",
          overflow: "auto"
        }}
        bodyStyle={{
          height: "100%",
          paddingBottom: 60,
          marginTop: 0,
        }}
      >
        <Title>短信记录</Title>
        <InputBox
          title="剩余短信数量"
          style={{ marginBottom: 24 }}
        >
          {data?.sms.sms_number}条
        </InputBox>
        <Divider style={{ margin: 0 }} />
        <Title>登录注册提示反馈</Title>
        <InputBox
          title="登录注册提示反馈"
          style={{
            alignItems: "inherit",
            marginBottom: 24
          }}
        >
          <Input.TextArea
            placeholder="请输入登录注册公告，不超过100字"
            value={data?.pages_tips.login_tip}
            style={{ width: 464 }}
            rows={3}
            onChange={(e) => {
              setData({
                ...data,
                pages_tips: {
                  ...data.pages_tips,
                  login_tip: e.target.value
                }
              })
            }}
          />
        </InputBox>
        <Divider style={{ margin: 0 }} />
        <Title>订单车辆确认提示反馈</Title>
        <InputBox
          title="订单车辆确认提示反馈"
          style={{
            alignItems: "inherit",
          }}
        >
          <Input.TextArea
            placeholder="请输入车辆使用反馈提示，不超过100字"
            value={data?.pages_tips.car_tip}
            style={{
              width: 464,
              marginBottom: 24
            }}
            rows={3}
            onChange={(e) => {
              setData({
                ...data,
                pages_tips: {
                  ...data.pages_tips,
                  car_tip: e.target.value
                }
              })
            }}
          />
        </InputBox>
        <div style={{ width: 464, height: 1, background: "#efefef", marginTop: 15, marginBottom: 15 }} />
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 64,
          width: "100%",
          position: "absolute",
          boxShadow: "0px -1px 4px 0px rgba(0, 21, 41, 0.12)",
          left: 0,
          bottom: 0,
          background: "white"
        }}>
          <Button type="primary" onClick={submit}>保存</Button>
        </div>
      </Block>
    </div >
  )
}

export default CustomOrderList