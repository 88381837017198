/*
 * @Author: your name
 * @Date: 2020-12-09 13:33:05
 * @LastEditTime: 2020-12-31 18:04:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/mobile/Daily/View/style.ts
 */

import styled from 'styled-components'

const width = window.innerWidth
const tableWidth = `${width - 45}px`
const height = window.innerHeight

export const Body = styled.div`
  height: 2397px;
  position: relative;
  margin-top: 140px;
`

export const Space = styled.div`
  height: 10px;
  background: #F7F8FA; 
`

export const TimeList = styled.div`
  top:0;
  height:2397px;
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  width:45px;
  position: absolute;
  padding-left:5px;
  z-index:800;
  background: white;
  box-sizing: border-box;
  padding-top:30px;
`

export const TimeListSpace = styled.div`
  height: 35px;
  width: 1px;
  background: white;
  position: absolute;
  top: 0;
  left: 45px; 
  z-index:10;
`

export const Time = styled.div`
  height: 15px;
  font-size:12px;
  line-height:12px;
  width:35px;
  color: #999;
`

export const TableBody = styled.div`
 height: 2390px;
 width: ${tableWidth};
 overflow: auto;
 position: relative;
 left:45px;
 border-left:1px solid #efefef;
`

export const WeekList = styled.div`
  width: 530px;
  display: flex;
  justify-content: space-around;
  height: 21px;
  align-items: center;
  margin-top: 7px;
`

export const WeekListFixed = styled.div`
  width: 530px;
  display: flex;
  justify-content: space-around;
  height: 21px;
  align-items: center;
  position: fixed;
  top: 145px;
  left: 45px;
  z-index: 700;
  background: white;
  box-sizing: border-box;
`


export const Week = styled.div`
  color: #999;
  font-size: 15px;
`

export const Table = styled.div`
  height: 2354px;
  width: 530px;
  margin-top: 7px;
  position: relative;
`

export const Line = styled.div`
  height: 1px;
  background: #efefef;
`

export const LineBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  width: 100%;
`

export const DateBox = styled.div`
  display: flex;
  justify-content: center;
  width: 250px;
`

export const DateTime = styled.div`
 font-size: 14px;
`

export const DateBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  height: 45px;
  z-index: 3;
  position: relative;
  background: white;
`

export const Card = styled.label`
  height: 145px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  border-bottom: 1px solid #efefef;
  background: white;
`

export const CardHeader = styled.div`
  display: flex;
  padding-left: 16px;
  align-items: center;
`

export const CardTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-left: 12px;
`

export const CardState = styled.div`
  height: 18px;
  text-align: center;
  display: flex;
  margin-left: 16px;
  align-items: center;
  padding: 0 4px;
  font-size: 12px;
  margin-top: 1px;
`

export const Footer = styled.div`
  position: fixed;
  bottom: 45px;
  left: 0;
  width: 100%;
  height:66px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: white;
`

export const FooterSpace = styled.div`
  height: 66px;
`

export const Html = styled.div`
  min-height: ${height - 45}px;
  width: 100%;
  background: #F7F8FA;
`