/*
 * @Author: your name
 * @Date: 2020-11-03 11:21:00
 * @LastEditTime: 2021-04-14 15:24:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Order/Model/Refuse/style.ts
 */
import styled from "styled-components"

export const Body = styled.div`
  padding-bottom: 16px;
`

export const Add = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px dashed #D9D9D9;
  margin-top: 16px;
  cursor: pointer;
  user-select: none;
`

export const Box = styled.div`
  background: #FAFAFA;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  padding-right: 100px;
`

export const Title = styled.div`
  height: 54px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`