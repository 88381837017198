/*
 * @Author: your name
 * @Date: 2021-04-12 10:52:43
 * @LastEditTime: 2021-04-12 16:07:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/Block/index.tsx
 */
import React, { FC, ReactNode, CSSProperties } from 'react'
import { Body, Header, Title, Main, Loading } from './style'
import { Spin } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface BlockProps {
  title?: ReactNode,
  description?: ReactNode,
  children?: ReactNode,
  loading?: boolean,
  showBack?: boolean
  style?: CSSProperties,
  bodyStyle?: CSSProperties;
}

const Block: FC<BlockProps> = (props) => {
  return (
    <Body
      style={props.bodyStyle}
    >
      {
        props.title || props.description ?
          <Header>
            <Title>
              {
                props.showBack &&
                <ArrowLeftOutlined
                  style={{ marginRight: 10 }}
                  onClick={() => window.history.back()}
                />}
              {props.title ? props.title : <div />}
            </Title>
            <div>{props.description ? props.description : <div />}</div>
          </Header>
          : null
      }
      <Main style={props.style}>{props.children}</Main>
      {props.loading ? <Loading><Spin size="large" /></Loading> : null}
    </Body>
  )
}

export default Block
