import { timeDoubleFormat } from "utils/time"

export const getWeekDay = (week: number) => {
  switch (week) {
    case 6: return 0
    default: return week + 1
  }
}

export const getWeekNumber = (day: string) => {
  switch (day) {
    case 'Sun': return 6
    case 'Mon': return 0
    case 'Tue': return 1
    case 'Wed': return 2
    case 'Thu': return 3
    case 'Fri': return 4
    default: return 5
  }
}

export const getDateString = (date: string) => {
  const [year, month, day] = date.split("-")
  return `${year}${timeDoubleFormat(Number(month))}${timeDoubleFormat(Number(day))}`
}




