import React, { FC } from 'react'
import { Body, Left, Right, Title, Description, Header, Bottom, BottomButton } from './style'

interface CardType {
  name?: string,
  img_url?: string,
  description?: string,
  onClick?: () => void
}

const Card: FC<CardType> = (props) => {

  return (
    <Body>
      <Left>
        <Header src={props.img_url} />
      </Left>
      <Right>
        <Title>{props.name}</Title>
        <Description>{props.description}</Description>
      </Right>
      <Bottom>
        <BottomButton onClick={props.onClick}>编辑</BottomButton>
      </Bottom>
    </Body>
  )
}

export default Card