/*
 * File: /src/pages/System/View/Decoration/Emulator/ResList.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 01:38:05
 * Last Modified: Wednesday 2020-12-09 01:38:05
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC } from 'react'

const ResList: FC = () => {
  return (
    <div
      style={{
        backgroundColor: '#EFEFEF',
        height: 300
      }}
    ></div>
  )
}

export default ResList
