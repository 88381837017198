import moment from "moment"

export const FormatDay = (date: Date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${date.getFullYear()}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
}

export const FormatDayChinese = (date: Date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${date.getFullYear()}年${month > 9 ? month : `0${month}`}月${day > 9 ? day : `0${day}`}日`
}

export const dateFormat = (fmt: string, date: Date) => {
  let ret;
  const opt: any = {
    "Y+": date.getFullYear().toString(),
    "m+": (date.getMonth() + 1).toString(),
    "d+": date.getDate().toString(),
    "H+": date.getHours().toString(),
    "M+": date.getMinutes().toString(),
    "S+": date.getSeconds().toString()
  }
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

export const getLastDay = (now: Date, last: number) => {
  return new Date(new Date(FormatDay(now)).getTime() - (24 * 3600 * 1000 * last))
}

export const handleWeekStartDate = (year: number, week: number) => {
  const date = new Date(((week + 1) * 7 + (year - 1970) * 365) * 86400000)
  const day = date.getDay() === 0 ? 7 : date.getDay()
  let monday = new Date(date.getTime() - (day + 1) * 86400000)
  if (new Date(monday.getTime() + 86400000).getMonth() === monday.getMonth()) {
    return `${monday.getFullYear()}-${monday.getMonth() + 1}-${monday.getDate() + 1}`
  } else {
    monday = new Date(monday.getTime() + 86400000)
    return `${monday.getFullYear()}-${monday.getMonth() + 1}-${monday.getDate()}`
  }

}

export const handleDateToWeek = (date: Date) => {
  const startTime = new Date(`${date.getFullYear()}-01-01`)
  const endTime = new Date(FormatDay(date))
  const time = Math.round((endTime.getTime() - startTime.getTime()) / 86400000);
  return Math.ceil((time + ((startTime.getDay() + 1) - 1)) / 7);
}

export const getWeekDay = (week: number) => {
  switch (week) {
    case 0: return 'Sun'
    case 1: return 'Mon'
    case 2: return 'Tue'
    case 3: return 'Wed'
    case 4: return 'Thu'
    case 5: return 'Fri'
    default: return 'Sat'
  }
}

export const timeDoubleFormat = (time: number) => {
  return time < 10 ? `0${time}` : time.toString()
}

export const handleDateToMin = (date: Date) => {
  return date.getHours() * 60 + date.getMinutes()
}

export const handClick24PM = (time: string) => {
  if (time === '24:00') {
    return '23:59'
  } else {
    return time
  }
}

export const getMinuteFromTime = (time: string) => {
  return (Number(time.substr(0, 2)) * 60) + Number(time.substr(2, 3))
}

export const getEndTimeFromWeek = (start: Date) => {
  return new Date(start.getTime() + (24 * 60 * 60 * 1000 * 6))
}

/**
 * 日期时间格式化
 */
const formatMap = {
  'date': 'YYYY-MM-DD',
  'time': 'hh:mm:ss',
  'datetime': 'YYYY-MM-DD hh:mm:ss',
  'timestamp': 'X',
  'unix': 'x'
}

type Format = keyof typeof formatMap

export function formatTime(time: string | number = Date.now(), format: Format = 'datetime') {
  return moment(time).format(formatMap[format])
}

/**
 * 获取本月所有日期
 */
export const getMonthAllDate = (year: number, month: number) => {
  const result = []
  let day = 1
  while (new Date(`${year}-${month + 1}-${day}`).getTime()) {
    result.push(`${year}-${timeDoubleFormat(month + 1)}-${timeDoubleFormat(day)}`)
    day++
  }
  return result
}
