import React, { FC, useState, useEffect } from "react"
import { Route } from "react-router-dom"
import { BreadcrumbOption } from "types/antd"
import { Header } from "components"
import { useTranslation } from "react-i18next"
import List from "./View/List"
import Detail from "./View/Detail"
import { order } from "services"
import { ConfigureResult } from "services/order"
import Attend from "./View/Attend"

const Order: FC = () => {
  const { t } = useTranslation()
  const [configure, setConfigure] = useState<ConfigureResult>()
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "订单管理"
  }, {
    name: "订单列表"
  }])

  // init
  useEffect(() => {
    (async () => {
      const data = await order.getConfigure()
      setConfigure(data)
    })()
  }, [])

  return (
    <div>
      <Route path='/order/list' render={() =>
        <React.Fragment>
          <Header
            title={t("page_order_title")}
            description={t("page_order_description")}
            breadcrumbs={breadcrumbs}
          />
          <List configure={configure} />
        </React.Fragment>
      } />
      <Route path='/order/detail/:orderId' render={() => <Detail configure={configure} />} />
      <Route path='/order/attend' render={() =>
        <React.Fragment>
          <Header
            title={t("page_order_attend_title")}
            description={t("page_order_qrcode_description")}
            breadcrumbs={breadcrumbs}
          />
          <Attend />
        </React.Fragment>
      } />
    </div>
  )
}

export default Order
