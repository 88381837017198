import { ReactNode } from "react";

export enum Scene {
  johnson = 'johnson'
}

export enum Sort {
  asc = 'asc',
  dec = 'dec'
}

export enum State { init, loading, resolve, reject }

export interface Configure {
  scene: Scene
}

export interface alertDialog {
  isOpen: boolean,
  content: ReactNode,
}

export interface TimeFragment {
  id: string,
  display_id: string,
  resource_id: string,
  date: string,
  time: string,
  max_load: number,
  use_load: number,
  color: string,
  state: string,
}
