import { Button } from "antd"
import { Block } from "components"
import WeekTable from "components/WeekTable"
import React, { FC, useState, useCallback, ReactNode, useEffect } from "react"
import { useParams } from "react-router"
import policy from "services/policy"
import { weekItem } from "types/time"
import {  timeDoubleFormat } from "utils/time"
import Item from "../Model/Item"
import { getDateString, getWeekDay, getWeekNumber } from "../utils/calendar"
import { Box, BoxSpace } from "./style"


const Calendar: FC = () => {
  const { type, id } = useParams<{ type: string, id: string }>()
  const now = new Date()
  const [state, setState] = useState<string>('display')
  const [date, setDate] = useState<Date>(now)
  const [weekList, setWeekList] = useState<weekItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<string[]>([])
  const [tempData, setTempData] = useState<string[]>([])
  const [render, setRender] = useState<number>(1)
  const [originSpecialList, setOriginSpecialList] = useState<string[]>([])

  //渲染时间格子
  const renderBoxList = useCallback(() => {
    const result: ReactNode[] = []
    for (let index = 0; index < 24; index++) {
      weekList.forEach(() => {
        result.push(<Box><BoxSpace /></Box>)
      })
    }
    return result
  }, [weekList])

  const handleClickBox = useCallback((id: string) => {
    const temp = [...tempData]
    const index = temp.findIndex(item => item === id)
    const idFragment = id.split("_")
    temp[index] = `${idFragment[0]}_${idFragment[1]}_${idFragment[2]}_${idFragment[3] === 'none' ? state : 'none'}`
    setTempData(temp)
    setData(temp)
  }, [tempData, type, state])

  //渲染选择格子
  const renderClickLayer = useCallback(() => {
    const list = state === 'display' ? data : tempData
    return list && list.length && list.map(item => (
      <Item
        data={item}
        state={state}
        type={type}
        onClick={(id: string) => { handleClickBox(id) }}
      />
    ))
  }, [type, weekList, state, data, tempData])

  //渲染时间格子
  const setBoxData = useCallback(async () => {
    const boxList = setNoneBox()
    setIsLoading(true)
    const data = await policy.getCalendar({ id })
    setIsLoading(false)
    const generalList = data.general
    const specialList = data.special
    setOriginSpecialList(specialList)
    switch (type) {
      case "固定型":
        if (generalList && generalList.length) {
          generalList.forEach(item => {
            const [week, time] = item.split("_")
            const index = resetIndexFromWeek(week, time)
            const temp = boxList[index]
            if (temp) {
              boxList[index] = temp.replace('none', 'general')
            }
          })
        }
        if (specialList && specialList.length) {
          specialList.forEach(item => {
            const [date, time] = item.split("_")
            const index = resetIndexFromDate(date, time, boxList)
            const temp = boxList[index]
            if (temp) {
              boxList[index] = temp.replace('none', 'special')
            }
          })
        }
      case "优先型":
      case "VIP型":
        if (generalList && generalList.length) {
          generalList.forEach(item => {
            const [week, time] = item.split("_")
            const index = resetIndexFromWeek(week, time)
            const temp = boxList[index]
            if (temp) {
              boxList[index] = temp.replace('none', 'general')
            }
          })
        }
        if (specialList && specialList.length) {
          specialList.forEach(item => {
            const [date, time] = item.split("_")
            const index = resetIndexFromDate(date, time, boxList)
            const temp = boxList[index]
            if (temp) {
              boxList[index] = temp.replace('none', 'special')
            }
          })
        }
    }
    setData(boxList)
  }, [id, weekList])

  const handleGeneralList = useCallback((data: string[]) => {
    const result: string[] = []
    data.forEach(item => {
      const idFragment = item.split("_")
      if (idFragment[3] === 'general') {
        result.push(`${getWeekNumber(idFragment[0])}_${idFragment[2]}`)
      }
    })
    return result
  }, [])

  const submitGeneralData = useCallback(async () => {
    const cycle = handleGeneralList(tempData)
    try {
      setIsLoading(true)
      await policy.updateGeneral({ id, data: { cycle } })
      setIsLoading(false)
      setState('display')
      setRender(render + 1)
    } finally {

    }
  }, [tempData, render])

  const handleSpecialList = useCallback((tempData: string[]) => {
    let result: string[] = []
    if (tempData.length) {
      tempData.forEach(item => {
        const idFragment = item.split("_")
        if (idFragment[3] === 'special') {
          result.push(`${getDateString(idFragment[1])}_${idFragment[2]}`)
        }
      })
    }
    if (originSpecialList.length) {
      const deleteDayList = weekList.map(week => (
        `${week.year}${timeDoubleFormat(Number(week.month))}${timeDoubleFormat(Number(week.day))}`
      ))
      const setList: string[] = []
      originSpecialList.forEach(originSpecial => {
        if (!deleteDayList.filter(deleteDay => deleteDay == originSpecial.split("_")[0]).length) {
          setList.push(originSpecial)
        }
      })
      result = [...result, ...setList]
    }
    
    return Array.from(new Set(result))
  }, [originSpecialList, weekList])

  const submitSpecialData = useCallback(async () => {
    const cycle = handleSpecialList(tempData)
    try {
      setIsLoading(true)
      await policy.updateSpecial({ id, data: { cycle } })
      setIsLoading(false)
      setState('display')
      setRender(render + 1)
    } finally {

    }
  }, [tempData, render, data, originSpecialList, weekList])

  const resetIndexFromWeek = useCallback((week: string, time: string) => {
    switch (type) {
      case "固定型":
        const startTime = time.substr(0, 4)
        const index = (Number(startTime.substr(0, 2)) * 2 + (startTime.substr(2, 2) === '30' ? 1 : 0)) * 7
        return getWeekDay(Number(week)) + index
      case "优先型":
      case "VIP型":
        return getWeekDay(Number(week)) + (time === 'PM' ? 7 : 0)
      default: return -1
    }
  }, [type])

  const resetIndexFromDate = useCallback((date: string, time: string, boxList: string[]) => {
    return boxList.findIndex((id) => {
      const idFragment = id.split("_")
      return getDateString(idFragment[1]) === date && idFragment[2] === time
    })
  }, [state, data, tempData])

  const setNoneBox = useCallback(() => {
    const result: string[] = []
    switch (type) {
      case "固定型":
        for (let index = 0; index < 48; index++) {
          const timeSpace = !!(Math.floor(index / 2) === index / 2)
          const hour = Math.floor(index / 2)
          let time = `${timeDoubleFormat(hour)}${timeSpace ? '00' : 30}${timeSpace ? timeDoubleFormat(hour) : timeDoubleFormat(hour + 1)}${timeSpace ? 30 : '00'}`
          if (index === 47) time = '23302359'
          weekList.forEach(item => {
            result.push(`${item.week}_${item.year}-${item.month}-${item.day}_${time}_none`)
          })
        }
      case "优先型":
      case "VIP型":
        for (let index = 0; index < 2; index++) {
          weekList.forEach(item => {
            result.push(`${item.week}_${item.year}-${item.month}-${item.day}_${index ? "PM" : "AM"}_none`)
          })
        }
    }
    return result
  }, [weekList, data])

  // 刷新时间格子
  useEffect(() => {
    if (!weekList || !weekList.length) return
    setBoxData()
  }, [weekList, render])

  useEffect(() => {
    if (state !== 'display') {
      setTempData([...data])
    } else {
      setTempData([])
    }
  }, [state, data])

  return (
    <React.Fragment>
      <Block
        showBack
        title={'送货日历'}
        description={
          state === 'display' ?
            <div>
              <Button onClick={() => { setState('general') }}>选择送货周期</Button>
              <Button
                onClick={() => { setState('special') }}
                style={{ marginLeft: 8 }}
              >
                选择特殊日期
              </Button>
            </div> :
            <div>
              <Button onClick={() => { setState('display') }}>取消</Button>
              <Button
                type='primary'
                onClick={() => {
                  state === "general" ? submitGeneralData() : void 0
                  state === "special" ? submitSpecialData() : void 0
                }}
                style={{ marginLeft: 8 }}
              >
                保存
              </Button>
            </div>
        }
        style={{ width: '1150px' }}
      >
        <WeekTable
          now={now}
          headerStyle={{ marginTop: 30 }}
          date={date}
          loading={isLoading}
          setDate={(payload) => { setDate(payload) }}
          renderNoneBoxList={renderBoxList}
          getWeekList={(weekList) => setWeekList(weekList)}
        >
          {renderClickLayer()}
        </WeekTable>
      </Block>
    </React.Fragment >
  )
}

export default Calendar