import React, { FC, useEffect, useState, useCallback } from 'react'
import { supplier } from 'services'
import { Button, Input, message, Table } from 'antd'
import { Block } from 'components'
import { reviewColumns } from 'consts/supplier'
import { Row, Title, Right, HandleList, Space } from './style'
import { SupplierList } from 'services/supplier'

const Review: FC = () => {
  const { Search } = Input
  const [total, setTotal] = useState<number>(0)
  const [data, setData] = useState<any[]>([])
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [size, setSize] = useState<number>(20)
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [actionLoading, setActionLoading] = useState<boolean>(false)
  const [render, setRender] = useState<number>(1)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await supplier.getReviewList({
        q: search || '',
        page_size: size,
        page,
        auth: false
      })
      setLoading(false)
      setTotal(data.total)
      setData(handleData(data.data))
    })()
  }, [search, render])

  const handleData = useCallback((data: SupplierList[]) => {
    if (!data || !data.length) return []
    return data.map(item => ({
      key: item.id,
      name: item.name,
      create_time: item.create_time,
      handle: (
        <HandleList>
          <Button
            loading={actionLoading}
            style={{ padding: '4px 5px' }}
            type='link'
            onClick={() => { handleClickAction('resolve', [item.id]) }}
          >
            同意
          </Button>
          <Space />
          <Button
            loading={actionLoading}
            style={{ padding: '4px 5px' }}
            type='link'
            onClick={() => { handleClickAction('reject', [item.id]) }}
          >
            拒绝
          </Button>
        </HandleList>
      )
    }))
  }, [])

  const handleClickAction = useCallback(async (action: string, _selectList?: number[]) => {
    const id_list: number[] = _selectList && _selectList.length ? _selectList : selectRowIdList as number[]
    if (!id_list || !id_list.length) {
      message.error('请选择一个供应商');
      return
    }
    const payload = { op: action, id_list }
    setActionLoading(true)
    await supplier.handleReview(payload)
    setActionLoading(false)
    setRender(render + 1)
  }, [selectRowIdList, render])

  return (
    <React.Fragment>
      <Block loading={loading}>
        <Row style={{ justifyContent: 'space-between' }}>
          <Title>待审核供应商列表</Title>
          <Right>
            <Search onSearch={(value) => { setSearch(value) }} style={{ width: '200px' }} placeholder='请输入' />
            <Button
              style={{ width: '65px', marginLeft: '8px' }}
              onClick={() => { handleClickAction('resolve') }}
            >
              同意
            </Button>
            <Button
              style={{ width: '65px', marginLeft: '8px' }}
              onClick={() => { handleClickAction('reject') }}
            >
              拒绝
            </Button>
          </Right>
        </Row>
        <Table
          dataSource={data}
          columns={reviewColumns}
          rowSelection={{
            selectedRowKeys: selectRowIdList,
            onChange: selectedRowKeys => { setSelectRowIdList(selectedRowKeys) }
          }}
          style={{ marginTop: '24px' }}
          pagination={{
            total: total,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize: size,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (_page, _size) => {
              if (_page !== page) setPage(_page)
              if (_size !== size) setSize(_size!)
            },
          }}
        />
      </Block>
    </React.Fragment>
  )
}

export default Review
