import React, { FC, useEffect, useState, useCallback } from 'react'
import { item, policy, supplier, time } from 'services'
import { Button, Col, Input, message, Table, Tabs } from 'antd'
import { Block, InputBox } from 'components'
import { whiteColumns } from 'consts/supplier'
import AddSupplierDialog from '../Model/Dialog/AddSupplierDialog'
import { Row } from './style'
import { Group, SupplierList } from 'services/supplier'
import { Link } from 'react-router-dom'
import FilesDialog from '../Model/Dialog/FilesDialog'
import { SearchOutlined } from '@ant-design/icons'
import Dialog from '../Model/Dialog/dialog'

const White: FC = () => {
  const { TabPane } = Tabs
  const [total, setTotal] = useState<number>(0)
  const [searchName, setSearchName] = useState<string>('')
  const [searchGroup, setSearchGroup] = useState<string>('')
  const [searchWeight, setSearchWeight] = useState<number>()
  const [groupList, setGroupList] = useState<Group[]>()
  const [resourceId, setResourceId] = useState<number>()
  const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>([])
  const [policyList, setPolicyList] = useState<{ id: number, name: string }[]>([])
  const [size, setSize] = useState<number>(20)
  const [page, setPage] = useState<number>(1)
  const [addSupplierIsOpen, setAddSupplierIsOpen] = useState<boolean>(false)
  const [filesDialogIsOpen, setFilesDialogIsOpen] = useState<boolean>(false)
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])
  const [render, setRender] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any[]>()
  const [inputPayload, setInputPayload] = useState<{
    searchName?: string,
    searchGroup?: string,
    searchWeight?: number,
  }>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      setResourceList(data)
      data && data.length && setResourceId(Number(data[0].id))
      const { groups } = await supplier.getGroupList()
      setGroupList(groups)
    })()
  }, [])

  useEffect(() => {
    resourceId && (async () => {
      setLoading(true)
      setPolicyList([])
      const data = await supplier.getWhiteList({
        supplier_name: inputPayload?.searchName,
        group_name: inputPayload?.searchGroup,
        weight: inputPayload?.searchWeight,
        resource_id: resourceId,
        size,
        page,
        auth: true
      })
      const list = await (await policy.getList(resourceId, { page: 1, size: 100 })).data
      setPolicyList(list as unknown as { id: number, name: string }[])
      setLoading(false)
      setData(handleData(data.data))
      setTotal(data.total)
    })()
  }, [render, page, size, inputPayload, resourceId])

  useEffect(() => {
    setPage(1)
  }, [size])

  const handleData = useCallback((data: SupplierList[]) => {
    if (!data || !data.length) return []
    return data.map(item => ({
      key: item.id,
      name: item.name,
      group: item.group,
      weight: item.weight,
      phone: item.phone,
      create_time: item.create_time,
      handle: <div>
        <Link to={`/supplier/detail/${item.id}`}>查看详情</Link>
      </div>
    }))
  }, [])

  const handleClickSearch = useCallback(() => {
    setInputPayload({
      searchName,
      searchGroup,
      searchWeight
    })
  }, [searchName, searchGroup, searchWeight])

  const handleClickReset = useCallback(() => {
    setInputPayload({
      searchName: void 0,
      searchGroup: void 0,
      searchWeight: void 0,
    })
    setPage(1)
    setSize(20)
  }, [])

  const handleClickEditSort = useCallback(async (id) => {
    try {
      await supplier.editSort({
        suppliers: selectRowIdList.length ? selectRowIdList.map(item => item.toString()) : [],
        group_id: id
      })
      message.success("修改成功")
      setIsOpen(false)
      setRender(render + 1)
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [selectRowIdList, render])

  return (
    <React.Fragment>
      <Block loading={loading}>
        <Row>
          <Button type='primary' onClick={() => { setFilesDialogIsOpen(true) }}>导入供应商列表</Button>
          <Button style={{ marginLeft: '8px' }} onClick={() => { setAddSupplierIsOpen(true) }}>添加供应商</Button>
        </Row>
        <Row>
          <Tabs onChange={(value) => { setResourceId(Number(value)) }} type="card">
            {resourceList.map(item => <TabPane tab={item.name} key={item.id} />)}
          </Tabs>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col style={{ justifyContent: 'space-between', display: 'flex', width: 800 }}>
            <InputBox title="名称">
              <Input
                value={searchName}
                style={{ width: '200px' }}
                placeholder='请输入'
                prefix={<SearchOutlined />}
                onChange={(e) => { setSearchName(e.target.value) }}
              />
            </InputBox>
            <InputBox title="策略分组">
              <Input
                value={searchGroup}
                style={{ width: '200px' }}
                placeholder='请输入'
                prefix={<SearchOutlined />}
                onChange={(e) => { setSearchGroup(e.target.value) }}
              /></InputBox>
            <InputBox title="权重">
              <Input
                value={searchWeight}
                style={{ width: '200px' }}
                placeholder='请输入'
                prefix={<SearchOutlined />}
                onChange={(e) => { setSearchWeight(Number(e.target.value)) }}
              /></InputBox>
          </Col>
          <Col style={{ justifyContent: 'space-between', display: 'flex' }}>
            <Button
              style={{ width: 65, marginLeft: '8px' }}
              type="primary"
              onClick={handleClickSearch}
            >
              查询
            </Button>
            <Button
              style={{ width: 65, marginLeft: '8px' }}
              onClick={handleClickReset}
            >
              重置
            </Button>
            <Button onClick={() => setIsOpen(true)} style={{ width: 85, marginLeft: '8px' }}>更变分组</Button>
          </Col>
        </Row>
        <Table
          dataSource={data}
          columns={whiteColumns}
          rowSelection={{
            selectedRowKeys: selectRowIdList,
            onChange: selectedRowKeys => { setSelectRowIdList(selectedRowKeys) }
          }}
          style={{ marginTop: '24px' }}
          pagination={{
            total: total,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize: size,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (_page, _size) => {
              if (_page !== page) setPage(_page)
              if (_size !== size) setSize(_size!)
            },
          }}
        />
      </Block >
      <AddSupplierDialog
        isOpen={addSupplierIsOpen}
        groupList={groupList}
        onClose={() => { setAddSupplierIsOpen(false) }}
        onSubmit={() => {
          setRender(render + 1)
        }}
      />
      <FilesDialog
        isOpen={filesDialogIsOpen}
        onClose={() => { setFilesDialogIsOpen(false) }}
        onSubmit={() => { setRender(render + 1) }}
      />
      <Dialog
        resourceId={resourceId}
        isOpen={isOpen}
        list={policyList}
        onSubmit={handleClickEditSort}
        onClose={() => setIsOpen(false)}
      />
    </React.Fragment >
  )
}

export default White
