/*
 * File: /src/models/decoration.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Thursday 2020-12-10 09:54:57
 * Last Modified: Thursday 2020-12-10 09:54:57
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import { createModel } from '@rematch/core'
import { IndexStyle } from 'types/decoration'

interface State {
  indexStyle: IndexStyle | null,
  activeComponent?: string
}

export const decoration = createModel({
  state: {
    indexStyle: null,
  } as State,
  reducers: {
    setIndexStyle: (state, payload: IndexStyle): State => ({
      ...state,
      indexStyle: payload
    }),
    setActiveComponent: (state, payload: string): State => ({
      ...state,
      activeComponent: payload
    })
  }
})
