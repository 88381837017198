/*
 * @Author: your name
 * @Date: 2020-12-16 14:30:52
 * @LastEditTime: 2020-12-17 16:05:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/announcement.ts
 */
import { get, post } from "../utils/http/http"

const baseURL = "/Admin/reserve_notice/"

export default {
  //系统预约须知-获取
  getSystem: () => get<SystemPayloadType>(`${baseURL}system_notice`),
  //系统预约须知-修改
  editSystem: (payload: SystemPayloadType) => post<null>(`${baseURL}system_notice`, payload),
  //预约项目预约须知-列表
  getPrivateList: () => get<PrivateResult>(`${baseURL}notice_resource`),
  //预约项目预约须知-添加
  addPrivateCard: (payload: { id: number }) => post<null>(`${baseURL}notice_resource`, payload),
  //预约项目预约须知-获取
  getPrivate: (payload: { id: number }) => get<PrivateDetailResult>(`${baseURL}private_notice/${payload.id}`),
  //预约项目预约须知-修改
  editPrivate: (payload: PrivateDetailPayload) => post<null>(`${baseURL}private_notice/${payload.id}`,payload),
  //签署记录-获取
  getHistory: (payload: { keyword: string, page: number, size: number }) => get<HistoryType>(`${baseURL}sign_list`, payload)
}

interface HistoryType {
  total: number,
  list: HistoryItem[]
}

export interface HistoryItem {
  username: string,
  phone: string,
  create_time: number,
  resource_name: string,
  register_time: string,
}

export interface SystemPayloadType {
  is_used: boolean,
  rule: string,
  space: number,
  content: string,
}

interface PrivateResult {
  unregistered: PrivateCard[]
  registered: PrivateCard[]
}

export interface PrivateCard {
  id: number,
  title: string,
  description: string,
  img_url: string,
}

export interface PrivateDetailResult {
  is_used: boolean,
  rule: string,
  space: number,
  display: string[],
  content: string,
}

export interface PrivateDetailPayload {
  id: number,
  is_used: boolean,
  rule: string,
  space: number,
  display: string[],
  content: string,
}





