import React, { FC, useState, useCallback, useEffect } from 'react'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Table, Button, message, Input, Form, Spin, Select, Modal, Popconfirm, Col, Row, Typography } from 'antd'
import services from 'services'
import { Block } from 'components'
import { Car } from 'types/car'
import XLSX, { exportExcel } from 'components/XLSX'
import { debounce, mapKeys } from 'utils/common'
import { OSS_EXCEL } from 'consts/url'

export const columns = [{
  key: 'lpn',
  dataIndex: 'lpn',
  title: '车牌号'
}, {
  key: '供应商名称',
  dataIndex: 'supplier',
  title: 'supplier'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]

let debounced: (...args: any[]) => any

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [list, setList] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [suppliers, setSuppliers] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [fetching, setFetching] = useState<boolean>(false)
  const [mode, setMode] = useState<'create' | 'import'>()
  const [cars, setCars] = useState<Car[]>([])
  const [keyword, setKeyword] = useState<string>()
  const [form] = Form.useForm()

  const handleData = useCallback((data: Car[]) => {
    return data.map((item, index) => ({
      key: index,
      lpn: item.lpn,
      supplier: item.supplier,
      operation:
        <Popconfirm
          title="你确定要删除吗？"
          okText="确定"
          cancelText="取消"
          onConfirm={() => handleDelete(item.id)}
        >
          <Button type="link">删除</Button>
        </Popconfirm>
    }))
  }, [])

  // 加载车辆列表
  const loadList = useCallback(() => {
    services.car.getList({
      page,
      size: pageSize,
      q: keyword
    })
      .then(data => {
        setList(handleData(data.data))
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取车辆列表失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, pageSize, keyword])

  useEffect(loadList, [page, pageSize, keyword])

  // 获取供应商列表
  const fetchData = useCallback(async (value) => {
    if (!debounced) debounced = debounce(services.user.getSupplierList, 800)
    setFetching(true)
    try {
      const data = await debounced({ q: value })
      setSuppliers(data.suppliers)
    } finally {
      setFetching(false)
    }
  }, [])

  const handleSubmit = useCallback(async () => {
    let postData: Car[] = []
    if (mode === 'import') {  // 批量导入
      postData = cars
    } else {  // 单个创建
      try {
        const data = await form.validateFields()
        postData = [data] as Car[]
      } catch (error) {
        message.warning('请正确填写表单')
        return
      }
    }

    setLoading(true)
    services.car.create({ cars: postData })
      .then(() => {
        message.success('车牌号添加成功！')
      })
      .catch(error => {
        const failList = error.response.data?.data?.fail_list
        if (failList) {
          const mapping = {lpn: '车牌号（必填）', supplier: '供应商（必填）',fail_reason: '失败原因'}
          exportExcel(mapKeys(failList, mapping), '错误报告-车辆白名单导入')
          message.error('部分数据导入失败，错误报告已下载，请更改并重新上传')
        } else {
          message.error('导入失败~')
        }
      })
      .finally(() => {
        setMode(undefined)
        setTimeout(loadList, 300)
        setLoading(false)
      })
  }, [cars, mode])

  const handleDelete = useCallback((id) => {
    const hide = message.loading("正在删除...", 0)
    services.car.delete(id)
      .then(() => {
        setLoading(true)
        message.success('删除车辆成功！')
        loadList()
      })
      .catch(() => {
        message.success('删除车辆失败~')
      })
      .finally(() => {
        hide()
      })
  }, [])

  return (
    <Block
      loading={loading}
      style={{ paddingTop: '32px' }}
    >
      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setMode('import')}>
            <PlusOutlined />导入车辆列表
          </Button>
          <Button style={{ marginLeft: '15px' }} onClick={() => setMode('create')}>添加车牌号</Button>
        </Col>
        <Col>
          <Input.Search
            placeholder="请输入关键字"
            onSearch={keyword => setKeyword(keyword)}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={loading}
        dataSource={list}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _pageSize) => {
            if (_page !== page) setPage(_page)
            if (_pageSize !== pageSize) setPageSize(_pageSize!)
          },
        }}
        style={{ marginTop: 15 }}
      />
      <Modal
        title="添加车牌号"
        visible={mode === 'create'}
        okText="提交"
        cancelText="重置"
        onOk={() => handleSubmit()}
        onCancel={() => setMode(undefined)}
      >
        <Form form={form} validateMessages={{ required: "'${label}' 不能为空" }}>
          <Form.Item name="lpn" label="车牌号" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="supplier" label="供应商名称" rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder="请选择"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={fetchData}
            >
              {suppliers.map(supplier => (
                <Select.Option key={supplier.id} value={supplier.name}>{supplier.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="导入车辆白名单列表"
        visible={mode === 'import'}
        onCancel={() => setMode(undefined)}
        footer={null}
      >
        <XLSX
          mapping={{ 'A': { name: 'lpn' }, 'B': { name: 'supplier' } }}
          onChange={(data) => setCars(data as Car[])}
          type="dragger"
          hasHeader
        />
        <Typography.Text style={{ color: '#929292', display: 'block', margin: '8px auto' }}>
          不知道格式？请查看
          <Typography.Link href={`${OSS_EXCEL}/car.xlsx`} target="_blank">
            车辆白名单导入模板
          </Typography.Link>
        </Typography.Text>
        <Button
          type="primary"
          style={{ display: 'block', margin: '10px auto 0px auto' }}
          onClick={handleSubmit}
        >确定导入</Button>
      </Modal>
    </Block>
  )
}

export default List
