import { InfoCircleOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, message, Row, Select, Tooltip } from "antd"
import { Block } from "components"
import React, { FC, useEffect, useMemo, useState } from "react"
import { daily } from "services"
import { Body, Bottom, Title, Top, Value } from "./style"

/*
 * @Author: your name
 * @Date: 2021-04-19 16:30:56
 * @LastEditTime: 2021-04-19 18:41:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Home/index.tsx
 */
interface BoxType {
  text1: string,
  number1: string,
  text2: string,
  number2: string,
  description: string,
}

const Box: FC<BoxType> = ({ text1, number1, text2, number2, description }) => {

  return (
    <Body>
      <Top>
        <Tooltip placement="topLeft" title={description}>
          <InfoCircleOutlined style={{ position: "absolute", right: 0, top: 2 }} />
        </Tooltip>
        <Title>{text1}</Title>
        <Value>{number1}</Value>
      </Top>
      <Bottom>
        <Title>{text2}</Title>
        <Value>{number2}</Value>
      </Bottom>
    </Body>
  )
}

export default Box