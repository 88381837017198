import React, { FC, useState, useEffect } from 'react'
import { Block } from 'components'
import { announcement } from 'services'
import { List } from './style'
import Card from 'components/Card'
import Add from '../../Model/Add'
import Dialog from '../../Model/Add/dialog'
import { PrivateCard } from 'services/announcement'
import { Link } from 'react-router-dom'

const PrivateList: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [cardList, setCardList] = useState<PrivateCard[]>()
  const [addList, setAddList] = useState<PrivateCard[]>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [render, setRender] = useState<number>(0)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await announcement.getPrivateList()
      setLoading(false)
      setCardList(data.registered)
      setAddList(data.unregistered)
    })()
  }, [render])

  return (
    <React.Fragment>
      <Block
        title="设置列表"
        loading={loading}
      />
      <List>
        <Add
          onClick={() => setIsOpen(true)}
        />
        {cardList && cardList.length ? cardList.map(item =>
          <Link to={`/announcement/private_detail/${item.id}`}>
            <Card
              key={item.id}
              name={item.title}
              description={item.description}
              img_url={item.img_url}
            />
          </Link>
        ) : null
        }
      </List>
      <Dialog
        isOpen={isOpen}
        list={addList}
        onSubmit={() => {
          setIsOpen(false)
          setRender(render + 1)
        }}
        onClose={() => setIsOpen(false)}
      />
    </React.Fragment>
  )
}

export default PrivateList