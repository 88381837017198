import React, { FC, useCallback, useState } from 'react'
import { Form, Input, message, Modal, Select, Spin } from 'antd'
import services from 'services'
import { debounce } from 'utils/common'

interface Props {
  visible: boolean,
  onCancel: () => void,
  onOk: () => void
}

let debounced: (...args: any[]) => any

const AddUser: FC<Props> = (props) => {
  const { visible, onCancel, onOk } = props
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)
  const [suppliers, setSuppliers] = useState<QueryResult>([])

  const handleCancel = useCallback(() => {
    form.resetFields()
    onCancel()
  }, [])

  const handleOk = useCallback(() => {
    form.validateFields()
      .then((data) => {
        setConfirmLoading(true)
        services.user.add({users: [{
          username: data.name,
          phone: data.phone,
          supplier: data.supplier,
          email: null,
          password: '654321',
        }]})
          .then(() => {
            message.success(`用户“${data.name}”添加成功`)
            onOk()
            handleCancel()
          })
          .catch(() => {
            message.error(`用户“${data.name}”添加失败`)
          })
          .finally(() => {
            setConfirmLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单')
      })
  }, [])

  const fetchData = useCallback(async (value) => {
    if (!debounced) {
      debounced = debounce(services.user.getSupplierList, 500)
    }
    setFetching(true)
    try {
      const data = await debounced({q: value})
      setSuppliers(data.suppliers)
    } finally {
      setFetching(false)
    }
  }, [])

  return (
    <Modal
      title="添加用户"
      visible={visible}
      okText="确认"
      cancelText="重置"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        name="item"
        form={form}
        validateMessages={{ required: "'${label}' 不能为空" }}
        preserve={false}
      >
        <Form.Item
          label="用户姓名"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="联系方式"
          name="phone"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="供应商名称"
          name="supplier"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="请选择"
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchData}
            style={{ width: '100%' }}
          >
            {suppliers.map(supplier => (
              <Select.Option key={supplier.id} value={supplier.name}>{supplier.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddUser
