import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block, Header, InputBox } from 'components'
import { BreadcrumbOption } from 'types/antd'
import { Button, Col, Input, message, Row } from 'antd'
import { system } from 'services'
const Weight: FC = () => {
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: '系统配置'
  }, {
    name: '权重配置'
  }])
  const [loading, setLoading] = useState<boolean>(false)
  const [weight, setWeight] = useState<number>()
  const [render, setRender] = useState<number>(0)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const { minutes } = await system.getWeight()
        setWeight(minutes)
      } catch (err) {
        err.response && message.error(err.response.data.message)
      } finally {
        setLoading(false)
      }
    })()
  }, [render])

  const submit = useCallback(async () => {
    try {
      setLoading(true)
      await system.editWeight({ minutes: weight || 0 })
      setRender(render + 1)
      message.success("修改成功")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }, [weight, render])

  return (
    <React.Fragment>
      <Header
        title="权重设置"
        description="配置单位权重对应的时间"
        breadcrumbs={breadcrumbs}
      />
      <Block
        title="权重设置"
        loading={loading}
        description={<Button loading={loading} disabled={loading} onClick={submit}>保存</Button>}
      >
        <InputBox title="单位权重对应时间">
          <Row align="middle">
            <Col style={{ padding: '32px 0px' }}>
              <Input
                min={0}
                type="number"
                value={weight}
                onChange={(e) => setWeight(Number(e.target.value))}
                style={{ marginRight: 32 }}
              />
            </Col>
            <Col>分钟</Col>
          </Row>
        </InputBox>
      </Block>
    </React.Fragment>
  )
}

export default Weight