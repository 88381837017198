/*
 * File: /src/pages/System/View/Decoration/Tabbar.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 10:02:08
 * Last Modified: Wednesday 2020-12-09 10:02:08
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, message, Row, Table, Typography } from 'antd'
import { Block } from 'components'
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import services from 'services'
import { IndexStyle } from 'types/decoration'
import Head from '../Head'

const columns = [{
  key: 'name',
  dataIndex: 'name',
  title: '导航栏'
}, {
  key: 'icon',
  dataIndex: 'icon',
  title: '默认图标'
}, {
  key: 'link',
  dataIndex: 'link',
  title: '跳转链接'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]

const Tabbar: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [list, setList] = useState<AnyObject[]>([])

  const handleData = useCallback((data: IndexStyle) => {
    return data.tabbar.tabs.map(tab => ({
      name: tab.name,
      link: tab.link,
      icon: tab.icon,
      operation: <Link to="/system/decoration/home">去装修</Link>
    }))
  }, [])

  useEffect(() => {
    services.common.getConfig({configs_need: ['index_style']})
      .then(data => {        
        setList(handleData(data.index_style))
      })
      .catch(() => {
        message.error('获取配置失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Fragment>
      <Head />
      <Block loading={loading} style={{padding: 30}}>
        <Row>
          <Col span={12}>
            <Typography.Title level={5}>导航栏风格装修</Typography.Title>
            <Form.Item label="微信端主题颜色:">
              <Input type="color" style={{ padding: 0, border: 'none', width: 30 }} />
            </Form.Item>
            <Button type="primary"><PlusOutlined />添加导航栏</Button>
            <Typography.Text style={{ color: "rgba(0,0,0,0.45)", fontSize: 14, marginLeft: 8 }}>
              （默认添加在首页和个人中心中间）
          </Typography.Text>
            <Table
              columns={columns}
              dataSource={list}
              style={{ marginTop: 15 }}
              pagination={false}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={5}>页面预览</Typography.Title>
          </Col>
        </Row>
      </Block>
    </Fragment>
  )
}

export default Tabbar
