import React, { FC, useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { BreadcrumbOption, TabOption } from "types/antd"
import { Header } from "components"
import PrivateList from "./PrivateList"
import System from "./System"
import PrivateDetail from "./PrivateDetail"
import History from "./History"

const Announcement: FC = () => {
  const { pathname } = useHistory().location
  const page = pathname.split("/")[2]
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbOption[]>()
  const [tabs] = useState<TabOption[]>([{
    key: "/announcement/system",
    name: "系统预约须知",
    link: "/announcement/system"
  }, {
    key: "/announcement/private_list",
    name: "项目预约须知",
    link: "/announcement/private_list"
  }, {
    key: "/announcement/history",
    name: "签署记录",
    link: "/announcement/history"
  }])
  const [selectTabKey, setSelectTabKey] = useState<string>(page === "private_detail" ? "/announcement/private_list" : pathname)
  console.log(page)
  useEffect(() => {
    console.log(selectTabKey)
    setBreadcrumbs([{
      name: "预约实况"
    }, {
      name: tabs.filter(item => item.key === selectTabKey)[0].name
    }
    ])
  }, [selectTabKey])

  return (
    <div>
      {pathname === "/announcement/private_detail" ? null :
        <Header
          title="预约实况"
          description=""
          breadcrumbs={breadcrumbs}
          tabs={tabs}
          selectTabKey={selectTabKey}
          onTabClick={(key) => { setSelectTabKey(key) }}
        />}
      <Route path='/announcement/system' render={() => <System />} />
      <Route path='/announcement/private_list' render={() => <PrivateList />} />
      <Route path='/announcement/private_detail/:id' render={() => <PrivateDetail />} />
      <Route path='/announcement/history' render={() => <History />} />
    </div>
  )
}

export default Announcement