import React, { FC, useCallback, useState, useEffect } from 'react'
import { Upload, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import axios from 'axios'
import { OSS_SIGN } from 'consts/url'
import { getRandomString } from 'utils/common'
import { CSSProperties } from 'styled-components'

interface Config {
  accessid: string,
  host: string,
  policy: string,
  signature: string,
  expire: number,
  callback: string,
  dir: string
}

interface ExtraData {
  key: string,
  OSSAccessKeyId: string,
  policy: string,
  signature: string,
  success_action_status: '200',
  callback: string,
}

let uploading: any

interface Props {
  accept?: string,
  listType?: 'text' | 'picture' | 'picture-card',
  showUploadList?: boolean,
  onUploaded?: (key: string) => void
  imgUrl?: string,
  crop?: boolean,
  fullpath?: boolean,
  disabled?: boolean,
  style?: CSSProperties
}
const OSSUpload: FC<Props> = (props) => {
  const { accept = 'image/jpeg, image/png', listType = 'text', imgUrl,
    showUploadList = true, crop = true, disabled = false, style
  } = props

  const [config, setConfig] = useState<Config>()
  const [extraData, setExtraData] = useState<ExtraData>()

  const getSignature = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      axios.get<Config>(OSS_SIGN)
        .then(({ data }) => {
          setConfig(data)
          const filename = `oss-${Date.now()}-${getRandomString()}.jpg`
          const key = data.dir + filename
          setExtraData({
            key: key,
            OSSAccessKeyId: data.accessid,
            policy: data.policy,
            signature: data.signature,
            success_action_status: '200',
            callback: data.callback,
          })
          resolve()
        })
        .catch(() => {
          message.error('OSS文件上传:从服务器获取签名失败~')
          reject()
        })
    })
  }, [])

  const beforeUpload = useCallback(() => {
    uploading = message.loading('上传中...')
    return (!config || Date.now() > config.expire * 1000) || getSignature()
  }, [config])

  const transformFile = useCallback(file => {
    if (!config || !extraData) return
    const filename = `oss-${Date.now()}-${getRandomString()}.jpg`
    extraData.key = config.dir + filename
    return file
  }, [config, extraData])

  const handleChange = useCallback((info: any) => {
    if (!config || !extraData) return
    if (info.file.status === 'done') {
      uploading && uploading()
      const url = props.fullpath ? `${config.host}/${extraData.key}` : extraData.key.replace(config.dir, '')
      props.onUploaded && props.onUploaded(url)
    }
  }, [config, extraData])

  useEffect(() => { getSignature() }, [])

  const renderUploader = useCallback(() => {
    return (
      <Upload
        action={config?.host}
        data={extraData}
        transformFile={transformFile}
        beforeUpload={beforeUpload}
        accept={accept}
        listType={listType}
        showUploadList={showUploadList}
        onChange={handleChange}
        disabled={disabled}
        style={style}
      >
        {imgUrl ?
          <img
            src={imgUrl.startsWith('http') ? imgUrl : `${config?.host}/${config?.dir}${imgUrl}`}
            style={{ width: '100%' }}
          /> : (props.children ? props.children : <div><PlusOutlined />上传</div>)
        }
      </Upload>
    )
  }, [config, props])

  return crop ? (
    <ImgCrop modalTitle="裁剪图片" modalCancel="取消" modalOk="确定">
      {renderUploader()}
    </ImgCrop>
  ) : renderUploader()
}

export default OSSUpload
