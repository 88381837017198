import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { daily } from 'services'
import { Picker, List as AtList, Toast } from 'antd-mobile'
import { PickerData } from 'antd-mobile/lib/picker/PropsType'
import {
  Body, Time, TimeList, TableBody, Table, Line, Space, WeekList, Week, TimeListSpace,
  DateTime, DateBox, DateBody, LineBorder, WeekListFixed
} from './style'
import { Button, DatePicker } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { FormatDay, FormatDayChinese, getEndTimeFromWeek, getWeekDay, handleDateToWeek, handleWeekStartDate } from 'utils/time'
import moment from 'moment'
import { weekItem } from 'types/time'
import { TimeFragment } from 'services/dilay'
import Box from '../Model/Box'

const List: FC = () => {
  const now = new Date()
  const { Item } = AtList
  let time: any = null
  const dateRef = useRef(null) //控制DatePicker实例
  const weekRef = useRef(null) //控制DatePicker实例
  const tableRef = useRef(null) //控制DatePicker实例
  const bodyRef = useRef<HTMLInputElement>(null) //控制Body实例
  const [date, setDate] = useState<Date>(now)
  const [weekList, setWeekList] = useState<weekItem[]>([])
  const [resourceList, setResourceList] = useState<PickerData[]>([])
  const [select, setSelect] = useState<number[]>()
  const [isOpen, setIsOpen] = useState<boolean>(false) //控制DatePicker开关
  const [week, setWeek] = useState<string>(`${now.getFullYear()}-${handleDateToWeek(now)}`)
  const [loading, setLoading] = useState<boolean>(false)
  const [timeList, setTimeList] = useState<TimeFragment[]>()
  const [startTime, setStartTime] = useState<Date>(
    new Date(handleWeekStartDate(parseInt(week.split('-')[0]), parseInt(week.split('-')[1])))
  )
  const [endTime, setEndTime] = useState<Date>(
    new Date(new Date(handleWeekStartDate(parseInt(week.split('-')[0]), parseInt(week.split('-')[1]))).getTime()
      + (24 * 60 * 60 * 1000 * 6))
  )
  const [isMask, setIsMask] = useState<boolean>(false)

  useEffect(() => {
    if(!bodyRef) return
    const body = bodyRef.current
    body!.scrollTop = 784
  }, [bodyRef])

  useEffect(() => {
    (async () => {
      const data = await daily.getResourceList()
      setResourceList(data.map(item => ({ label: item.name, value: item.id })))
    })()
  }, [])

  useEffect(() => {
    if (!select?.length) {
      setTimeList([])
      return
    }
    (async () => {
      try {
        setLoading(true)
        const timeList = await daily.getWeekFragmentList({
          date: FormatDay(new Date(date.getTime() + (24 * 60 * 60 * 1000))),
          resource_id: select[0].toString()
        })
        const result: TimeFragment[] = []
        for (let index in timeList) {
          timeList[index].length && timeList[index].forEach(item => {
            item.date = index
            result.push(item)
          })
        }
        setTimeList(result)

        if (result.length) {
          let top = 2300
          result.forEach(item => {
            const time = Number(item.time.split("-")[0].split(":")[0])
            if (time * 98 < top) {
              top = time * 98
            }
          })
          setScrollTop(top)
        }

      } catch (err) {
        Toast.fail(err.response.data.message, 1)
        setTimeList([])
      } finally {
        setLoading(false)
      }
    })()
  }, [date, select])

  const setScrollTop = useCallback((top: number) => {
    const body = bodyRef.current
    const reserve = !(body!.scrollTop > top)
    const time = setInterval(() => {
      if (reserve) {
        if (!!(body!.scrollTop < top)) {
          body!.scrollTop += 40
        } else {
          clearInterval(time)
        }
      } else {
        if (!!(body!.scrollTop > top)) {
          body!.scrollTop -= 40
        } else {
          clearInterval(time)
        }
      }
    }, 30)
  }, [])

  const renderTimeList = useCallback(() => {
    const result = []
    for (let index = 0; index <= 24; index++) {
      result.push(<Time key={'time' + index}>{index < 10 ? `0${index}` : index}:00</Time>)
    }
    return result
  }, [])

  const renderLine = useCallback(() => {
    const result = []
    for (let index = 0; index <= 24; index++) {
      result.push(<Line />)
    }
    return <LineBorder>{result}</LineBorder>
  }, [])

  //点击日期左右箭头
  const handleClickAllow = useCallback((point: string) => {
    const startDate = new Date(handleWeekStartDate(parseInt(week.split("-")[0]), parseInt(week.split("-")[1])))
    const endDate = new Date(getEndTimeFromWeek(startDate))
    const result = point === "left" ? new Date(startDate.getTime()) : new Date(endDate.getTime() + 86400000 * 3)
    setWeek(`${result.getFullYear()}-${handleDateToWeek(result)}`)
  }, [week])

  //点击日期
  const handleClickDate = useCallback(() => {
    setIsOpen(true)
    const dom = dateRef.current as unknown as HTMLElement
    dom.focus()
  }, [dateRef])

  //切换星期
  useEffect(() => {
    if (week) {
      const startDate = new Date(handleWeekStartDate(parseInt(week.split("-")[0]), parseInt(week.split("-")[1])).replace(/-/g, "/"))
      const tempWeekList = []
      for (let index = 0; index < 7; index++) {
        const date = new Date(startDate.getTime() + (24 * 60 * 60 * 1000 * index))
        tempWeekList.push({
          year: date.getFullYear().toString(),
          month: (date.getMonth() + 1).toString(),
          day: date.getDate().toString(),
          week: getWeekDay(date.getDay()),
          isToady: !!(FormatDay(date) === FormatDay(now))
        })
      }
      setStartTime(startDate)
      setEndTime(getEndTimeFromWeek(startDate))
      setWeekList(tempWeekList)
      setDate(startDate)
    }
  }, [week])

  //点击今天
  const handleClickToday = useCallback(() => {
    setWeek(`${now.getFullYear()}-${handleDateToWeek(now)}`)
  }, [])

  if (!isMask) {
    setIsMask(true)
    time = setInterval(() => {
      const week = weekRef.current as unknown as HTMLElement
      if (week && week.style) { week.style.left = `${-((tableRef.current as unknown as HTMLElement)?.scrollLeft - 45)}px` }
    }, 20)
  }

  useEffect(() => {
    return () => {
      clearInterval(time)
    }
  }, [])

  return (
    <>
      <div style={{ position: "fixed", top: 0, width: "100%", background: "white", zIndex: 900 }}>
        <AtList>
          <Picker
            data={resourceList}
            value={select}
            onOk={(value) => setSelect(value)}
            cols={1}
          >
            <Item arrow="horizontal">预约对象</Item>
          </Picker>
          <Space />
          <Item extra={<Button onClick={handleClickToday}>今天</Button>}>选择日期</Item>
        </AtList>
        <div>
          <DatePicker
            ref={dateRef}
            value={moment(date)}
            open={isOpen}
            onChange={(_value, string) => {
              setWeek(string)
              setIsOpen(false)
            }}
            picker="week"
            style={{
              position: "absolute",
              height: 20,
              left: "54%",
              top: 120,
              zIndex: 2,
              transform: "translateY(-50%)"
            }}
            onBlur={() => { setIsOpen(false) }}
          />
          <DateBody>
            <LeftOutlined
              color="#ccc"
              style={{ fontSize: "9px", marginLeft: "16px", cursor: "pointer" }}
              onClick={() => { handleClickAllow("left") }}
            />
            <DateBox onClick={handleClickDate}>
              <DateTime>{FormatDayChinese(startTime)}</DateTime>
              <div>-</div>
              <DateTime>{FormatDayChinese(endTime)}</DateTime>
            </DateBox>
            <RightOutlined
              color="#ccc"
              style={{ fontSize: "9px", cursor: "pointer" }}
              onClick={() => { handleClickAllow("right") }}
            />
          </DateBody>
        </div>
      </div>
      <div ref={bodyRef} style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        borderTop: "1px solid white",
        top: 0,
        left: 0,
        overflowY: "scroll",
      }}>
        <Body>
          <TimeList>
            {renderTimeList()}
          </TimeList>
          <TableBody ref={tableRef}>
            <WeekListFixed ref={weekRef}>
              <Week>日</Week>
              <Week>一</Week>
              <Week>二</Week>
              <Week>三</Week>
              <Week>四</Week>
              <Week>五</Week>
              <Week>六</Week>
            </WeekListFixed>
            <WeekList />
            <Table>
              {renderLine()}
              {timeList?.map(item =>
                <Box
                  data={item}
                  type="time"
                  weekList={weekList}
                />
              )}
            </Table>
          </TableBody>
          <TimeListSpace />
        </Body>
      </div>
    </>
  )
}

export default List
