import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Col, Input, message, Modal, PageHeader, Row, Tag } from 'antd'
import HashItem from 'components/mobile/HashItem'
import Container from 'components/mobile/Container'
import { useParams } from 'react-router-dom'
import services from 'services'
import { DetailResult, Reason } from 'services/order'
import { Box, FormEntry2, Title } from './style'
import { STATUS } from 'consts/order'

function makeReasons(reasons: Reason[]) {
  const groups: AnyObject = {}
  reasons.forEach(reason => {
    if (!groups[reason.type]) groups[reason.type] = []
    groups[reason.type].push(reason)
  })
  return Object.keys(groups).map(key => {
    return { title: key.slice(0, key.lastIndexOf('-')), list: groups[key] }
  })
}

const Detail: FC = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [detail, setDetail] = useState<DetailResult>()
  const [visible, setVisible] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')

  const summary = detail?.order_status
  const logs = detail?.order_status.logs || []
  const reasons = detail?.order_status.reason
  const userInfo = detail?.order_user || {}
  const orderInfos = detail?.order_detail || []
  const customFields = detail?.order_field || []

  // 获取详情
  const loadDetail = useCallback(() => {
    orderId && (async () => {
      setLoading(true)
      services.order.getDetail({ id: orderId })
        .then((data) => {
          setDetail(data)
        })
        .catch(() => {
          message.error('获取订单详情失败')
        })
        .finally(() => {
          setLoading(false)
        })
    })()
  }, [orderId])

  useEffect(loadDetail, [])

  const handleMoveUp = useCallback(() => {
    if (!summary) return
    setLoading(true)
    const action = STATUS[summary.status].moveUpText
    services.order.action({ id: [summary.id], action, payload: { content } })
      .then(() => {
        setVisible(false)
        message.success(`${action}成功`)
        loadDetail()
      })
      .catch(() => {
        message.error(`${action}失败`)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [summary, content])

  const handleMoveDown = useCallback(() => {
    if (!summary) return
    if (summary.status === '待审核') {
      window.location.href = `/order/queue/${summary.id}`
      return
    }
    setLoading(true)
    const action = STATUS[summary.status].moveDownText
    services.order.action({ id: [summary.id], action, payload: {} })
      .then(() => {
        message.success(`${action}成功`)
        loadDetail()
      })
      .catch(() => {
        message.error(`${action}失败`)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [summary])

  const handleShuang = useCallback(() => {
    if (!summary) return
    setLoading(true)
    services.order.action({ id: [summary.id], action: '爽约', payload: {} })
      .then(() => {
        message.success('爽约成功')
        loadDetail()
      })
      .catch(() => {
        message.error('爽约失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [summary])

  return (
    <Container loading={loading} bgColor="F5F6F8">
      <PageHeader
        title="订单详情"
        onBack={() => window.history.back()}
        style={{
          background: '#FFFFFF',
          padding: '6px 20px',
          borderBottom: '1px solid #EEE'
        }}
      />
      <Box>
        <Title>
          {summary?.supplier_type}-{summary?.subject}
          <Tag color={summary?.color} style={{ marginLeft: '10px' }}>{summary?.status}</Tag>
        </Title>
        <HashItem name="订单编号" colw={8}>{summary?.id}</HashItem>
        {logs.map(log => <HashItem name={log.title} colw={8}>{log.content}</HashItem>)}
      </Box>
      {reasons && !!reasons.length &&
        <Box>
          <Title>拒收原因</Title>
          {makeReasons(reasons).map(reason =>
            <Fragment>
              <FormEntry2>{reason.title}</FormEntry2>
              {reason.list.map((item: any) =>
                <HashItem name={item.name} colw={8}>{item.value}</HashItem>
              )}
            </Fragment>
          )}
        </Box>
      }
      <Box>
        <Title>用户信息</Title>
        {Object.keys(userInfo).map(key => <HashItem name={key} colw={7}>{userInfo[key]}</HashItem>)}
      </Box>
      {
        orderInfos.map(orderInfo =>
          <Box>
            <Title>订单信息</Title>
            <HashItem name="预约日期">{orderInfo.date}</HashItem>
            <HashItem name="时段">{orderInfo.lesson}</HashItem>
            <HashItem name="日期（周）">{orderInfo.week}</HashItem>
            <HashItem name="预约项目">{orderInfo.name}</HashItem>
            <HashItem name="状态">{orderInfo.state}</HashItem>
            <HashItem name="排队序号">{orderInfo.sequence_number}</HashItem>
          </Box>
        )
      }
      <Box>
        <Title>自定义订单信息</Title>
        {customFields.map(field => <HashItem name={field.name}>{field.field_content}</HashItem>)}
      </Box>
      {
        STATUS[summary?.status || ''] &&
        <Box>
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col span={summary?.status === '已签到' ? 8 : 12} style={{ textAlign: 'center' }}>
              <Button
                style={{ width: '86%' }}
                size="large"
                onClick={summary?.status === '已签到'
                  ? () => window.location.href = `/order/refuse/${orderId}` : () => setVisible(true)
                }
              >
                {STATUS[summary?.status || '']?.moveUpText}
              </Button>
            </Col>
            {summary?.status === '已签到' &&
              <Col span={8} style={{ textAlign: 'center' }}>
                <Button style={{ width: '86%' }} size="large" onClick={handleShuang}>
                  爽约
              </Button>
              </Col>
            }
            <Col span={summary?.status === '已签到' ? 8 : 12} style={{ textAlign: 'center' }}>
              <Button style={{ width: '86%' }} size="large" type="primary" onClick={handleMoveDown}>
                {STATUS[summary?.status || '']?.moveDownText}
              </Button>
            </Col>
          </Row>
        </Box>
      }
      <Modal
        visible={visible}
        title={`${STATUS[summary?.status || '']?.moveUpText}原因`}
        okText="确认"
        cancelText="取消"
        onCancel={() => setVisible(false)}
        onOk={handleMoveUp}
      >
        <Input.TextArea
          value={content}
          placeholder={`请输入${STATUS[summary?.status || '']?.moveUpText}的原因`}
          onChange={(e) => setContent(e.target.value)}
        />
      </Modal>
    </Container>
  )
}

export default Detail
