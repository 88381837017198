import React, { FC, useState, useCallback, useEffect } from "react"
import { message, Modal, Select } from "antd"
import { announcement, policy } from "services"
import { InputBox } from "components"
import { CardList } from "services/time"

interface DialogType {
  list?: { id: number, name: string }[],
  isOpen: boolean,
  onSubmit: () => void
  onClose: () => void
}

const Dialog: FC<DialogType> = (props) => {
  const { Option } = Select
  const [option, setOption] = useState<number>()

  const handleClickSubmit = useCallback(() => {
    if (!option) {
    } else {
      (async () => {
        try {
          await policy.addSort({ resource_id: option })
          props.onSubmit()
        } catch (err) {
          err.response && message.error(err.response.data.message)
        }
      })()
    }
  }, [option])

  return (
    <Modal
      title='新增配置'
      visible={props.isOpen}
      onCancel={props.onClose}
      onOk={handleClickSubmit}
    >
      <InputBox style={{ display: 'flex', justifyContent: 'center' }} title='选择预约项目'>
        <Select onChange={(value) => { setOption(value as number) }} style={{ width: 200 }} placeholder='请选择预约对象'>
          {props.list && props.list.length ? props.list.map(item =>
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ) : null
          }
        </Select>
      </InputBox>
    </Modal>
  )
}

export default Dialog