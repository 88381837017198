import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Block } from 'components'
import { Avatar, Button, Col, Descriptions, Form, Input, message, Row, Select, Space, Spin, Statistic, Table } from 'antd'
import { Link, useParams } from 'react-router-dom'
import services, { item } from 'services'
import { UserOutlined } from '@ant-design/icons'
import { Record } from 'types/user'
import { recordColumns } from 'consts/user'
import { debounce } from 'utils/common'

let debounced: (...args: any[]) => any

const Detail: FC = () => {
    const params: { id: string } = useParams()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(true)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [fetching, setFetching] = useState<boolean>(false)
    const [suppliers, setSuppliers] = useState<any[]>([])
    const [detail, setDetail] = useState<any>()
    const [records, setRecords] = useState<Record[]>([])
    const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>([])

    useEffect(() => {
        (async () => {
            const data = await item.getSummaryList()
            setResourceList(data)
        })()
    }, [])

    const loadDetail = useCallback(async () => {
        setLoading(true)
        try {
            const data = await services.user.getDetail(params.id)
            setDetail(data)
            form.setFieldsValue({
                phone: data.phone,
                supplier: data.supplier
            })
        } catch (error) {
            message.error('获取用户详情失败')
        } finally {
            setLoading(false)
        }
    }, [params.id, form])

    // 获取用户详情
    useEffect(() => {
        !isEdit && loadDetail()
    }, [isEdit, loadDetail, resourceList])

    // 获取用户预约记录
    useEffect(() => {
        services.user.getRecords(params.id, { page: 1 })
            .then((data) => {
                const records = data.data.map(record => ({
                    id: record.id,
                    created: record.created,
                    status: record.status,
                    subject: record.subject,
                    operation: <Link to={`/order/detail/${record.id}`}>查看详情</Link>
                }))
                setRecords(records)
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false)
            })
    }, [params.id])

    // 获取供应商数据
    const fetchData = useCallback(async (value) => {
        if (!debounced) {
            debounced = debounce(services.user.getSupplierList, 800)
        }
        setFetching(true)
        try {
            const data = await debounced({ q: value })
            setSuppliers(data.suppliers)
        } finally {
            setFetching(false)
        }
    }, [])

    const handleDelete = useCallback(async () => {
        if (!detail?.id) return
        setLoading(true)
        try {
            await services.user.delete({ id: detail.id })
            window.history.back()
        } catch (error) {
            message.error(`删除用户 ${detail.name} 失败`)
        } finally {
            setLoading(false)
        }
    }, [detail])

    const handleUpdate = useCallback(async () => {
        setLoading(true)
        try {
            const data = await form.validateFields()
            await services.user.update({
                id: params.id,
                user_name: detail?.name,
                phone: data.phone,
                supplier: data.supplier
            })
            message.success('更新用户信息成功')
        } catch (error) {
            message.error('更新用户信息失败')
        } finally {
            setIsEdit(false)
            setLoading(false)
        }
    }, [params.id, form, detail])

    return (
        <Fragment>
            <Block
                loading={loading}
                title="用户详情"
                showBack
                description={
                    <Space>
                        {/* <Button onClick={handleDelete}>删除用户</Button> */}
                        {/* <Button>添加预约</Button> */}
                        <Button type="primary" onClick={isEdit ? handleUpdate : () => setIsEdit(true)}>
                            {isEdit ? '保存' : '编辑'}
                        </Button>
                    </Space>
                }
                style={{ padding: '16px 32px' }}
            >
                <Row>
                    <Col span={2}>
                        <Avatar size={64} src={detail?.img_url || ''} icon={<UserOutlined />} />
                    </Col>
                    <Col span={22}>
                        <Form form={form}>
                            <Descriptions title={detail?.name}>
                                <Descriptions.Item label={isEdit ? '' : '联系方式'}>
                                    {isEdit ?
                                        <Form.Item
                                            name="phone"
                                            style={{ margin: '0px' }}
                                            label="联系方式"
                                            rules={[{ required: true, min: 11, max: 11, pattern: /^1[3-9]\d{9}$/ }]}
                                        >
                                            <Input />
                                        </Form.Item> : detail?.phone
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={isEdit ? '' : '所属供应商'}>
                                    {isEdit ?
                                        <Form.Item
                                            name="supplier"
                                            style={{ margin: '0px' }}
                                            label="所属供应商"
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="请选择"
                                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                                filterOption={false}
                                                onSearch={fetchData}
                                                style={{ width: '100%' }}
                                            >
                                                {suppliers.map(supplier => (
                                                    <Select.Option key={supplier.id} value={supplier.name}>{supplier.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item> : detail?.supplier
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="供应商分组">{detail?.group}</Descriptions.Item>
                                <Descriptions.Item label="注册时间">{detail?.register_time}</Descriptions.Item>
                                <Descriptions.Item label="策略类型">{detail?.group_Type}</Descriptions.Item>
                                <Descriptions.Item label="权重类型">{detail?.supplier_weight}</Descriptions.Item>
                            </Descriptions>
                        </Form>
                    </Col>
                </Row>
            </Block>
            <Block title="供应商分组信息" style={{ paddingTop: 16 }}>
                {resourceList && resourceList.length && detail?.groups.map((item: any) =>
                    <Descriptions>
                        <Descriptions.Item label={`${resourceList?.filter(resource => resource.id === Number(item.resource))[0].name}分组`}>
                            {item.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={`${resourceList?.filter(resource => resource.id === Number(item.resource))[0].name}策略类型`}>
                            {item.group_type}
                        </Descriptions.Item>
                    </Descriptions>)
                }
            </Block>
            <Block title="账户信息" loading={loading}>
                <Row>
                    <Col span={8}>
                        <Statistic
                            title="累计预约次数"
                            value={detail?.appointment_all}
                            style={{
                                padding: '16px 0px',
                                textAlign: 'center'
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="本月预约次数"
                            value={detail?.appointment_month}
                            style={{
                                padding: '16px 0px',
                                textAlign: 'center'
                            }}
                        />
                    </Col>
                </Row>
            </Block>
            <Block title="预约记录">
                <Table
                    loading={loading}
                    columns={recordColumns}
                    dataSource={records}
                    style={{ marginTop: '20px' }}
                />
            </Block>
        </Fragment >
    )
}

export default Detail
