/*
 * @Author: your name
 * @Date: 2020-12-07 16:49:22
 * @LastEditTime: 2020-12-16 14:17:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/System/View/ Announcement/style.ts
 */
import styled from 'styled-components'

export const BraftEditorBody = styled.div`
  margin-top: 24px;
  border: 1px solid #e9e9e9;
`

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 1161px;
`