/*
 * @Author: your name
 * @Date: 2021-04-12 10:52:43
 * @LastEditTime: 2021-04-12 16:35:51
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Supplier/index.tsx
 */
import React, { FC, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { BreadcrumbOption, TabOption } from "types/antd"
import { Header } from "components"
import White from "./View/White"
import Detail from "./View/Detail"
import Review from "./View/Review"

const Supplier: FC = () => {
  const { t } = useTranslation()
  const { pathname } = useHistory().location
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "供应商管理"
  }, {
    name: "供应商列表"
  }])
  // const [selectTabKey, setSelectTabKey] = useState<string>(pathname === "/supplier/white" ? "0" : "1")
  // const [tabs] = useState<TabOption[]>([{
  //   key: "0",
  //   name: "白名单",
  //   link: "/supplier/white"
  // }, {
  //   key: "1",
  //   name: "待审核",
  //   link: "/supplier/review"
  // }])

  const renderHeader = () => <Header
    title={t("page_supplier_title")}
    description={t("page_supplier_description")}
    breadcrumbs={breadcrumbs}
    // tabs={tabs}
    // selectTabKey={selectTabKey}
    // onTabClick={(key) => { setSelectTabKey(key) }}
  />

  return (
    <div>
      <Route path='/supplier/white' render={renderHeader} />
      <Route path='/supplier/review' render={renderHeader} />
      <Route path='/supplier/white' render={() => <White />} />
      <Route path='/supplier/review' render={() => <Review />} />
      <Route path='/supplier/detail/:id' render={() => <Detail />} />
    </div>
  )
}

export default Supplier