/*
 * @Author: your name
 * @Date: 2021-04-27 17:06:16
 * @LastEditTime: 2021-04-27 18:16:34
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Order/Model/ExportDialog/index.tsx
 */
import React, { FC, useState, useCallback, useEffect } from "react"
import { Modal, Select, Button, Input } from "antd"
import { InputBox } from "components"
import { Tab } from "types/order"
import { order } from "services"
import { Body, Text } from "./style"
import { exportExcel } from 'xlsx-oc'

interface ExportDialogType {
  tabList: Tab[]
  isOpen: boolean,
  onClose: () => void
}

const ExportDialog: FC<ExportDialogType> = (props) => {
  const { Option } = Select
  const [tabList, setTabList] = useState<string[]>()
  const [stateList, setStateList] = useState<{ [key: string]: string[] }>()
  const [selectTab, setSelectTab] = useState<string>()
  const [selectState, setSelectState] = useState<string>()
  const [search, setSearch] = useState<string>('')

  //init
  useEffect(() => {
    setTabList(props.tabList.map(item => item.name))
    setSelectTab(props.tabList[0].name)
    let temp: { [key: string]: string[] } = {}
    props.tabList.forEach(item => { temp[item.name] = item.options })
    setStateList(temp)
    setSelectState(temp[props.tabList[0].name][0])
  }, [props.tabList])

  const handleClickRule = useCallback(async () => {
    const data = await order.getList({
      status: selectState,
      q: search,
      page_size: '1000',
      page: '1'
    })
    exportDefaultExcel(data.data)
  }, [search, selectState])

  const exportDefaultExcel = useCallback((excel) => {
    const header = [
      { k: 'id', v: '用户Id' },
      { k: 'name', v: '用户姓名' },
      { k: 'supplier', v: '供应商名称' },
      { k: 'subject', v: '订单分类' },
      { k: 'phone', v: '联系方式' },
      { k: 'status', v: '订单状态' },
      { k: 'created', v: '创建时间' },
      { k: 'reason', v: '拒绝原因' },
    ];
    const fileName = "用户列表.xlsx";
    exportExcel(header, excel, fileName);
  }, [])

  return (
    <Modal
      title="导出报表"
      visible={props.isOpen}
      onCancel={props.onClose}
      footer={
        <div>
          <Button type="primary" onClick={handleClickRule}>导出</Button>
          <Button>
            <Text onClick={() => { }}>重置</Text>
          </Button>
        </div>
      }
    >
      <Body>
        <InputBox style={{ margin: 16 }} title="订单分类">
          <Select
            value={selectTab}
            onChange={(value) => {
              setSelectTab(value)
              setSelectState(stateList && stateList[value] && stateList[value].length ? stateList[value][0] : void 0)
            }}
            style={{ width: 256 }}
          >
            {tabList && tabList.map(item => <Option key={item} value={item}>{item}</Option>)}
          </Select>
        </InputBox>
        <InputBox style={{ margin: 16 }} title="订单状态">
          <Select value={selectState} onChange={(value) => { setSelectState(value) }} placeholder='请选择' style={{ width: 256 }}>
            {stateList && selectTab && stateList[selectTab].map(item => <Option key={item} value={item}>{item}</Option>)}
          </Select>
        </InputBox>
        <InputBox style={{ margin: 16 }} title="搜索订单">
          <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='请输入关键字' style={{ width: 256 }} />
        </InputBox>
      </Body>
    </Modal>
  )
}

export default ExportDialog