/*
 * @Author: your name
 * @Date: 2021-04-12 14:59:04
 * @LastEditTime: 2021-04-12 18:31:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Reject/View/style.ts
 */
import styled from 'styled-components'

export const Title = styled.div`
  height: 82px;
  padding-top: 34px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
`

export const DetailTitle = styled.div`
  height: 24px;
  font-size: 16px;
  font-weight: 600;
`

export const ModalBody = styled.div`
  display: flex; 
  align-items: center;
  flex-direction: column ;
`

export const BlockLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`
