/*
 * File: /src/pages/System/View/Decoration/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Tuesday 2020-12-08 04:30:16
 * Last Modified: Wednesday 2020-12-09 09:57:34
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment } from 'react'
import { Route } from 'react-router-dom'
import Tabbar from './Tabbar'
import Home from './Home'

const Decoration: FC = () => {
  return (
    <Fragment>
      <Route path='/system/decoration/tabbar' render={() => <Tabbar />} />
      <Route path='/system/decoration/home' render={() => <Home />} />
    </Fragment>
  )
}

export default Decoration
