import React, { FC, useState, useCallback } from "react"
import { Button, Modal, message, Typography } from "antd"
import { supplier } from "services"
import { OSS_EXCEL } from "consts/url"
import XLSX, { exportExcel } from "components/XLSX"

interface DialogType {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void
}

const FilesDialog: FC<DialogType> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [matrix, setMatrix] = useState<AnyObject[]>([])

  const handleSubmit = useCallback(() => {
    setLoading(true)
    const suppliers = matrix.map(obj => Object.values(obj))
    supplier.importSupplierList({ suppliers })
      .then(() => {
        message.success('导入成功！')
      })
      .catch(error => {
        const failList = error.response.data?.data?.fail_list
        if (failList) {
          const headers = [
            '供应商名称（必填）',
            '供应商联系电话（非必填）',
            '供应商策略分组名称（必填）',
            '供应商权重（不填默认为1）',
            '错误原因'
          ]
          const data = failList.map((arr: string[]) => {
            const obj: AnyObject = {}
            arr.forEach((value, index) => obj[headers[index]] = value)
            return obj
          })
          exportExcel(data, '错误报告-供应商导入')
          message.error('部分数据导入失败，错误报告已下载，请更改并重新上传')
        } else {
          message.error('导入失败~')
        }
      })
      .finally(() => {
        props.onClose()
        props.onSubmit()
        setLoading(false)
      })
  }, [matrix])

  return (
    <Modal
      title="导入供应商"
      visible={props.isOpen}
      onCancel={props.onClose}
      bodyStyle={{ paddingBottom: 0 }}
      footer={<Button type="primary" onClick={handleSubmit} loading={loading} >上传</Button>}
    >
      <XLSX
        mapping={{
          'A': { name: '供应商名称' },
          'B': { name: '供应商联系电话', default: 'x' },
          'C': { name: '供应商策略分组名称' },
          'D': { name: '供应商权重', default: 1 },
          'E': { name: '针对预约项目' }
        }}
        onChange={matrix => setMatrix(matrix)}
        hasHeader
        type="dragger"
      />
      <Typography.Text style={{ color: '#929292', display: 'block', margin: '8px auto' }}>
        不知道格式？请查看
          <a href={`${OSS_EXCEL}/supplier.xlsx`} download="temp">
          供应商导入模板
          </a>
      </Typography.Text>
    </Modal >
  )
}

export default FilesDialog
