import React, { FC, useState, useEffect, useCallback, Fragment } from "react"
import { order } from "services"
import { DetailResult, ConfigureResult, ActionPayload } from "services/order"
import { Button, Table, Popconfirm, message, Modal, Input, Descriptions } from "antd"
import { columns, fieldColumns, infoStyle } from "consts/order"
import { Block, Info } from "components"
import { useParams } from "react-router-dom"
import { Title, View, State } from "./style"
import { useDispatch } from "react-redux"
import { Dispatch } from "src/store"
import { QueueDialog, Refuse } from "../Model"

const Detail: FC<{ configure?: ConfigureResult }> = (props) => {
  const { TextArea } = Input
  const { configure } = props
  const { setPage } = useDispatch<Dispatch>().order
  const { orderId } = useParams<{ orderId: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [detail, setDetail] = useState<DetailResult>()
  const [render, setRender] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<string>("")
  const [actionName, setActionName] = useState<string>("")
  const [queueModalIsOpen, setQueueModalIsOpen] = useState<boolean>(false)
  const [user, setUser] = useState<{ name: string, value: string }[]>([])
  const [refuseList, setRefuseList] = useState<any[]>()
  const [refuseData, setRefuseData] = useState<{ [key: number]: string }>()

  //获取详情
  useEffect(() => {
    orderId && configure && (async () => {
      setIsLoading(true)
      const data = await order.getDetail({ id: orderId })
      setIsLoading(false)
      setDetail(data)
      const tempUser = []
      for (let name in data.order_user) {
        tempUser.push({ name, value: data.order_user[name] })
      }
      setUser(tempUser)
    })()
  }, [configure, render, orderId])

  useEffect(() => {
    (async () => {
      const data = await order.getReject()
      setRefuseList(data)
    })()
  }, [])

  useEffect(() => {
    setContent("")
  }, [isOpen])

  //选择状态
  const renderStatus = useCallback(() => {
    if (!configure || !detail) return ""
    const { status } = detail.order_status
    let result = ""
    for (let name in configure.status) {
      if (configure.status[name].filter(item => item === status).length) {
        result = `${name}-${status}`
      }
    }
    return result
  }, [configure, detail])

  const onConfirm = useCallback(async (action) => {
    if (!detail) return
    setIsOpen(false)
    try {
      let payload: ActionPayload = {
        id: [orderId],
        action,
        payload: {}
      }
      switch (action) {
        case "同意":
        case "签到":
          break
        case "拒绝收货":
          const refuse: any[] = []
          const resultRefuse: any[] = []
          for (let id in refuseData) {
            const item: any = refuseData[id as any]
            const temp = {
              name: item.name,
              type: item.formName,
              value: void 0
            }
            switch (item.type) {
              case "text":
                if (item.require && !item.value) {
                  message.error(`请填写${item.name}`)
                  return
                }
                if (item.value && item.value.length > item.text_len) {
                  message.error(`${item.name}最多只可填写${item.text_len}字`)
                  return
                }
                temp.value = item.value
                break

              default:
                if (item.require && !item.value.length) {
                  message.error(`请选择${item.name}`)
                  return
                }
                temp.value = item.value instanceof Array ? item.value.join() : item.value
            }
            refuse.push(temp)
          }
          refuse.forEach(item => {
            if (item.value) {
              resultRefuse.push(item)
            }
          })
          payload.payload["refuse"] = resultRefuse
          break
        default:
          payload.payload["content"] = content
      }
      await order.action(payload)
      setRender(render + 1)
      setPage(1)
      message.success(`您已完成操作"${action}"`, 5)
    } catch (err) {
      message.error(`操作出现异常`, 5)
    }
  }, [detail, orderId, render, content, refuseData])

  //点击按钮
  const handleClickButton = useCallback((action) => {
    if (!detail || !orderId || isLoading) return
    setActionName(action)
    switch (action) {
      case "同意": case "签到": case "补签": onConfirm(action); return
      default: setIsOpen(true)
    }
  }, [detail, orderId, render, actionName, content])

  //渲染按钮
  const renderButton = useCallback((name) => (
    <Popconfirm placement="bottom" title={`您要"${name}"么`} onConfirm={() => handleClickButton(name)} okText={name} cancelText="暂不操作">
      <Button style={{ marginLeft: "8px" }}>{name}</Button>
    </Popconfirm>
  ), [detail, orderId, render, actionName, content])

  //渲染一个特殊功能的按钮
  const renderSpecialButton = useCallback((text) => {
    if (detail!.order_status.status === "待审核" && text === "同意") {
      return <Button style={{ marginLeft: "8px" }} onClick={() => { setQueueModalIsOpen(true) }}>{text}</Button>
    }
  }, [detail])

  //渲染按钮们
  const renderButtonList = useCallback(() => {
    if (!detail || !detail.order_status.status) return ""
    switch (detail.order_status.status) {
      case "待审核": return <Fragment>{renderButton("拒绝")}{renderSpecialButton("同意")}</Fragment>
      case "待签到": return <Fragment>{renderButton("取消")}{renderButton("签到")}</Fragment>
      case "未签到": return <Fragment>{renderButton("补签")}</Fragment>
      case "已签到": return <Fragment>{renderButton("拒绝收货")}{renderButton("同意收货")}{renderButton("爽约")}</Fragment>
    }
  }, [detail, orderId, render])

  return <React.Fragment>
    <Block
      loading={isLoading}
      title={<Title>订单编号:&nbsp;#{orderId}</Title>}
      description={<div>
        {renderButtonList()}
      </div>}
      style={{ paddingBottom: '15px' }}
    >
      <State>{renderStatus()}</State>
      {detail && detail.order_status && detail.order_status.logs && detail.order_status.logs.length ?
        <Descriptions style={{ marginTop: 0 }}>
          {detail.order_status.logs.map(item =>
            <Descriptions.Item label={item.title}>{item.content}</Descriptions.Item>
          )}
        </Descriptions>
        : null
      }
    </Block>
    {detail?.order_status?.status === "收货拒绝" ? <Block
      loading={isLoading}
      title={<Title>用户信息</Title>}
      style={{ padding: '14px 32px 16px' }}
    >
      <Table
        columns={[{
          key: 'type',
          dataIndex: 'type',
          title: '拒收表单'
        }, {
          key: 'name',
          dataIndex: 'name',
          title: '拒收信息名称'
        }, {
          key: 'value',
          dataIndex: 'value',
          title: '信息内容'
        }]}
        bordered
        dataSource={(detail?.order_status as any).reason}
        pagination={false}
      />
    </Block> : null
    }
    <Block
      loading={isLoading}
      title={<Title>用户信息</Title>}
      style={{ padding: '14px 32px 16px' }}
    >
      <View>
        {user && user.length ? user.map(item => <Info title={item.name} style={infoStyle}>{item.value}</Info>) : null}
      </View>
    </Block >
    <Block
      loading={isLoading}
      title={<Title>订单信息</Title>}
      style={{ padding: '24px 32px 32px' }}
    >
      <Table
        columns={columns}
        dataSource={detail?.order_detail}
        pagination={false}
      />
    </Block >
    {
      detail && detail.order_field && detail.order_field.length ?
        <Block
          loading={isLoading}
          title={<Title>自定义预约信息</Title>}
          style={{ padding: '24px 32px 32px' }}
        >
          <Table
            bordered
            columns={fieldColumns}
            dataSource={detail?.order_field}
            pagination={false}
          />
        </Block >
        : null
    }
    <Modal
      visible={isOpen}
      title={actionName}
      onOk={() => onConfirm(actionName)}
      onCancel={() => setIsOpen(false)}
    >
      {
        actionName === "拒绝收货" ?
          <Refuse
            refuseList={refuseList}
            setResult={(value) => { setRefuseData(value) }}
            setRefuseList={(data) => { setRefuseList(data) }}
          /> :
          <View style={{ marginTop: 0 }}>
            <p>原因:</p>
            <TextArea
              rows={4}
              value={content}
              onChange={(e) => { setContent(e.target.value) }} />
          </View>
      }
    </Modal>
    <QueueDialog
      isOpen={queueModalIsOpen}
      onClose={() => setQueueModalIsOpen(false)}
      onSubmit={() => {
        setQueueModalIsOpen(false)
        setRender(render + 1)
      }}
    />
  </React.Fragment >
}

export default Detail