import React, { FC, useState, ReactNode, Fragment, useCallback, useEffect } from 'react'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Table, Row, Col, Button, message, Modal } from 'antd'

import services from 'services'
import { Block } from 'components'
import { Item } from 'types/item'
import { ItemImg, ItemName, ItemSummary, WechatVisible } from './style'
import Edit from './Edit'

interface ColumnType {
  item: any
  visible: boolean
  operation: ReactNode
}

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [sourceData, setSourceData] = useState<{ [index: string]: Item }>()
  const [tableData, setTableData] = useState<ColumnType[]>()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)

  const [rnd, setRnd] = useState<number>(0)
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const [editOperation, setEditOperation] = useState<'add' | 'update'>('add')
  const [editVisible, setEditVisible] = useState<boolean>(false)

  useEffect(() => {
    services.item.getList({ page })
      .then(data => {
        const tableData: ColumnType[] = []
        Object.values(data.list).forEach((el, index) => {
          tableData.push({
            item: el,
            visible: el.visible,
            operation: { index, id: el.id }
          })
        })
        setSourceData(data.list)
        setTableData(tableData)
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取预约项目列表失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [rnd, page])

  const handleAddItem = useCallback(() => {
    setEditOperation('add')
    setEditVisible(true)
    setActiveIndex(-1)
  }, [])

  const handleUpdateItem = useCallback((index: number) => {
    setEditOperation('update')
    setEditVisible(true)
    setActiveIndex(index)
  }, [])

  const handleDeleteItem = useCallback(async (id) => {
    Modal.confirm({
      title: '确认要删除这个项目吗？',
      icon: <ExclamationCircleOutlined />,
      content: '注意，项目一旦删除不可复原，请慎重考虑！！！',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        return new Promise((resolve, reject) => {
          services.item.delete({ id: id })
            .then(() => {
              message.success('删除预约项目成功')
              setRnd(Math.random())
              resolve()
            })
            .catch(() => {
              message.success('删除预约项目失败')
              reject()
            })
        })
      }
    })
  }, [])

  return (
    <Block
      loading={loading}
      style={{
        paddingTop: '32px'
      }}>
      <Button type="primary" onClick={handleAddItem}>
        <PlusOutlined />
          新增预约项目
        </Button>
      <Table
        loading={loading}
        dataSource={tableData}
        pagination={{
          total: total,
          showQuickJumper: true,
          pageSize,
          onChange: page => setPage(page),
        }}
        style={{
          marginTop: 24
        }}
      >
        <Table.Column
          title="预约项目"
          dataIndex="item"
          key="item"
          render={(item: Item) =>
            <Row>
              <Col>
                <ItemImg src={item.img_url} />
              </Col>
              <Col offset={1}>
                <ItemName>{item.name}</ItemName>
                <ItemSummary>{item.summary}</ItemSummary>
              </Col>
            </Row>
          }
        />
        <Table.Column
          title="项目状态"
          dataIndex="visible"
          key="visible"
          className="thead-center"
          render={(visible: boolean) => {
            return (
              <WechatVisible visible={visible}>
                {`微信端${visible ? '显示' : '隐藏'}`}
              </WechatVisible>
            )
          }}
        />
        <Table.Column
          title="操作"
          dataIndex="operation"
          key="operation"
          className="thead-center"
          render={({ index, id }) =>
            <Fragment>
              <Button type="link" onClick={() => handleUpdateItem(index)}>编辑</Button>
              <Button type="link" onClick={() => handleDeleteItem(id)} >删除</Button>
            </Fragment>
          }
        />
      </Table>
      <Edit
        visible={editVisible}
        operation={editOperation}
        onClose={() => setEditVisible(false)}
        onChange={() => setRnd(Math.random())}
        item={Object.assign({}, sourceData?.[activeIndex])}
      />
    </Block>
  )
}

export default List
