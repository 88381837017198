import React, { FC, Fragment, useState } from 'react'
import { Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Header } from 'components'
import List from './View/List'
import Detail from './View/Detail'
import { TabOption } from 'types/antd'
import Calendar from './View/Calendar'
import Sort from './View/Sort'

const Policy: FC = () => {
  const { t } = useTranslation()
  const [tabs] = useState<TabOption[]>([{
    key: "0",
    name: "策略分组",
    link: "/policy/list"
  }, {
    key: "1",
    name: "权重分组",
    link: "/policy/list"
  }])

  return (
    <div>
      <Route path="/policy/list/:id" render={() =>
        <Fragment>
          <Header
            title={t("page_policy_title")}
            description={t("page_policy_description")}
            tabs={tabs}
          />
          <List />
        </Fragment>
      } />
      <Route path="/policy/calendar/:id/:name/:type" render={() => <Calendar />} />
      <Route path="/policy/detail/:id" render={() => <Detail />} />
      <Route path="/policy/sort" render={() => <Sort />} />
    </div>
  )
}

export default Policy
