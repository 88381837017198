import React, { FC, ReactNode, CSSProperties } from 'react'
import { Body, Header, Title, Description, Main, Line } from './style'

interface BlockProps {
  title?: ReactNode,
  description?: ReactNode,
}

const Block: FC<BlockProps> = (props) => {
  return (
    <Body>
      <Header>
        <Title>{props.title}</Title>
        <Description>
          {props.description}
        </Description>
      </Header>
      <Line />
      <Main>
        {props.children}
      </Main>
    </Body>
  )
}

export default Block
