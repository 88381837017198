import React, { FC, useEffect, useState, useCallback } from 'react'
import { Drawer, Form, Button, Input, Spin, message, Switch } from 'antd'
import OSSUpload from 'components/OSSUpload'
import services from 'services'
import { Item } from 'types/item'
import RichText from 'components/RichText'

interface EditProps {
  visible: boolean,
  operation: 'add' | 'update',
  onClose: () => any,
  onChange: () => any,
  item: Item
}

const Edit: FC<EditProps> = (props) => {
  const { visible, operation, onClose, onChange, item } = props
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (operation === 'add') {
      form.resetFields()
    }
    form.setFieldsValue({
      id: item.id || '',
      name: item.name || '',
      imgUrl: item.img_url ? item.img_url.slice(item.img_url.lastIndexOf('/') + 1) : '',
      visible: item.visible || '',
      summary: item.summary || '',
      description: item.description || ''
    })
  }, [item])

  const handleSubmit = useCallback(async () => {
    form.validateFields()
      .then(async (data) => {
        const postData = {
          id: data.id,
          name: data.name,
          img_url: data.imgUrl,
          visible: data.visible || false,
          summary: data.summary,
          description: data.description
        }
        const action = operation === 'add' ? services.item.add : services.item.update
        action(postData)
          .then(() => {
            setLoading(true)
            onClose()
            onChange()
            message.success(`预约项目${operation === 'add' ? '创建' : '更新'}成功`)
          })
          .catch(() => {
            message.error(`预约项目${operation === 'add' ? '创建' : '更新'}失败`)
          })
          .finally(() => {
            setLoading(false)
          })
      }).catch(() => {
        message.warning('请正确填写表单')
      })
  }, [item])

  const handleUploaded = useCallback((key: string) => {
    form.setFieldsValue({ imgUrl: key.slice(key.lastIndexOf('/') + 1) })
  }, [form])

  return (
    <Drawer
      visible={visible}
      maskClosable
      onClose={onClose}
      title={`${operation === 'add' ? '新增' : '编辑'}预约项目`}
      width={512}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 8 }}
            onClick={onClose}
          >取消</Button>
          <Button
            type="primary"
            onClick={handleSubmit}
          >确定</Button>
        </div>
      }
    >
      <Spin spinning={loading}>
        <Form
          name="item"
          form={form}
          layout="vertical"
          validateMessages={{ required: "'${label}' 不能为空" }}
        >
          <Form.Item
            label="预约项目ID"
            name="id"
            style={{ display: 'none' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="预约项目名称"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="预约项目图片(只支持.jpg格式)"
            name="imgUrl"
            rules={[{ required: true }]}
            valuePropName="imgUrl"
          >
            <OSSUpload
              accept=".jpg"
              listType="picture-card"
              showUploadList={false}
              onUploaded={handleUploaded}
            />
          </Form.Item>
          <Form.Item
            label="预约项目简介"
            name="summary"
            rules={[{ required: true }]}
          >
            <Input maxLength={15} placeholder="请输入预约项目简介（不超过15个字）" />
          </Form.Item>
          <Form.Item
            label="微信端是否显示"
            name="visible"
            valuePropName="checked"
          >
            <Switch
              checkedChildren="开启"
              unCheckedChildren="关闭"
            />
          </Form.Item>
          <Form.Item
            label="详细信息"
            name="description"
            rules={[{ required: false }]}
          >
            <RichText defaultValue={item.description} />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default Edit
