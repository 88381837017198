/*
 * File: /src/pages/System/View/Decoration/Emulator/components.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-12-09 04:18:35
 * Last Modified: Wednesday 2020-12-09 04:18:35
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */

import Carousel from "./Emulator/Carousel"
import Shortcut from "./Emulator/Shortcut"
import ResList from "./Emulator/ResList"

const components: AnyObject = {
  'Carousel': {
    name: 'Carousel',
    title: '轮播图',
    icon: 'iconlunbotu',
    category: 'basic',
    level: 1,
    render: Carousel
  },
  'Shortcut': {
    name: 'Shortcut',
    title: '快捷功能区',
    icon: 'iconkuaijiegongnengqu',
    category: 'basic',
    level: 2,
    render: Shortcut
  },
  'ResList': {
    name: 'ResList',
    title: '对象列表',
    icon: 'iconduixiangliebiao',
    category: 'basic',
    level: 3,
    render: ResList
  },
  'Image': {
    name: 'Image',
    title: '图片',
    icon: 'icontupian',
    category: 'basic'
  },
  'Text': {
    name: 'Text',
    title: '文本',
    icon: 'iconwenben',
    category: 'basic'
  }
}

export default components
