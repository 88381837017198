import styled from "styled-components"

export const Row = styled.div`
  display: flex;
  padding-top: 24px;
`

export const Title = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`

export const Right = styled.div`
  display: flex;
`

export const Value = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-top: 16px;
`

export const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 20px;
`

export const HandleList = styled.div`
  display: flex;
  align-items: center;
`

export const Space = styled.div`
  height: 16px;
  width: 1px;
  background: #30A694;
`