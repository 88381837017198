export const columns = [{
  key: 'name',
  dataIndex: 'name',
  title: '用户姓名'
}, {
  key: 'phone',
  dataIndex: 'phone',
  title: '联系方式'
}, {
  key: 'order_time',
  dataIndex: 'order_time',
  title: '最近一次预约时间'
}, {
  key: 'supplier',
  dataIndex: 'supplier',
  title: '供应商名称'
}, {
  key: 'group',
  dataIndex: 'group',
  title: '供应商分组'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]

export const recordColumns = [{
  key: 'id',
  dataIndex: 'id',
  title: '订单号'
}, {
  key: 'subject',
  dataIndex: 'subject',
  title: '预约项目'
}, {
  key: 'created',
  dataIndex: 'created',
  title: '预约时间'
}, {
  key: 'status',
  dataIndex: 'status',
  title: '订单状态'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]
