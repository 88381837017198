import React, { FC, useState, useCallback, useEffect } from "react"
import { Modal, Select } from "antd"
import { time } from "services"
import { InputBox } from "components"
import { RoomList } from "services/time"

interface DialogType {
  isOpen: boolean,
  onSubmit: () => void
  onClose: () => void
}

const Dialog: FC<DialogType> = (props) => {
  const { Option } = Select
  const [options, setOptions] = useState<RoomList[]>()
  const [option, setOption] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!props.isOpen) return
    (async () => {
      setIsLoading(true)
      const data = await time.getRoomList()
      setIsLoading(false)
      setOptions(data)
    })()
  }, [props.isOpen])

  const handleClickSubmit = useCallback(() => {
    if (!option) {

    } else {
      (async () => {
        try {
          await time.addNewCard({ resource_id: option.toString() })
          props.onSubmit()
        } catch {

        }
      })()
    }
  }, [option])

  return (
    <Modal
      title='新增配置'
      visible={props.isOpen}
      onCancel={props.onClose}
      onOk={handleClickSubmit}
    >
      <InputBox style={{ display: 'flex', justifyContent: 'center' }} title='选择预约项目'>
        <Select onChange={(value) => { setOption(value as number) }} loading={isLoading} style={{ width: 200 }} placeholder='请选择预约对象'>
          {options && options.length ? options.map(item =>
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ) : null
          }
        </Select>
      </InputBox>
    </Modal>
  )
}

export default Dialog