import React, { FC, useState, useEffect, Fragment, ReactNode, useCallback } from 'react'
import { Button, Form, Input, Select, Table, message } from 'antd'
import services from 'services'
import { Block } from 'components'
import { Link, useParams } from 'react-router-dom'
import AddSupplier from '../Model/AddSupplier'

interface ColumnType {
  name: string,
  phone: string,
  register_time: string,
  operation: ReactNode
}

const formItemStyle = {
  marginBottom: 10
}

const Detail: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [form] = Form.useForm()
  const [title, setTitle] = useState<string>('')
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState<boolean>(true)
  const [detailLoading, setDetailLoading] = useState<boolean>(true)
  const [tableData, setTableData] = useState<ColumnType[]>()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(0)
  const [type, setType] = useState<string>('')

  useEffect(() => {
    services.policy.getDetail({ id })
      .then((data) => {
        setTitle(data.name)
        setType(data.type)
        form.setFieldsValue(data)
      })
      .catch(() =>
        message.error('获取策略分组详情失败')
      )
      .finally(() =>
        setDetailLoading(false)
      )
  }, [id, form])

  const loadSupplierList = useCallback(() => {
    services.policy.getSupplierList(id, { page })
      .then(data => {
        const tableData: ColumnType[] = []
        data.data.forEach((el, index) => {
          tableData.push({
            name: el.name,
            phone: el.phone,
            register_time: el.register_time,
            operation: { index, supplierId: el.id }
          })
        })
        setTableData(tableData)
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取供应商列表失败');
      })
      .finally(() => {
        setTableLoading(false)
      })
  }, [page, id])

  useEffect(loadSupplierList, [loadSupplierList])

  const handleEditButtonClick = useCallback(() => {
    if (isEdit) {
      form.validateFields()
        .then((data) => {
          setDetailLoading(true)
          services.policy.update({
            name: data.name,
            type: data.type,
            remark: data.remark
          }, { id })
            .then(() => {
              message.success(`策略分组更新成功`)
              setType(data.type)
            })
            .catch(() =>
              message.error(`策略分组更新失败`)
            )
            .finally(() => {
              setIsEdit(!isEdit)
              setDetailLoading(false)
            })
        })
        .catch(() => {
          message.warning('请正确填写表单')
        })
    } else {
      setIsEdit(!isEdit)
    }
  }, [isEdit, form])

  const handleRemoveSupplier = useCallback((policyId: string, supplierId: string) => {
    setTableLoading(true)
    services.policy.removeSupplier(policyId, supplierId)
      .then(() => {
        message.success('移除供应商成功！')
        loadSupplierList()
      })
      .catch(() => {
        message.error('移除供应商失败~')
      })
      .finally(() => {
        setTableLoading(false)
      })
  }, [])

  return (
    <Fragment>
      <Block
        loading={detailLoading}
        title={title}
        showBack
        description={
          <Fragment>
            {type === "普通型" ?
              null :
              <Link to={`/policy/calendar/${id}/${title}/${type}`}>
                <Button key="1" style={{ marginRight: 12 }}>查看送货日历</Button>
              </Link>
            }
            <Button
              key="2"
              type={isEdit ? "primary" : "default"}
              onClick={handleEditButtonClick}
            >{isEdit ? "保存" : "编辑"} </Button>
          </Fragment>
        }
        style={{ padding: 20 }}
      >
        <Form
          name="item"
          form={form}
          validateMessages={{ required: "'${label}' 不能为空" }}
        >
          <Form.Item
            label="分组名称"
            name="name"
            rules={[{ required: isEdit }]}
            style={formItemStyle}
          >
            <Input bordered={isEdit} readOnly={!isEdit} style={{ width: 160 }} />
          </Form.Item>
          <Form.Item
            label="策略类型"
            name="type"
            rules={[{ required: isEdit }]}
            style={formItemStyle}
          >
            {
              !isEdit ?
                <Input bordered={false} readOnly /> :
                <Select style={{ width: 200 }} bordered={isEdit} >
                  <Select.Option value="固定型">固定型</Select.Option>
                  <Select.Option value="优先型">优先型</Select.Option>
                  <Select.Option value="VIP型">VIP型</Select.Option>
                </Select>
            }
          </Form.Item>
          <Form.Item
            label="策略周期"
            name="cycle"
            style={formItemStyle}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            label="特殊日期"
            name="special_date"
            style={formItemStyle}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            label="分组备注"
            name="remark"
            rules={[{ required: isEdit }]}
            style={formItemStyle}
          >
            <Input.TextArea bordered={isEdit} readOnly={!isEdit} style={{ width: 360 }} />
          </Form.Item>
        </Form>
      </Block>
      <Block
        title="组内供应商"
        description={<AddSupplier onFlush={loadSupplierList} />}>
        <Table
          loading={tableLoading}
          dataSource={tableData}
          pagination={{
            total: total,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (_page, _pageSize) => {
              if (_page !== page) setPage(_page)
              if (_pageSize !== pageSize) setPageSize(_pageSize!)
            },
          }}
          style={{
            marginTop: 24
          }}
        >
          <Table.Column
            title="供应商名称"
            dataIndex="name"
            key="name"
          />
          <Table.Column
            title="联系方式"
            dataIndex="phone"
            key="phone"
          />
          <Table.Column
            title="注册时间"
            dataIndex="register_time"
            key="register_time"
          />
          <Table.Column
            title="操作"
            dataIndex="operation"
            key="operation"
            className="thead-center"
            render={({ supplierId }) =>
              <Fragment>
                <Link to={`/supplier/detail/${supplierId}`}>详情</Link>
                <Button type="link" onClick={() => handleRemoveSupplier(id, supplierId)} >移除</Button>
              </Fragment>
            }
          />
        </Table>
      </Block>
    </Fragment>
  )
}

export default Detail
