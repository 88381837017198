import React, { FC, useState, useEffect } from 'react'
import { Block, Header } from 'components'
import { BreadcrumbOption } from 'types/antd'
import { useTranslation } from 'react-i18next'
import { CardList as CardListType } from 'services/time'
import { time } from 'services'
import { Input } from 'antd'
import Card from '../Model/Card'
import Add from '../Model/Add'
import Dialog from '../Model/Add/dialog'
import { List } from './style'

const CardList: FC = () => {
  const { t } = useTranslation()
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: '时间管理'
  }, {
    name: '项目时间段配置'
  }])
  const { Search } = Input
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [cardList, setCardList] = useState<CardListType[]>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [render, setRender] = useState<number>(0)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await time.getCardList({ q: search })
      setLoading(false)
      setCardList(data)
    })()
  }, [render, search])

  return (
    <React.Fragment>
      <Header
        title={t('page_time_title')}
        description={t('page_time_description')}
        breadcrumbs={breadcrumbs}
      />
      <Block
        title="设置列表"
        loading={loading}
        description={
          <React.Fragment>
            <Search
              placeholder='请输入'
              onSearch={(value) => setSearch(value)}
            />
          </React.Fragment>
        }
      />
      <List>
        <Add onClick={() => setIsOpen(true)} />
        {cardList && cardList.length ? cardList.map(item =>
          <Card key={item.id} tag={`/time/detail/${item.id}`} name={item.name} description={item.description} img_url={item.img_url} />
        ) : null
        }
      </List>
      <Dialog
        isOpen={isOpen}
        onSubmit={() => {
          setIsOpen(false)
          setRender(render + 1)
        }}
        onClose={() => setIsOpen(false)}
      />
    </React.Fragment>
  )
}

export default CardList