/*
 * @Author: your name
 * @Date: 2021-04-12 10:52:43
 * @LastEditTime: 2021-04-27 18:00:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/NavBar/index.tsx
 */
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { ossURL } from 'consts/url'
import { Body } from './style'
import { InsertRowAboveOutlined, CrownOutlined, CheckSquareOutlined, UserOutlined, FormOutlined } from '@ant-design/icons'

interface MenuType {
  inlineCollapsed?: boolean
}

const { SubMenu, Item } = Menu

const NavBar: FC<MenuType> = (props) => {
  return (
    <Body>
      <img
        src={`${ossURL}/img/${props.inlineCollapsed ? 'mini_' : ''}logo.png`}
        style={props.inlineCollapsed ?
          { width: '36px', margin: '19px 22px 44px' } :
          { width: '188px', margin: '19px 34px 44px' }
        }
      />
      <Menu
        style={{
          minHeight: '100%',
          borderRight: 'none'
        }}
        mode='inline'
        inlineCollapsed={props.inlineCollapsed}
      >
        <SubMenu key='1' title='预约管理' icon={<CrownOutlined />}>
          <Item key='1.1'>
            <Link to='/item/list'>预约项目</Link>
          </Item>
          <Item key='1.2'>
            <Link to='/time/list'>项目时间段配置</Link>
          </Item>
        </SubMenu>
        <SubMenu key='2' title='系统配置' icon={<FormOutlined />}>
          <Item key='2.1'>
            <Link to='/notice/list'>预约公告</Link>
          </Item>
          <Item key='2.2'>
            <Link to='/system/display_time/common'>日期展示设置</Link>
          </Item>
          <Item key='2.3'>
            <Link to='/system/weight'>权重设置</Link>
          </Item>
          <Item key='2.4'>
            <Link to='/system/number'>预约规则设置</Link>
          </Item>
          <Item key='2.5'>
            <Link to='/announcement/system'>预约须知</Link>
          </Item>
          <Item key='2.6'>
            <Link to='/system/decoration/home'>店铺装修</Link>
          </Item>
          <Item key="2.8">
            <Link to='/reject/list'>系统表单维护</Link>
          </Item>
        </SubMenu>
        <SubMenu key='3' title='订单管理' icon={<InsertRowAboveOutlined />}>
          <Item key='3.1'>
            <Link to='/order/list'>订单列表</Link>
          </Item>
          <Item key='3.2'>
            <Link to='/order/attend'>签到二维码</Link>
          </Item>
          <Item key='3.3'>
            <Link to='/daily/today'>预约实况</Link>
          </Item>
          <Item key='3.4'>
            <Link to='/manual/detail'>手动添加预约</Link>
          </Item>
          <Item key="3.5">
            <Link to='/statistics'>数据统计</Link>
          </Item>
        </SubMenu>
        <SubMenu key='4' title='人员管理' icon={<UserOutlined />}>
          <Item key='4.1'>
            <Link to='/administrators/list'>管理员管理</Link>
          </Item>
          <Item key='4.2'>
            <Link to='/user/list'>用户管理</Link>
          </Item>
        </SubMenu>
        <SubMenu key='5' title='供应商管理' icon={<CheckSquareOutlined />}>
          <Item key='5.1'>
            <Link to='/supplier/white'>供应商列表</Link>
          </Item>
          <Item key='5.2'>
            <Link to='/policy/sort'>供应商分组</Link>
          </Item>
          <Item key='5.3'>
            <Link to='/car/list'>供应商车辆管理</Link>
          </Item>
        </SubMenu>
      </Menu>
    </Body >
  )
}

export default NavBar
