export const columns = [{
  key: 'title',
  dataIndex: 'title',
  title: '预约公告名称'
}, {
  key: 'open',
  dataIndex: 'open',
  title: '是否开启'
}, {
  key: 'policys',
  dataIndex: 'policys',
  title: '针对分组'
}, {
  key: 'cycle',
  dataIndex: 'cycle',
  title: '显示规则'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]