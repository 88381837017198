import { Button, Input, Modal } from 'antd'
import { ActivityIndicator, Checkbox, List, Toast } from 'antd-mobile'
import Block from 'components/mobile/Block'
import HashItem from 'components/mobile/HashItem'
import React, { FC, useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router'
import { daily } from 'services'
import { TimeFragment } from 'services/dilay'
import order, { ActionPayload } from 'services/order'
import { handlePush } from 'utils/common'
import { Card, CardHeader, CardState, CardTitle, Footer, FooterSpace, Space, Html } from './style'

const Detail: FC = () => {
  const { Item } = List
  const { TextArea } = Input
  const { id } = useParams<{ id: string }>()
  const [resource_id, date, time, fragmentId] = id.split("_")
  const [data, setData] = useState<TimeFragment>()
  const [selectList, setSelectList] = useState<string[]>([])
  const [render, setRender] = useState<number>(1)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      try {
        const timeList = await daily.getWeekFragmentList({ date, resource_id })
        setData(timeList[date].filter((item => (item.time === time) && (item.id === fragmentId)))[0])
      } catch (err) {
        Toast.fail(err.response.data.message, 1)
      } finally {
        setIsLoading(false)
      }

    })()
  }, [resource_id, date, time, fragmentId, render])

  const orderSubmit = useCallback(async (action) => {
    if (!selectList || !selectList.length) return
    setIsLoading(true)
    try {
      let payload: ActionPayload = {
        id: selectList,
        action,
        payload: { content }
      }
      await order.action(payload)
      Toast.success("您已完成操作签到", 1)
      setSelectList([])
      setRender(render + 1)
      setContent("")
    } catch (err) {
      Toast.fail("操作出现异常", 1)
    } finally {
      setIsLoading(false)
    }
  }, [selectList, render, content])

  return (
    <React.Fragment>
      <Html>
        <List>
          <Item><b>预约项目</b></Item>
          <Item extra={""}>仓库</Item>
          <Item style={{ flexBasis: 'none' }} extra={<div>{`${date} ${time}`}</div>}>时间</Item>
        </List>
        <Space />
        <Block
          title="实况列表"
          description={<Button type="link">全选</Button>}
        >
          {data?.user_list.map(item =>
            <Card>
              <CardHeader>
                <Checkbox onChange={() => { setSelectList(handlePush(item.order_id, selectList)) }} checked={selectList?.some(id => id === item.order_id)} />
                <CardTitle>{item.supplier}</CardTitle>
                <CardState>{item.state}</CardState>
              </CardHeader>
              <HashItem style={{ width: 280, marginLeft: 30 }} name="订单编号">{item.order_id}</HashItem>
              <HashItem style={{ width: 280, marginLeft: 30 }} name="序号">{item.index}</HashItem>
              <HashItem style={{ width: 280, marginLeft: 30 }} name="手机号">{item.phone}</HashItem>
            </Card>)}
        </Block>
        <Footer>
          <Button disabled={!selectList.length} style={{ width: "45%", height: 46 }} onClick={() => setIsOpen(true)}>取消</Button>
          <Button disabled={!selectList.length} style={{ width: "45%", height: 46 }} type="primary" onClick={() => orderSubmit("签到")}>签到</Button>
        </Footer>
        <FooterSpace />
      </Html>
      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => orderSubmit("取消")}
        title="取消订单"
        closable
      >
        <TextArea value={content} onChange={(e) => { setContent(e.target.value) }} placeholder="请输入取消理由" />
      </Modal>
      <ActivityIndicator
        toast
        text="Loading..."
        animating={isLoading}
      />
    </React.Fragment>
  )
}

export default Detail
