/*
 * @Author: your name
 * @Date: 2020-12-09 17:31:20
 * @LastEditTime: 2020-12-09 17:55:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/mobile/Daily/Model/Box/style.ts
 */


import styled from 'styled-components'

export const Box = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:4;
  width: 73px;
`