/*
 * @Author: your name
 * @Date: 2020-12-10 13:23:50
 * @LastEditTime: 2020-12-10 15:29:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/mobile/Block/style.ts
 */
import styled from "styled-components"

export const Body = styled.div`
  padding: 0 16px;
  background: white;
`

export const Header = styled.div`
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const Description = styled.div`
 
`

export const Main = styled.div`
  
`

export const Line = styled.div`
  height: 1px;
  background: #eee;
`