/*
 * @Author: your name
 * @Date: 2021-04-12 10:52:43
 * @LastEditTime: 2021-04-15 16:57:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Order/Model/Refuse/index.tsx
 */
import React, { FC, useState, useCallback, useEffect } from "react"
import { Input, Select } from "antd"
import { InputBox } from "components"
import { Body, Add, Title, Box } from "./style"
import { PlusOutlined, CloseOutlined } from "@ant-design/icons"


interface RefuseType {
  refuseList: any,
  setRefuseList: (data: any) => void
  setResult: (data: { [key: number]: string }) => void
}

const Refuse: FC<RefuseType> = (props) => {
  const { Option } = Select
  const { refuseList, setResult, setRefuseList } = props
  const [data, setData] = useState<any>({})

  useEffect(() => {
    setResult(data)
  }, [data])

  useEffect(() => {
    if (refuseList && refuseList.length) {
      const result: any = {}
      refuseList.forEach((refuse: any) => {
        refuse.item.forEach((item: any, index: number) => {
          if (data[`${refuse.id}-${index}`]) {
            result[`${refuse.id}-${index}`] = data[`${refuse.id}-${index}`]
          } else {
            result[`${refuse.id}-${index}`] = {
              name: item.name,
              type: item.type,
              value: item.type === "text" ? "" : [],
              formName: refuse.name,
              require: item.is_require,
              text_len: item.text_len
            }
          }
        })
      })
      setData(result)
    }
  }, [refuseList])

  const renderInput = useCallback((template, id) => {
    if (!data[id]) return
    switch (template.type) {
      case "text":
        return <div>
          <Input
            style={{ width: 224 }}
            value={data[id].value}
            placeholder={template.placeholder}
            onChange={(e) => {
              const newData = JSON.parse(JSON.stringify(data))
              newData[id].value = e.target.value
              setData(newData)
            }} />
        </div>
      default:
        return <div>
          <Select
            style={{ width: 224 }}
            value={data[id].value}
            placeholder={template.placeholder}
            mode={template.type === "multiple" ? "multiple" : void 0}
            onChange={(value) => {
              const newData = JSON.parse(JSON.stringify(data))
              newData[id].value = value
              setData(newData)
            }}
          >
            {template.options.map((name: string) => (
              <Option key={name + template.type} value={name}>{name}</Option>
            ))}
          </Select>
        </div>
    }
  }, [data])

  const handleClickAdd = useCallback((refuseIndex) => {
    const newRefuse = JSON.parse(JSON.stringify(refuseList))
    const newRefuseItem = JSON.parse(JSON.stringify(newRefuse[newRefuse.length - 1]))
    newRefuseItem.is_add = false
    newRefuse.push({
      ...newRefuseItem,
      id: +newRefuseItem.id + 1
    })
    setRefuseList(newRefuse)
  }, [refuseList, setRefuseList])

  const handleDel = useCallback((item, index) => {
    const newRefuse = JSON.parse(JSON.stringify(refuseList))
    newRefuse.splice(index, 1)
    setRefuseList(newRefuse)
  }, [refuseList, setRefuseList])

  return (
    <div>
      {refuseList?.map((refuse: any, refuseIndex: number) => (
        <Body key={refuseIndex} style={{position: 'relative'}}>
          {
            refuseList.length > 1 ?
            <CloseOutlined
              style={{
                fontSize: '18px',
                color: '#999',
                position: 'absolute',
                top: '12px',
                right: '12px',
                cursor: 'pointer'
              }}
              onClick={() => {
                handleDel(refuse, refuseIndex)
              }}
            /> : null
          }
          <Box>
            <Title>{refuse.name}</Title>
            {refuse.item.map((item: any, index: number) => (
              <InputBox key={index +'Item'} title={item.name} style={{ paddingBottom: 16 }} isMust={item.is_require}>
                {renderInput(item, `${refuse.id}-${index}`)}
              </InputBox>
            ))}
          </Box>
        </Body>
      ))
      }
      <Add onClick={handleClickAdd}>
        <PlusOutlined /> &nbsp;&nbsp;追加一组记录
      </Add>
    </div >
  )
}

export default Refuse