import React, { FC, useState, useCallback } from 'react'
import { RereadRuleType, CustomRuleType } from 'types/time'
import { Drawer, Input, DatePicker, Select, Button, Radio, Switch, Modal, message } from 'antd'
import { useParams } from 'react-router'
import { FormatDay } from 'utils/time'
import { InputBox } from 'components'
import { PanelRowStyle, PanelInput, defaultCustomRule, PanelLeftRowStyle } from 'consts/time'
import moment from "moment"
import { InputRow } from './style'
import Reread from '../Reread'
import time, { SubmitQuickPayload } from 'services/time'

interface QuickPanelType {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void
}

const QuickPanel: FC<QuickPanelType> = (props) => {
  const { RangePicker } = DatePicker
  const { cardId: resource_id } = useParams<{ cardId: string }>()
  const [date, setDate] = useState<Date>(new Date())
  const [timeRange, setTimeRange] = useState<[string, string]>(['08:00', '16:00'])
  const [loading, setLoading] = useState<boolean>(false)
  const [space, setSpace] = useState<number>(0)
  const [size, setSize] = useState<number>(0)
  const [load, setLoad] = useState<number>(0)
  const [isUseRereadRule, setIsUseRereadRule] = useState<boolean>(false)
  const [rereadRule, setRereadRule] = useState<RereadRuleType>(RereadRuleType.day)
  const [customRule, setCustomRule] = useState<CustomRuleType>(defaultCustomRule)
  const [payload, setPayload] = useState<SubmitQuickPayload>()
  const [isTipOpen, setIsTipOpen] = useState<boolean>(false)

  const createNewItem = useCallback(async (payload) => {
    try {
      await time.submitQuick(payload)
      setIsTipOpen(false)
      props.onSubmit()
    } catch (err) {
      err.response && message.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleClickSubmit = useCallback(async () => {
    setLoading(true)
    const payload = {
      resource_id: Number(resource_id),
      start_time: `${FormatDay(date)} ${timeRange[0]}`,
      end_time: `${FormatDay(date)} ${timeRange[1]}`,
      time: size!,
      space_time: space,
      load: load!,
      weight: 1,
      reread_rule: isUseRereadRule && rereadRule ? rereadRule : RereadRuleType.today,
      custom_rule: customRule,
    }
    setPayload(payload)
    try {
      const data = await time.VACTipTimeQuick(payload)
      if (data.is_overlapping || data.is_overlap) {
        setIsTipOpen(true)
      } else {
        createNewItem(payload)
      }
    } catch (err) {
      setLoading(false)
      err.response && message.error(err.response.data.message)
    }
  }, [resource_id, date, timeRange, size, space, load, isUseRereadRule, customRule, rereadRule])

  return (
    <React.Fragment>

      <Drawer
        visible={props.isOpen}
        width={512}
        title='快速生成'
        bodyStyle={{
          padding: ' 0 24px',
        }}
        onClose={props.onClose}
        footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
        footer={
          <React.Fragment>
            <Button onClick={props.onClose}>取消</Button>
            <Button
              loading={loading}
              disabled={loading}
              onClick={handleClickSubmit}
              type='primary'
              style={{ marginLeft: '8px' }}
            >
              确定
          </Button>
          </React.Fragment>
        }
      >
        <InputBox style={PanelRowStyle} title='选择日期'>
          <DatePicker
            value={moment(date)}
            style={PanelInput}
            onChange={(_value, string) => setDate(new Date(string))}
            allowClear={false}
          />
        </InputBox>
        <InputBox style={PanelRowStyle} title='起止时间'>
          <RangePicker
            picker='time'
            format='HH:mm'
            value={timeRange && timeRange.length ? [moment(timeRange[0], 'HH:mm'), moment(timeRange[1], 'HH:mm')] : undefined}
            onChange={(_value, string) => setTimeRange(string)}
            allowClear={false}
            style={{
              width: '224px'
            }}
          />
        </InputBox>
        <InputBox style={{ ...PanelRowStyle, paddingRight: '42px' }} title='设置单位时间段持续时间'>
          <InputRow>
            <Input
              type='number'
              value={size}
              onChange={(e) => { setSize(Number(e.target.value)) }}
              style={PanelInput}
            />
            <p>分钟</p>
          </InputRow>
        </InputBox>
        <InputBox style={{ ...PanelRowStyle, paddingRight: '42px' }} title='设置时间间隔'>
          <InputRow>
            <Input
              type='number'
              value={space}
              onChange={(e) => { setSpace(Number(e.target.value)) }}
              style={PanelInput}
            />
            <p>分钟</p>
          </InputRow>
        </InputBox>
        <InputBox style={PanelRowStyle} title='时段可预约人数'>
          <Input
            type='number'
            value={load}
            onChange={(e) => { setLoad(Number(e.target.value)) }}
            style={PanelInput}
          />
        </InputBox>
        <InputBox style={PanelLeftRowStyle} title='重复生成'>
          <Switch
            checked={isUseRereadRule}
            checkedChildren="使用"
            unCheckedChildren="禁用"
            onChange={(value) => { setIsUseRereadRule(value) }}
          />
        </InputBox>
        {
          isUseRereadRule ?
            <Reread
              reread_rule={rereadRule}
              custom_rule={customRule}
              onchangeRereadRule={(value) => setRereadRule(value)}
              onchangeCustomRule={(value) => setCustomRule(value)}
            />
            : null
        }
      </Drawer>
      <Modal
        title="提示"
        visible={isTipOpen}
        onOk={() => createNewItem(payload)}
        onCancel={() => {
          setIsTipOpen(false)
          setLoading(false)
        }}
      >
        <p>您正在创建一个与现有时段交叉的时段,仍要创建该时段?</p>
      </Modal>
    </React.Fragment>
  )
}

export default QuickPanel